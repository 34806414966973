import React from 'react';
import Header from './component/Header';
import Footer from './component/Footer';

const HowItWorks = () => {
    return (
        <div>
            <Header />
            <div className="static-page-wrap">
                <div className="wrap-img"></div>
                <div className="inner-wrapper">
                    <div className="container">
                        <div className="row pb-20">
                            <div className="col-md-2 col-xs-12"></div>
                            <div className="col-md-8 col-xs-12">
                                <div className="page-head">
                                    <h1>How Renew My Tech Works</h1>
                                    <p>
                                        Welcome to Renew My Tech! Discover how our platform simplifies the process of managing your vehicle registration renewals. With our user-friendly features, you can automate the renewal process, ensuring that your vehicle registration is always up to date.
                                    </p>
                                </div>
                            </div>
                            <div className="col-md-2 col-xs-12"></div>
                        </div>
                        
                        <div className="white-wrapper">
                            <h4>1. Vehicle Registration Information</h4>
                            <p>
                                Start by providing your vehicle's details, including the license plate number and other relevant information. This step helps us identify your vehicle and its registration requirements.
                            </p>

                            <h4>2. Select Your Municipality and State</h4>
                            <p>
                                Choose your municipality and state from our extensive list of options. Our app covers a wide range of locations, ensuring that you can always find your specific municipality for accurate renewal processing.
                            </p>

                            <h4>3. Secure Payment Setup</h4>
                            <p>
                                Add your payment information to your account securely. We use encryption and robust security measures to protect your sensitive data. Rest assured that your payment details are safe and protected.
                            </p>

                            <h4>4. Automatic Renewal Activation</h4>
                            <p>
                                Enable our automatic renewal feature to ensure your vehicle's plate registration renews automatically each year. Say goodbye to the hassle of remembering renewal dates or dealing with late fees.
                            </p>

                            <h4>5. Receive Timely Notifications</h4>
                            <p>
                                We'll send you notifications and reminders as your renewal date approaches. Stay informed about the renewal process, review your payment, and make any necessary updates with ease.
                            </p>

                            <h4>6. Renewal Confirmation</h4>
                            <p>
                                After successful payment processing, you'll receive a confirmation of your plate renewal. You can access your renewed registration details within the app and obtain a digital copy for your records.
                            </p>

                            <h4>7. Enjoy Peace of Mind</h4>
                            <p>
                                With Renew My Tech, managing your vehicle registration is simple and stress-free. Say goodbye to manual renewals and hello to a smarter, more efficient way of keeping your plate registration up to date.
                            </p>

                            <h4>8. Customer Support</h4>
                            <p>
                                Our dedicated customer support team is ready to assist you. Whether you have questions or need help, feel free to reach out through our in-app chat or email support.
                            </p>

                            <h4>9. Get Started Today</h4>
                            <p>
                                Join our community of satisfied users and let Renew My Tech take care of your vehicle renewal needs. Experience the future of hassle-free registration renewal. Sign up today and enjoy peace of mind.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default HowItWorks;
