import React, { useState } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope,faPhone } from "@fortawesome/free-solid-svg-icons";
import Header from './component/Header';
import Footer from './component/Footer';
import { Link } from 'react-router-dom';
import { API_URL } from "../config";
import Swal from "sweetalert2";
const imageUrlLogo = process.env.PUBLIC_URL + '/images/rmt-logo.png';

const ContactUs = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: '',
    });

    const [formErrors, setFormErrors] = useState({
        name: '',
        email: '',
        message: '',
    });

    const validateForm = () => {
        const errors = {};
        let valid = true;

        if (!formData.name) {
            errors.name = 'Name is required';
            valid = false;
        }

        if (!formData.email) {
            errors.email = 'Email is required';
            valid = false;
        } else if (!isValidEmail(formData.email)) {
            errors.email = 'Invalid email address';
            valid = false;
        }

        if (!formData.message) {
            errors.message = 'Message is required';
            valid = false;
        }

        setFormErrors(errors);
        return valid;
    };

    const isValidEmail = (email) => {
        // You can use a regular expression to validate the email format
        return /\S+@\S+\.\S+/.test(email);
    };

    const handleSubmit = (e) => { 
        e.preventDefault();

        if (validateForm()) {
            fetch(API_URL + '/contact-us', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            })
            .then((response) => response.json())
            .then((data) => {
                console.log(data);
                if (data.errors) {
                    // Handle errors, e.g., show an error message.
                    const errorMessages = Object.values(data.errors).join("<br>");
                    Swal.fire({
                        title: 'Error!',
                        html: errorMessages,
                        icon: 'error',
                        confirmButtonText: 'OK'
                    });
                } else {
                    // Handle success, e.g., show a success message.
                    Swal.fire({
                        title: 'Success!',
                        html: data.message,
                        icon: 'success',
                        confirmButtonText: 'OK'
                    });
                }
            })
            .catch((error) => {
                console.error('Error :', error);
            });

            // Reset the form fields after submission
            setFormData({
                name: '',
                email: '',
                message: '',
            });

            setFormErrors({});
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });

        // Clear the associated error when the user types
        if (formErrors[name]) {
            setFormErrors({ ...formErrors, [name]: '' });
        }
    };

    return (
        <div>
            <Header />
            <div className="static-page-wrap">
                <div className="wrap-img"></div>
                <div className="inner-wrapper">
                    <div className="container">
                        <div className="row pb-20">
                            <div className="col-md-12">
                                <div className="page-head">
                                    <h1>Contact Us</h1>
                                    <p>
                                        Have a question or need assistance? Contact us by filling out the form below.
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="white-wrapper">
                            <div className="row step2">
                                <div className="col-md-7 col-xs-12 form-wrapper contact-form">
                                    <form onSubmit={handleSubmit}>
                                        <div className="form-group">
                                            <label htmlFor="name" className="form-label">Name</label>
                                            <input
                                                type="text"
                                                className={`form-control ${formErrors.name ? 'is-invalid' : ''}`}
                                                id="name"
                                                name="name"
                                                value={formData.name}
                                                onChange={handleChange}
                                            />
                                            <div className="invalid-feedback">{formErrors.name}</div>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="email" className="form-label">Email</label>
                                            <input
                                                type="email"
                                                className={`form-control ${formErrors.email ? 'is-invalid' : ''}`}
                                                id="email"
                                                name="email"
                                                value={formData.email}
                                                onChange={handleChange}
                                            />
                                            <div className="invalid-feedback">{formErrors.email}</div>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="message" className="form-label">Message</label>
                                            <textarea
                                                className={`form-control ${formErrors.message ? 'is-invalid' : ''}`}
                                                id="message"
                                                name="message"
                                                value={formData.message}
                                                onChange={handleChange}
                                            ></textarea>
                                            <div className="invalid-feedback">{formErrors.message}</div>
                                        </div>
                                        <button type="submit" className="btn btn-primary">Submit</button>
                                    </form>
                                </div>
                                <div className="col-md-5 col-xs-12 text-center">
                                    <div className='contactus_text'>
                                        <Link to="/">
                                            <img src={imageUrlLogo} alt="RMT" />
                                        </Link>
                                        <div className='fa-2x mt-20 mb-20'>
                                            Contact us with
                                        </div>
                                        <div class="mail">
                                            <FontAwesomeIcon icon={faEnvelope} className="icon" />
                                            <Link to="mailto:hello@renewmy.tech">hello@renewmy.tech</Link>
                                        </div>
                                        <div>
                                            or
                                        </div>
                                        <div class="phone">
                                            <FontAwesomeIcon icon={faPhone} className="icon" />
                                            <Link to="tel:800-778-9457" >800-778-9457</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default ContactUs;
