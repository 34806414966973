import React, { useEffect } from 'react';


const GoogleMap = ({ lat, lng }) => {
  useEffect(() => {
    const center = { lat, lng };
    const map = new window.google.maps.Map(document.getElementById('google-map'), {
      zoom: 15,
      center: center,
    });

    new window.google.maps.Marker({
      position: center,
      map: map,
    });
  }, [lat, lng]);

  return <div id="google-map" style={{ height: '300px', width: '100%' }} />;
};

export default GoogleMap;
