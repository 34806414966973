import React from 'react';
import Header from './component/Header';
import Footer from './component/Footer';
import { Link } from 'react-router-dom';

const NotFound = () => {
  return (
    <div>
      <Header />
      <div className="static-page-wrap">
        <div className="wrap-img"></div>
        <div className="inner-wrapper">
          <div className="container">
            <div className="row pb-20">
              <div className="col-md-12">
                <div className="page-head">
                  <h1>404 Not Found</h1>
                  <p>Oops! The page you're looking for does not exist.</p>
                </div>
              </div>
            </div>

            <div className="white-wrapper">
              <p>
                It seems like you've entered a wrong URL or the page you are
                looking for has been moved, deleted, or does not exist.
              </p>
              <p>
                <Link to="/">Go back to the home page</Link>
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default NotFound;
