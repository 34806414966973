import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faCheckCircle, faForwardStep } from '@fortawesome/free-solid-svg-icons';
import Modal from 'react-modal';
import { API_URL } from '../../config';
import Swal from 'sweetalert2'; // Import SweetAlert2
import './PropertySchedulePopup.css'; // Add a CSS file for additional styling

const PropertySchedulePopup = ({ isOpen, onRequestClose, scheduleData, loadScheduleData, renewalId }) => {
    const currentYear = new Date().getFullYear();
    const defaultYear = scheduleData.some(schedule => schedule.year === currentYear) ? currentYear : scheduleData[0]?.year;
    const [activeYear, setActiveYear] = useState(defaultYear);

    useEffect(() => {
        // Default to the current year tab
        setActiveYear(defaultYear);
    }, [defaultYear]);

    const handleSkipPayment = (scheduleId) => {
        const authToken = localStorage.getItem('token');
    
        if (!scheduleId || !renewalId) {
            Swal.fire('Error', 'Invalid schedule or renewal selected.', 'error');
            return;
        }

        // Fetch the recalculated payment schedule before skipping
        fetch(`${API_URL}/payment-schedules/${scheduleId}/recalculate`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${authToken}`,
            },
        })
            .then(response => response.json())
            .then(data => {
                if (data.success) {
                    const recalculatedSchedules = data.recalculatedSchedules.map(
                        (schedule) => `Payment Date: ${new Date(schedule.payment_date).toLocaleDateString('en-US')} - Amount: $${schedule.chargeable_amount}`
                    ).join('\n');

                    // Show confirmation box with recalculated schedule using Swal2
                    Swal.fire({
                        title: 'Confirm Skip Payment',
                        html: `<pre>${recalculatedSchedules}</pre>`, // Using HTML for better formatting
                        icon: 'warning',
                        showCancelButton: true, // Allow cancel
                        confirmButtonText: 'Confirm',
                        cancelButtonText: 'Cancel',
                        showCloseButton: true,
                    }).then((result) => {
                        if (result.isConfirmed) {
                            // Proceed with skipping the payment
                            fetch(`${API_URL}/payment-schedules/${scheduleId}/skip`, {
                                method: 'POST',
                                headers: {
                                    'Content-Type': 'application/json',
                                    Authorization: `Bearer ${authToken}`,
                                },
                            })
                                .then(response => response.json())
                                .then(skipData => {
                                    if (skipData.success) {
                                        Swal.fire('Success', 'Payment skipped successfully. The schedule has been updated.', 'success');
                                        loadScheduleData(renewalId); // Reload the schedule using the correct renewalId
                                    } else {
                                        Swal.fire('Error', skipData.error || 'Failed to skip payment.', 'error');
                                    }
                                })
                                .catch(error => {
                                    Swal.fire('Error', 'Error skipping payment.', 'error');
                                    console.error(error);
                                });
                        }
                    });
                } else {
                    Swal.fire('Error', data.error || 'Failed to recalculate schedule.', 'error');
                }
            })
            .catch(error => {
                Swal.fire('Error', 'Error fetching recalculated schedule.', 'error');
                console.error(error);
            });
    };

    const handleResumePayment = (scheduleId) => {
        const authToken = localStorage.getItem('token');

        if (!scheduleId || !renewalId) {
            Swal.fire('Error', 'Invalid schedule or renewal selected.', 'error');
            return;
        }

        fetch(`${API_URL}/payment-schedules/${scheduleId}/resume`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${authToken}`,
            },
        })
            .then((response) => response.json())
            .then((data) => {
                if (data.success) {
                    Swal.fire('Success', 'Payment resumed successfully.', 'success');
                    loadScheduleData(renewalId); // Reload the schedule using the correct renewalId
                } else {
                    Swal.fire('Error', data.error || 'Failed to resume payment.', 'error');
                }
            })
            .catch((error) => {
                Swal.fire('Error', 'Error resuming payment.', 'error');
                console.error(error);
            });
    };

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            contentLabel="Payment Schedule"
            className="login-popup"
            overlayClassName="overlay"
        >
            <div className="login-content add-plate-pop">
                <button className="close-button" onClick={onRequestClose}>
                    <FontAwesomeIcon icon={faTimes} />
                </button>
                
                <div className="modal-body bill-detail-wrap">
                    <div className="white-wrapper">
                        {/* Tax Year Tabs */}
                        <div className="row">
                            <div className="col-md-12">
                                <div className="tab-list">
                                    <ul>
                                        {scheduleData.map((yearData) => (
                                            <li key={yearData.year}>
                                                <a
                                                    href="#"
                                                    className={yearData.year === activeYear ? 'active' : ''}
                                                    onClick={() => setActiveYear(yearData.year)}
                                                >
                                                    Tax Year - {yearData.year}
                                                </a>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        </div>

                        {/* Schedule Table */}
                        <div className="table-responsiveness">
                            <table className="table table-striped-columns">
                                <thead>
                                    <tr>
                                        <th width="40%" className="text-left">Payment Date</th>
                                        <th width="40%" className="text-right">Chargeable Amount ($)</th>
                                        <th width="20%" className="text-center">Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {scheduleData
                                        .filter((yearData) => yearData.year === activeYear)
                                        .flatMap((yearData) =>
                                            yearData.schedules.map((schedule, index) => {
                                                const isFutureDate = new Date(schedule.payment_date) > new Date();
                                                return (
                                                    <tr key={index}>
                                                        <td className="text-left">{new Date(schedule.payment_date).toLocaleDateString('en-US')}</td>
                                                        <td className="text-right">{schedule.chargeable_amount}</td>
                                                        <td>
                                                            {isFutureDate ? (
                                                                schedule.skip_payment ? (
                                                                    <button
                                                                        className="btn pop-btn action-btn"
                                                                        onClick={() => handleResumePayment(schedule.id)}
                                                                    >
                                                                        Resume <FontAwesomeIcon icon={faCheckCircle} />
                                                                    </button>
                                                                ) : (
                                                                    <button
                                                                        className="btn pop-btn action-btn"
                                                                        onClick={() => handleSkipPayment(schedule.id)}
                                                                    >
                                                                        Skip <FontAwesomeIcon icon={faForwardStep} />
                                                                    </button>
                                                                )
                                                            ) : (
                                                                'Cannot modify'
                                                            )}
                                                        </td>
                                                    </tr>
                                                );
                                            })
                                        )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default PropertySchedulePopup;
