import React, { createContext, useState, useContext, useEffect } from 'react';

import Swal from "sweetalert2";


const CartContext = createContext();

export const useCart = () => useContext(CartContext);

export const CartProvider = ({ children }) => {

    const [cart, setCart] = useState(() => {
        // Load cart items from local storage
        const savedCart = localStorage.getItem('cart');
        return savedCart ? JSON.parse(savedCart) : [];
    });

    useEffect(() => {
        localStorage.setItem('cart', JSON.stringify(cart));
    }, [cart]);

    const addToCart = (item) => {
        const exists = cart.some(bill => bill.id === item.id);
        if (exists) {
         // Show an error alert using SweetAlert with line breaks
          Swal.fire({
            icon: "error",
            title: "Please fix the following issues:",
            html: `This bill is already in your cart.`,
            showCloseButton: true,
            showConfirmButton: true, // Hide the "OK" button
          });
        } else {
            setCart(currentCart => [...currentCart, item]);
            Swal.fire({
              icon: "success",
              title: "Success!",
              text: 'Bill added to cart successfully!',
              showConfirmButton: true,
            });
        }
    };

    const removeFromCart = (id) => {
        setCart(currentCart => currentCart.filter(item => item.id !== id));
      

      //  Swal.fire({
        //    icon: "success",
          //  title: "Success!",
           // text: 'Bill removed to cart successfully!',
            //showConfirmButton: true,
        //});
    };

    const clearCart = () => {
        setCart([]);
    };

    const itemCount = () => {
        return cart.length;
    };

    const totalDue = () => {
        return cart.reduce((total, item) => total + parseFloat(item.balance), 0).toFixed(2);
    };

    return (
        <CartContext.Provider value={{
            cart,
            addToCart,
            removeFromCart,
            clearCart,
            itemCount,
            totalDue
        }}>
            {children}
        </CartContext.Provider>
    );
};
