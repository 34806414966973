import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrashAlt, faUser, faCheckCircle, faPlus } from '@fortawesome/free-solid-svg-icons';
import 'react-toastify/dist/ReactToastify.css';
import './Account.css';
import Footer from './component/Footer';
import Header from './component/Header';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { API_URL } from '../config';
import Swal from 'sweetalert2';
import ContactModal from './component/ContactModal';
import { useAuth } from './component/AuthContext';

const Profile = () => {
    const [userProfile, setUserProfile] = useState({
        name: '',
        email: '',
        password: '',
        confirmPassword: '',
    });

    const [savedContacts, setSavedContacts] = useState([]);
    const { updateProfile } = useAuth();

    const handleSaveClick = (e) => {
        e.preventDefault();

    const validationErrors = {};
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!userProfile.email) {
        validationErrors.email = 'Please enter an email.';
    } else if (!emailRegex.test(userProfile.email)) {
        validationErrors.email = 'Please enter a valid email.';
    }

    if (!userProfile.password) {
        validationErrors.password = 'Please enter a new password.';
    }
    if (!userProfile.confirmPassword) {
        validationErrors.confirmPassword = 'Please confirm your new password.';
    }
    if (userProfile.password !== userProfile.confirmPassword) {
        validationErrors.confirmPassword = 'Passwords do not match.';
    }

    if (Object.keys(validationErrors).length > 0) {
        const combinedErrorMessage = Object.values(validationErrors).join('<br>');

        Swal.fire({
            icon: 'error',
            title: 'Please fix the following issues:',
            html: `${combinedErrorMessage}`,
            showCloseButton: true,
        });

        return;
        } else {
            const token = localStorage.getItem('token');

            fetch(API_URL + '/update-profile', {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(userProfile),
            })
            .then((response) => {
                if (response.status === 200) {
                    return response.json().then((data) => {
                        Swal.fire({
                            icon: 'success',
                            title: 'Profile updated successfully!',
                            timer: 2000,
                        });
                        updateProfile(data);
                    });
                } else if (response.status === 422) {
                    response.json().then((data) => {
                        const errorMessages = data.errors
                        ? Object.values(data.errors).join('<br>')
                        : data.message || 'An error occurred during profile update.';
                        Swal.fire({
                            icon: 'error',
                            title: 'Profile update failed. Error(s):',
                            html: errorMessages,
                            showCloseButton: true,
                        });
                    });
                } else {
                    console.error('Unexpected status code:', response.status);
                }
            })
            .catch((error) => {
                console.error('Error updating profile:', error);
            });
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setUserProfile({
            ...userProfile,
            [name]: value,
        });
    };
    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) {
            const user = JSON.parse(localStorage.getItem('user'));

            if (user) {
                setUserProfile({
                    name: user.name,
                    email: user.email,
                    password: '',
                    confirmPassword: '', // Initialize confirmPassword
                });
            }
        }
    }, []);

    const fetchSavedContacts = () => {
        const token = localStorage.getItem('token');

        fetch(`${API_URL}/customer/notification/methods`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        })
        .then((response) => {
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            return response.json();
        })
        .then((data) => {
            setSavedContacts(data);
        })
        .catch((error) => {
            console.error('Error fetching saved contacts:', error);
        });
    };

    useEffect(() => {
        fetchSavedContacts();
    }, []);

    const deleteContact = (contactId) => {
        const token = localStorage.getItem('token');

        fetch(`${API_URL}/customer/notification/methods/${contactId}`, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        })
        .then((response) => {
            if (!response.ok) {
                return response.json().then((data) => {
                    throw new Error(data.error || 'Network response was not ok');
                });
            }
            return response.json();
        })
        .then(() => {
            // Remove the deleted contact from the state
            setSavedContacts(savedContacts.filter((contact) => contact.id !== contactId));

            Swal.fire('Deleted!', 'The contact has been deleted.', 'success');
        })
        .catch((error) => {
            console.error('Error deleting contact:', error.message);

            Swal.fire('Error', error.message, 'error');
        });
    };

    const handleDeleteClick = (contactId) => {
        Swal.fire({
            title: 'Are you sure?',
            text: 'You will not be able to recover this contact!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!',
        }).then((result) => {
        if (result.isConfirmed) {
            deleteContact(contactId);
        }
    });
};

const [selectedContact, setSelectedContact] = useState(null);
const [isContactModalOpen, setIsContactModalOpen] = useState(false);

const openContactModal = (contact) => {
    setSelectedContact(contact);
    setIsContactModalOpen(true);
};

const closeContactModal = () => {
    setSelectedContact(null);
    setIsContactModalOpen(false);
};

const handleContactSave = () => {
    closeContactModal();
    fetchSavedContacts(); // Refresh the contacts list
};

return (
    <div className="profile-page">
        <Header />
        <div className="container-fluid p-30">
            <div className="page-wrap">
                <h2>Your Profile</h2>
                <ol className="breadcrumb-wrap mb-30">
                    <li><span>RMT</span></li>
                    <li className="active">Profile</li>
                </ol>
                <Tabs>
                    <TabList>
                        <Tab>
                            <FontAwesomeIcon icon={faUser} /> Main Profile
                        </Tab>
                        <Tab>
                            <FontAwesomeIcon icon={faCheckCircle} /> Saved Contacts
                        </Tab>
                    </TabList>
                    <TabPanel>
                        <div className="profile-info form-design">
                            <div className="profile-edit-form">
                                <div className="form-group">
                                    <label>Name</label>
                                    <input
                                        type="text"
                                        name="name"
                                        value={userProfile.name}
                                        onChange={handleInputChange}
                                        className="form-control"
                                    />
                                </div>
                                <div className="form-group">
                                    <label>Email</label>
                                    <input
                                        type="email"
                                        name="email"
                                        value={userProfile.email}
                                        onChange={handleInputChange}
                                        className="form-control"
                                        readOnly={true}
                                    />
                                </div>
                                <div className="form-group">
                                    <label>Password</label>
                                    <input
                                        type="password"
                                        name="password"
                                        value={userProfile.password}
                                        onChange={handleInputChange}
                                        className="form-control"
                                    />
                                </div>
                                <div className="form-group">
                                    <label>Confirm Password</label>
                                    <input
                                        type="password"
                                        name="confirmPassword"
                                        value={userProfile.confirmPassword}
                                        onChange={handleInputChange}
                                        className="form-control"
                                    />
                                </div>
                                <button className="btn btn-primary" onClick={handleSaveClick}>
                                    Save
                                </button>
                            </div>
                        </div>
                    </TabPanel>
                    <TabPanel>
                        <div className="table-responsive table-wrap">
                            <div className="addMoreButtonContainer ">
                                <button className="btn btn-primary  btn-bordered" onClick={() => openContactModal(null)}>
                                    <FontAwesomeIcon icon={faPlus} /> Add Contact
                                </button>
                            </div>
                            {savedContacts.length > 0 ? (
                                <div className="clear-both pt-20">
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th>Phone</th>
                                                <th>Email</th>
                                                <th>Address</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {savedContacts.map((contact) => (
                                            <tr key={contact.id}>
                                                <td className="content">{contact.phone}</td>
                                                <td className="content">{contact.email}</td>
                                                <td className="content">{contact.address}</td>
                                                <td className="content">
                                                    <span style={{ cursor: 'pointer' }} onClick={() => openContactModal(contact)} >
                                                        <FontAwesomeIcon icon={faEdit} /> Edit
                                                    </span>
                                                    {' | '}
                                                    <span style={{ cursor: 'pointer' }} onClick={() => handleDeleteClick(contact.id)}>
                                                        <FontAwesomeIcon icon={faTrashAlt} /> Delete
                                                    </span>
                                                </td>
                                            </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            ) : (
                                <p>No contact found.</p>
                            )}
                        </div>
                    </TabPanel>
                </Tabs>
            </div>
        </div>
        <Footer />
        <ContactModal
            isOpen={isContactModalOpen}
            onClose={closeContactModal}
            contact={selectedContact}
            onSave={handleContactSave}
        />
    </div>
);
};

export default Profile;
