import React, { useState, useEffect } from 'react';
import Header from './component/Header';
import 'react-toastify/dist/ReactToastify.css'; // Import the CSS
import './Welcome.css';
import Footer from './component/Footer';

const Welcome = () => {

  return (
    <div className="welcome-wrap welcome-page" >
      <Header />
      <div className="container-fluid p-30">
        <div className="page-wrap">
          <div className="row section-1">
            <div className="col-md-12 col-lg-12 col-xl-12  text-center">
              <h1>Your Journey to Hassle-Free Tag Renewal: What's Next?</h1>
              <a href='/' className="btn btn-primary mt-10" >Go to Dashboard</a>
            </div>
          </div>
          <div className="row section-2">

            <div className="col-md-6 col-lg-4 col-xl-12 text-center">
              <div>
                <svg fill="#000000" height="200px" width="200px" version="1.1" id="Layer_1"
                  viewBox="0 0 511.999 511.999" >
                  <g>
                    <g>
                      <path d="M431.86,179.437l-77.982-36.963c11.912-12.286,16.971-29.64,13.235-46.65c-6.156-28.033-32.458-45.271-59.873-39.255
			c-1.052,0.231-2.018,0.64-2.885,1.175c-1.141-0.446-2.297-0.861-3.479-1.204c-14.645-4.255-29.895-0.132-41.834,11.315
			l-121.257,116.26c-13.106,12.568-20.91,24.083-26.094,38.503c-1.358,3.776-2.598,7.636-3.797,11.37
			c-3.96,12.328-7.728,24.016-15.353,32.565l-7.203-7.512c-4.265-4.448-10.02-6.968-16.206-7.1
			c-6.18-0.083-12.042,2.148-16.489,6.411L7.109,302.011c-9.203,8.823-9.512,23.492-0.687,32.694l160.977,167.895
			c4.264,4.448,10.02,6.968,16.206,7.099c0.168,0.003,0.334,0.005,0.501,0.005c6.002,0,11.662-2.268,15.989-6.416l26.419-25.331
			c3.714-3.561,3.838-9.457,0.277-13.171c-3.56-3.712-9.456-3.838-13.171-0.278L187.2,489.841c-1.154,1.107-2.496,1.233-3.204,1.232
			c-0.701-0.015-2.043-0.212-3.15-1.366l-38.543-40.2l52.022-49.878l38.543,40.2c3.561,3.714,9.458,3.838,13.171,0.278
			c3.714-3.561,3.838-9.458,0.277-13.171l-10.264-10.706l64.64-60.674c3.752-3.521,3.938-9.417,0.417-13.168
			c-3.521-3.752-9.416-3.939-13.168-0.417l-64.784,60.809L105.469,280.036c10.929-11.621,15.621-26.211,20.163-40.349
			c1.205-3.75,2.341-7.291,3.591-10.766c4.202-11.689,10.418-20.774,21.456-31.358l121.257-116.26
			c7.107-6.815,15.539-9.256,23.74-6.873c8.152,2.369,14.696,9.112,16.677,17.177c2.139,8.709-1.32,17.952-9.813,26.098L217,199.724
			c-3.714,3.561-3.838,9.457-0.278,13.171l6.534,6.815c5.259,5.486,8.068,12.691,7.908,20.289
			c-0.16,7.598-3.269,14.677-8.754,19.937l-27.26,26.136c-3.714,3.561-3.838,9.457-0.277,13.171c1.83,1.908,4.275,2.869,6.726,2.869
			c2.319,0,4.64-0.859,6.445-2.591l27.26-26.136c9.078-8.704,14.223-20.42,14.487-32.994c0.265-12.573-4.383-24.498-13.086-33.574
			l-0.088-0.091l69.248-66.395l118.016,55.939c1.288,0.61,2.646,0.901,3.983,0.901c3.488,0,6.831-1.967,8.424-5.328
			C438.491,187.196,436.509,181.64,431.86,179.437z M181.432,386.182L129.41,436.06L19.872,321.814
			c-1.688-1.76-1.626-4.67,0.132-6.354l45.534-43.658c1.154-1.107,2.504-1.22,3.204-1.232c0.701,0.015,2.043,0.212,3.15,1.366
			L181.432,386.182z M337.648,132.16c-0.661,0.54-1.22,1.156-1.692,1.818l-16.035-7.6c9.985-11.816,13.814-25.817,10.525-39.214
			c-1.074-4.373-2.9-8.52-5.313-12.302c11.519,2.747,20.952,12.066,23.784,24.958C351.575,111.925,347.257,124.318,337.648,132.16z"
                      />
                    </g>
                  </g>
                  <g>
                    <g>
                      <path d="M414.876,17.441c-0.467-5.125-4.995-8.918-10.121-8.432L139.395,33.174c-5.471,0.498-10.428,3.106-13.957,7.343
			c-3.53,4.237-5.2,9.584-4.701,15.054l1.587,17.44c0.44,4.838,4.502,8.471,9.266,8.471c0.283,0,0.569-0.012,0.856-0.039
			c5.123-0.466,8.899-4.997,8.432-10.12l-1.587-17.441c-0.061-0.66,0.217-1.146,0.461-1.44c0.245-0.292,0.673-0.653,1.334-0.714
			l265.358-24.165C411.568,27.096,415.343,22.565,414.876,17.441z"/>
                    </g>
                  </g>
                  <g>
                    <g>
                      <path d="M511.913,152.815L499.915,21.039c-1.031-11.317-11.07-19.699-22.399-18.658l-22.465,2.046
			c-5.123,0.466-8.898,4.998-8.431,10.121c0.466,5.123,4.981,8.896,10.121,8.431l22.464-2.046c1.056-0.101,2.058,0.725,2.156,1.795
			l11.999,131.777c0.06,0.659-0.219,1.148-0.462,1.44s-0.672,0.655-1.333,0.714l-28.85,2.627c-5.123,0.466-8.898,4.997-8.431,10.121
			c0.44,4.836,4.502,8.471,9.266,8.469c0.283,0,0.569-0.012,0.854-0.039l28.851-2.627c5.47-0.498,10.428-3.106,13.957-7.343
			C510.742,163.632,512.412,158.285,511.913,152.815z"/>
                    </g>
                  </g>
                  <g>
                    <g>
                      <path d="M467.694,119.963c-14.711-0.694-26.813-12.3-28.152-26.998c-1.395-15.319,8.93-29.03,24.017-31.893
			c5.054-0.958,8.375-5.833,7.416-10.888c-0.958-5.055-5.84-8.378-10.888-7.416c-24.559,4.658-41.367,26.965-39.097,51.887
			c2.177,23.908,21.88,42.788,45.829,43.917c0.149,0.006,0.297,0.01,0.445,0.01c4.942,0,9.063-3.888,9.297-8.877
			C476.803,124.566,472.833,120.205,467.694,119.963z"/>
                    </g>
                  </g>
                  <g>
                    <g>
                      <path d="M215.721,76.022l-24.193-11.575c-10.254-4.907-22.586-0.555-27.491,9.699l-39.135,81.796
			c-2.221,4.64-0.258,10.203,4.383,12.424c1.297,0.621,2.665,0.914,4.013,0.914c3.475,0,6.81-1.954,8.41-5.297l39.135-81.796
			c0.465-0.97,1.676-1.397,2.644-0.934l24.191,11.575c4.643,2.222,10.205,0.257,12.424-4.382
			C222.324,83.806,220.361,78.243,215.721,76.022z"/>
                    </g>
                  </g>
                  <g>
                    <g>
                      <path d="M508.328,222.726c-1.836-5.2-5.574-9.37-10.53-11.741l-20.35-9.736c-4.641-2.222-10.203-0.258-12.424,4.382
			c-2.219,4.64-0.258,10.203,4.382,12.424l20.35,9.736c0.597,0.286,0.874,0.774,1.001,1.133c0.128,0.359,0.219,0.913-0.068,1.51
			l-57.108,119.361c-0.463,0.969-1.674,1.4-2.705,0.905l-157.204-73.873c-4.656-2.187-10.205-0.185-12.393,4.47
			c-2.187,4.656-0.186,10.205,4.469,12.393l157.148,73.845c2.862,1.37,5.885,2.017,8.864,2.017c7.693,0,15.09-4.324,18.628-11.715
			l57.108-119.363C509.867,233.52,510.161,227.927,508.328,222.726z"/>
                    </g>
                  </g>
                  <g>
                    <g>
                      <path d="M355.586,198.929c-4.558-12.921-13.875-23.294-26.234-29.209c-6.982-3.339-14.419-5.034-22.111-5.034
			c-19.649,0-37.825,11.449-46.302,29.166c-12.206,25.516-1.38,56.206,24.134,68.413c6.982,3.339,14.419,5.034,22.111,5.034
			c19.649,0,37.824-11.449,46.301-29.166C359.399,225.774,360.144,211.85,355.586,198.929z M336.678,230.091
			c-5.399,11.285-16.976,18.577-29.495,18.577c-4.886,0-9.618-1.079-14.069-3.209c-16.248-7.774-23.143-27.317-15.369-43.566
			c5.399-11.285,16.977-18.577,29.496-18.577c4.886,0,9.618,1.079,14.069,3.209c7.871,3.766,13.804,10.372,16.707,18.601
			C340.92,213.354,340.444,222.22,336.678,230.091z"/>
                    </g>
                  </g>
                  <g>
                    <g>
                      <path d="M462.163,236.769c-23.484-8.566-49.341,2.002-60.141,24.576c-10.362,21.657-3.093,47.96,16.907,61.183
			c1.581,1.047,3.366,1.546,5.13,1.546c3.023,0,5.987-1.469,7.779-4.178c2.838-4.292,1.659-10.07-2.632-12.909
			c-12.286-8.123-16.746-24.288-10.376-37.6c6.639-13.876,22.523-20.376,36.952-15.116c4.837,1.765,10.181-0.729,11.942-5.561
			C469.486,243.878,466.997,238.532,462.163,236.769z"/>
                    </g>
                  </g>
                </svg>
              </div>
              <h1>Email Notification &amp; Payment Confirmation</h1>
              <p> Keep an eye on your inbox! We will send you an email detailing the cost for your tag renewal. After reviewing, we'll process the charge for our renewal service. This step ensures transparency and prepares you for the upcoming renewal process.</p>
            </div>
            <div className="col-md-6 col-lg-4 col-xl-12 text-center pl-10 pr-10 b-r b-l">
              <div>
                <svg width="200px" height="200px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M3 9H21M9 15L11 17L15 13M7 3V5M17 3V5M6.2 21H17.8C18.9201 21 19.4802 21 19.908 20.782C20.2843 20.5903 20.5903 20.2843 20.782 19.908C21 19.4802 21 18.9201 21 17.8V8.2C21 7.07989 21 6.51984 20.782 6.09202C20.5903 5.71569 20.2843 5.40973 19.908 5.21799C19.4802 5 18.9201 5 17.8 5H6.2C5.0799 5 4.51984 5 4.09202 5.21799C3.71569 5.40973 3.40973 5.71569 3.21799 6.09202C3 6.51984 3 7.07989 3 8.2V17.8C3 18.9201 3 19.4802 3.21799 19.908C3.40973 20.2843 3.71569 20.5903 4.09202 20.782C4.51984 21 5.07989 21 6.2 21Z" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
              </div>
              <h1>Automated Renewal Management</h1>
              <p>No need to mark your calendar – we've got you covered. Three weeks before your tag's due date, we will automatically renew your vehicle's registration on your behalf. This auto-renewal service continues each year, providing you with hassle-free, continuous coverage.</p>
            </div>
            <div className="col-md-6 col-lg-4 col-xl-12 text-center">
              <div>
                <svg fill="#000000" height="200px" width="200px" version="1.1" id="_x32_"
                  viewBox="0 0 512 512">
                  <g>
                    <path class="st0" d="M447.77,33.653c-36.385-5.566-70.629,15.824-82.588,49.228h-44.038v37.899h40.902
		c5.212,31.372,29.694,57.355,62.855,62.436c41.278,6.316,79.882-22.042,86.222-63.341C517.428,78.575,489.07,39.969,447.77,33.653z
		"/>
                    <path class="st0" d="M162.615,338.222c0-6.88-5.577-12.468-12.468-12.468H96.16c-6.891,0-12.467,5.588-12.467,12.468
		c0,6.868,5.576,12.467,12.467,12.467h53.988C157.038,350.689,162.615,345.091,162.615,338.222z"/>
                    <path class="st0" d="M392.999,237.965L284.273,340.452l-37.966,9.398v-86.619H0v215.996h246.307v-59.454l35.547-5.732
		c16.95-2.418,29.396-6.692,44.336-15.018l46.302-24.228v104.432h132.435V270.828C504.927,202.618,428.016,202.43,392.999,237.965z
		 M215.996,448.913H30.313v-155.37h185.683v63.805l-36.419,9.01c-15.968,4.395-25.708,20.518-22.174,36.696l0.298,1.247
		c3.478,15.912,18.651,26.436,34.785,24.14l23.51-3.788V448.913z"/>
                  </g>
                </svg>
              </div>
              <h1> Tag Delivery Direct to Your Doorstep</h1>
              <p>Once your renewal is approved by the county, your new tags will be mailed directly to you. Simply check your mailbox for the tags – it's that convenient. Rest assured, we ensure the process is smooth and worry-free.</p>
            </div>

          </div>
          <div className="col-md-12 col-lg-12 col-xl-12  text-center">
            <p className="alert alert-info">
              Do not attempt to renew your tags again, as this may cause a duplicate charge and delay in receiving your tags.  If you need any assistance, feel free to reach out to hello@renewmy.tech for support.
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Welcome;
