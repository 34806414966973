import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { BottomNavigation, BottomNavigationAction, Badge, Box } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import DomainIcon from '@mui/icons-material/Domain';
import DomainOutlinedIcon from '@mui/icons-material/DomainOutlined';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import DirectionsCarOutlinedIcon from '@mui/icons-material/DirectionsCarOutlined';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import { useCart } from './CartContext';

function BottomNav() {
  const navigate = useNavigate();
  const location = useLocation();
  const currentPath = location.pathname;
  const { itemCount } = useCart(); // Use cart data

  // Define navigation items with the customized badge for the cart item
  const navItemsWithCart = [
    { label: 'Home', value: '/', icon: <HomeIcon />, outlineIcon: <HomeOutlinedIcon /> },
    {
      label: 'Cart',
      value: '/cart',
      icon: (
        <Badge
          badgeContent={itemCount()}
          color="error"
          sx={{
            '& .MuiBadge-root': { position: 'unset !important' }, 
            '& .Mui-selected .MuiBadge-badge': {
              top: '-15px !important',
              right: '36px !important',
            },
            '& .MuiBadge-badge.MuiBadge-standard': {
              top: '5px !important',
              right: '40px !important',
            },
          }}
        >
          {currentPath === '/cart' ? <ShoppingCartIcon /> : <ShoppingCartOutlinedIcon />}
        </Badge>
      ),
      outlineIcon: (
        <Badge
          badgeContent={itemCount()}
          color="error"
          sx={{
            '& .MuiBadge-root': { position: 'unset !important' },
            '& .MuiBadge-badge.MuiBadge-standard': {
              top: '5px !important',
              right: '40px !important',
            },
          }}
        >
          <ShoppingCartOutlinedIcon />
        </Badge>
      ),
    },
    { label: 'Vehicle', value: '/vehicle-home', icon: <DirectionsCarIcon />, outlineIcon: <DirectionsCarOutlinedIcon /> },
    { label: 'Me', value: '/account', icon: <AccountCircleIcon />, outlineIcon: <AccountCircleOutlinedIcon /> }
  ];

  // Handle navigation change
  const handleChange = (event, newValue) => {
    navigate(newValue);
  };

  return (
    <Box className="bottom-navigation">
      <BottomNavigation
        value={currentPath}
        onChange={handleChange}
        showLabels
        sx={{ height: 60, backgroundColor: '#ffffff' }}
      >
        {navItemsWithCart.map((item) => (
          <BottomNavigationAction
            key={item.label}
            label={item.label}
            value={item.value}
            icon={currentPath === item.value ? item.icon : item.outlineIcon}
            className={currentPath === item.value ? 'active-tab' : ''}
            sx={{
              backgroundColor: 'transparent',
              '& .MuiBottomNavigationAction-label': {
                color: currentPath === item.value ? '#0e40b3' : '#666666',
                fontWeight: currentPath === item.value ? '500' : '400',
                fontSize: currentPath === item.value ? '1em' : '15px',
                paddingTop: currentPath === item.value ? '20px' : '2px',
              },
              '& .MuiSvgIcon-root': {
                color: currentPath === item.value ? '#0e40b3' : '#666666',
                fontSize: currentPath === item.value ? '20px' : '20px',
              },
              '&.active-tab .MuiSvgIcon-root': {
                position: 'absolute',
                top: '-15px',
                left: 'calc(50% - 20px)',
                backgroundColor: '#0e40b3',
                color: '#fff',
                width: '40px',
                height: '40px',
                padding: '7px',
                borderRadius: '25px',
              },
              '&.active-tab::before': {
                content: '""',
                position: 'absolute',
                top: '-20px',
                left: '50%',
                transform: 'translateX(-50%)',
                width: '50px',
                height: '50px',
                backgroundColor: '#0e40b3',
                opacity: '.1',
                borderRadius: '50px',
              },
            }}
          />
        ))}
      </BottomNavigation>
    </Box>
  );
}

export default BottomNav;
