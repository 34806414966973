import React, { useState, useEffect } from "react";

import HeaderProperty from '../component/HeaderProperty';
import Footer from '../component/Footer';

import { Link } from 'react-router-dom';
import {
    ACH_PRICE,
    CREDIT_CARD_PRICE,
    DEBIT_CARD_PRICE,
    ACH_SERVICE_FEE,
    DEBIT_CARD_SERVICE_FEE,
    CREDIT_CARD_SERVICE_FEE
    
  } from "../../config";

const PricingProperty = () => {
    const [isScrolled, setIsScrolled] = useState(false); // State to track whether header is scrolled

    // Function to handle scrolling
    const handleScroll = () => {
        // Detect if user has scrolled beyond a certain threshold
        if (window.scrollY > 50) {
            setIsScrolled(true);
        } else {
            setIsScrolled(false);
        }
    };

    useEffect(() => {
        // Add event listener for scroll
        window.addEventListener('scroll', handleScroll);
        // Remove event listener on cleanup
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []); // Only run this effect once on component mount
    


    return (
        <div>
            <div className={`desktop-view property-detail-head${isScrolled ? ' scrolled' : ''}`}>
                <HeaderProperty />
            </div>
            <div className="static-page-wrap">
                <div className="wrap-img"></div>
                <div className="inner-wrapper">
                    <div className="container">
                        <div className="row pb-20">
                            <div className="col-md-2 col-xs-12"></div>
                            <div className="col-md-8 col-xs-12">
                                <div className="page-head">
                                    <h1>Pricing</h1>
                                    <p>
                                        Choose the plan that best suits your needs for hassle-free property tax management. All plans include automatic payments, timely notifications, and access to our dedicated customer support.
                                    </p>
                                </div>
                            </div>
                            <div className="col-md-2 col-xs-12"></div>
                        </div>
                        <div className="row pt-40">
                            <div className="col-md-4 col-xs-12">
                                <div className="price-wrapper ml-10 mr-10">
                                    <div className="pricing-plan">
                                        <h3>ACH/eCheck</h3>
                                        <div className="price">${Number(ACH_PRICE).toFixed(2)} <span>/property/payment</span></div>
                                        {ACH_SERVICE_FEE > 0 && <div className="service-fee">{Number(ACH_SERVICE_FEE).toFixed(2)}% service fee</div>}
                                        <img src='https://renewmy.tech/wp-content/uploads/2023/12/RMT-logo-final.png' style={{width: "100%"}} alt='RMT Logo' />
                                        <Link to="/" className="btn btn-primary">
                                            Sign Up
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 col-xs-12">
                                <div className="price-wrapper ml-10 mr-10">
                                    <div className="pricing-plan">
                                        <h3>Debit Card</h3>
                                        <div className="price">${Number(DEBIT_CARD_PRICE).toFixed(2)} <span>/property/payment</span></div>
                                        {DEBIT_CARD_SERVICE_FEE > 0 && <div className="service-fee">{Number(DEBIT_CARD_SERVICE_FEE).toFixed(2)}% service fee</div>}
                                        <img src='https://renewmy.tech/wp-content/uploads/2023/12/RMT-logo-final.png' style={{width: "100%"}} alt='RMT Logo' />
                                        <Link to="/" className="btn btn-primary">
                                            Sign Up
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 col-xs-12">
                                <div className="price-wrapper ml-10 mr-10">
                                    <div className="pricing-plan">
                                        <h3>Credit Card</h3>
                                        <div className="price">${Number(CREDIT_CARD_PRICE).toFixed(2)} <span>/property/payment</span></div>
                                        {CREDIT_CARD_SERVICE_FEE > 0 && <div className="service-fee">+{Number(CREDIT_CARD_SERVICE_FEE).toFixed(2)}% service fee</div>}
                                        <img src='https://renewmy.tech/wp-content/uploads/2023/12/RMT-logo-final.png' style={{width: "100%"}} alt='RMT Logo' />
                                        <Link to="/" className="btn btn-primary">
                                            Sign Up
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default PricingProperty;
