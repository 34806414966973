import React from 'react';
import Header from './component/Header';
import Footer from './component/Footer';

const TermsUse = () => {
    return (
        <div>
            <Header />
            <div className="static-page-wrap">
                <div className="wrap-img"></div>
                <div className="inner-wrapper">
                    <div className="container">
                        <div className="row pb-20">
                            <div className="col-md-2"></div>
                            <div className="col-md-8">
                                <div className="page-head">
                                    <h1>Terms and Conditions</h1>
                                    <p>
                                        By using our services, you agree to abide by these Privacy Policy and Terms and Conditions.
                                    </p>
                                </div>
                            </div>
                            <div className="col-md-2"></div>
                        </div>

                        <div className="white-wrapper">
                            <h4>1. Subscription and Billing</h4>
                            <ul>
                                <li>
                                    Users who subscribe to our service(s) agree to be billed according to the agreed-upon terms and rates.
                                </li>
                                <li>
                                    Notifications will be sent 30 days prior to the charge, 15 days prior to the charge, 10 days prior to the charge, and 3 days prior to the charge. If no response or cancellation is received within 3 days of the scheduled charge, it will be considered as user approval for the charge.
                                </li>
                            </ul>

                            <h4>2. Notification and Charges</h4>
                            <ul>
                                <li>
                                    Users who subscribe to our automated car tag service(s) will receive notifications by text and email regarding upcoming charges. Notifications will be sent 30 days prior to the charge, 15 days prior to the charge, 10 days prior to the charge, and 3 days prior to the charge.
                                </li>
                                <li>
                                    If a user receives a notification and does not respond or cancel the service within 3 days of the scheduled charge, it will be considered as their approval, and we will proceed to charge them as agreed upon.
                                </li>
                                <li>
                                    If the payment method on-file is insufficient or results in a chargeback, the subscriber will be responsible for all related fees. Fees will be at the cost of fees incurred by RMT and nothing more.
                                </li>
                            </ul>

                            <h4>3. Cancellation</h4>
                            <p>
                                You can cancel your subscription at any time by following the instructions provided in your account settings. Cancellations made before the scheduled charge dates will prevent future charges.
                            </p>

                            <h4>4. Termination</h4>
                            <p>
                                RMT reserves the right to terminate a user's access to our services under certain circumstances, including but not limited to breaches of these Terms and Conditions or fraudulent activity.
                            </p>

                            <h4>5. Changes to Terms</h4>
                            <p>
                                RMT may update these Terms and Conditions as needed. We will notify users of any significant changes to these terms.
                            </p>

                            <h4>6. Property Tax Prepayment Specifics</h4>
                            <p>
                                RMT will collect and remit payments due to the taxing jurisdiction/office/agency for the subscriber. Tax amounts will be based on the previous year's tax balance, and the final subscription-based payment collected from the subscriber will be adjusted accordingly to pay the tax in full.
                            </p>
                            <p>
                                Should the subscriber’s final payment be insufficient, delayed, rejected, or held due to any reason outside the control of RMT, the subscriber’s final payment will not be released to the taxing jurisdiction/office/agency until the payment can be processed. This may cause additional interests/fees to be charged by the taxing jurisdiction/office/agency to the subscriber's tax bill. This will result in the subscriber's true final payment being adjusted accordingly.
                            </p>
                            <p>
                                If a subscriber desires a refund prior to the payout of funds to the taxing jurisdiction/office/agency, the subscriber is entitled to a full refund less any fees paid. Additionally, should the subscriber have an insufficient payment and RMT incur any form of fee, the subscriber will be notified and then automatically billed for that amount.
                            </p>

                            <h4>7. Disclaimer</h4>
                            <p>
                                The information provided in these documents is for general purposes and should not be considered legal advice. For legal matters or concerns, please consult with a qualified legal professional.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default TermsUse;
