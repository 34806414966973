import React, { useState, useEffect } from 'react';
import {
  AppBar,
  Toolbar,
  IconButton,
  Drawer,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Box,
  Typography,
  Button,
  Menu,
  MenuItem,
  useMediaQuery,
  useTheme
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import LogoutIcon from '@mui/icons-material/ExitToApp';
import { useAuth } from './AuthContext';
import { useNavigate, Link } from 'react-router-dom';
import { useCart } from './CartContext';
import LoginPopup from './LoginPopup';
import './Header.css'; // Import the CSS file


import {
  Dashboard as DashboardIcon,
  CreditCard as CreditCardIcon,
  Person as PersonIcon,
  LocationOn as LocationOnIcon,
  HowToReg as HowToRegIcon,
  MonetizationOn as MonetizationOnIcon,
  ShoppingCart as ShoppingCartIcon,
  Search as SearchIcon,
} from '@mui/icons-material';

const imageUrlLogoW = process.env.PUBLIC_URL + '/images/rmt-logo-white.png';
const imageUrlLogo = process.env.PUBLIC_URL + '/images/rmt-logo.png';

// LoggedInMenu Component
const LoggedInMenu = ({ handleLogout }) => {
  return (
    <List className="drawer-menu">
      <ListItemButton className="drawer-menu-link" component={Link} to="/account">
        <ListItemIcon>
          <span className="glyphicon glyphicon-stats"></span>
        </ListItemIcon>
        <ListItemText primary="Dashboard" />
      </ListItemButton>

      <ListItemButton className="drawer-menu-link" component={Link} to="/license-plate-info">
        <ListItemIcon>
          <span className="glyphicon glyphicon-credit-card"></span>
        </ListItemIcon>
        <ListItemText primary="License Plate Information" />
      </ListItemButton>

      <ListItemButton className="drawer-menu-link" component={Link} to="/profile">
        <ListItemIcon>
          <span className="glyphicon glyphicon-user"></span>
        </ListItemIcon>
        <ListItemText primary="Your Profile" />
      </ListItemButton>

      <ListItemButton className="drawer-menu-link" component={Link} to="/">
        <ListItemIcon>
          <span className="glyphicon glyphicon-search"></span>
        </ListItemIcon>
        <ListItemText primary="Property Lookup" />
      </ListItemButton>

      <ListItemButton className="drawer-menu-link" onClick={handleLogout}>
        <ListItemIcon>
          <LogoutIcon />
        </ListItemIcon>
        <ListItemText primary="Logout" />
      </ListItemButton>
    </List>
  );
};

// LoggedOutMenu Component
const LoggedOutMenu = ({ handleLoginClick }) => {
  return (
    <List className="drawer-menu">
      <ListItemButton className="drawer-menu-link" component={Link} to="/property/how/works">
        <ListItemIcon>
          <span className="glyphicon glyphicon-cog"></span>
        </ListItemIcon>
        <ListItemText primary="How it works" />
      </ListItemButton>

      <ListItemButton className="drawer-menu-link" component={Link} to="/property/pricing">
        <ListItemIcon>
          <span className="glyphicon glyphicon-usd"></span>
        </ListItemIcon>
        <ListItemText primary="Pricing" />
      </ListItemButton>

      <ListItemButton className="drawer-menu-link" component={Link} to="/account">
        <ListItemIcon>
          <span className="glyphicon glyphicon-user"></span>
        </ListItemIcon>
        <ListItemText primary="Sign In" />
      </ListItemButton>

      {/* Uncomment and modify if needed */}
      {/* 
      <ListItemButton className="drawer-menu-link" component={Link} to="/">
        <ListItemIcon>
          <span className="glyphicon glyphicon-search"></span>
        </ListItemIcon>
        <ListItemText primary="Property Lookup" />
      </ListItemButton>
      */}
    </List>
  );
};

// DesktopMenu Component (Using MUI Icons - Option A)
const DesktopMenu = ({ isLoggedIn, handleLogout, handleLoginClick }) => {
  const { itemCount, totalDue } = useCart();
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
      <Button
        component={Link}
        to="/"
        color="inherit"
        startIcon={<LocationOnIcon />}
        className="desktop-menu-button"
      >
        Property Lookup
      </Button>
      <Button
        component={Link}
        to="/property/how/works"
        color="inherit"
        startIcon={<HowToRegIcon />}
        className="desktop-menu-button"
      >
        How it works
      </Button>
      <Button
        component={Link}
        to="/property/pricing"
        color="inherit"
        startIcon={<MonetizationOnIcon />}
        className="desktop-menu-button"
      >
        Pricing
      </Button>
      {isLoggedIn ? (
        <>
          <Button
            color="inherit"
            onClick={handleMenuOpen}
            startIcon={<DashboardIcon />}
            className="desktop-menu-button"
          >
            Account
          </Button>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
          >
            <MenuItem component={Link} to="/account" onClick={handleMenuClose}>
              <DashboardIcon sx={{ marginRight: 1 }} /> Dashboard
            </MenuItem>
            <MenuItem component={Link} to="/license-plate-info" onClick={handleMenuClose}>
              <CreditCardIcon sx={{ marginRight: 1 }} /> License Plate Information
            </MenuItem>
            <MenuItem component={Link} to="/profile" onClick={handleMenuClose}>
              <PersonIcon sx={{ marginRight: 1 }} /> Your Profile
            </MenuItem>
            <MenuItem component={Link} to="/" onClick={handleMenuClose}>
              <SearchIcon sx={{ marginRight: 1 }} /> Property Lookup
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleMenuClose();
                handleLogout();
              }}
            >
              <LogoutIcon sx={{ marginRight: 1 }} /> Logout
            </MenuItem>
          </Menu>
        </>
      ) : (
        <Button
          color="inherit"
          onClick={() => handleLoginClick()}
          startIcon={<HowToRegIcon />}
          className="desktop-menu-button"
        >
          Sign In
        </Button>
      )}
      <Button
        component={Link}
        to="/cart"
        color="inherit"
        startIcon={<ShoppingCartIcon />}
        className="desktop-menu-button"
      >
        ${totalDue()} <span className="badge">{itemCount()}</span>
      </Button>
    </Box>
  );
};

// Header Component
const Header = () => {
  const [isLoginOpen, setLoginOpen] = useState(false);
  const [isLoggedIn, setLoggedIn] = useState(false);
  const [userName, setUserName] = useState('');
  const [userEmail, setUserEmail] = useState('');
  const [drawerOpen, setDrawerOpen] = useState(false);
  const { logout } = useAuth();
  const navigate = useNavigate();
  const [onSuccessRedirect, setOnSuccessRedirect] = useState("/account");
  const { itemCount, totalDue } = useCart();

  // Use MUI's theme to get breakpoints
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md')); // Adjust breakpoint as needed

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      setLoggedIn(true);
      const user = JSON.parse(localStorage.getItem('user'));
      if (user) {
        setUserName(user.name);
        setUserEmail(user.email);
      }
    }
  }, []);

  const handleLoginClick = (redirectPath) => {
    setOnSuccessRedirect(redirectPath || "/account");
    setLoginOpen(true);
  };

  const closeLoginPopup = () => {
    setLoginOpen(false);
  };

  const handleLogout = () => {
    logout();
    setLoggedIn(false);
    setUserName('');
    navigate('/');
  };

  const toggleDrawer = (open) => (event) => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
    setDrawerOpen(open);
  };

  return (
    <>
      {isMobile ? (
        // Mobile View: AppBar with Drawer
        <>
          <AppBar position="fixed" color="primary" className="header-wrap">
            <Toolbar>
              <IconButton
                className="menu-icon"
                edge="start"
                color="inherit"
                aria-label="menu"
                onClick={toggleDrawer(true)}
              >
                <MenuIcon />
              </IconButton>
              <Link to="/" className="brand-name">
                <img
                  src={isLoggedIn ? imageUrlLogoW : imageUrlLogo}
                  alt="RMT"
                  style={{ height: 60 }}
                />
              </Link>
              <Box sx={{ flexGrow: 1 }} />
              <Link to="/cart" className="cart-menu" style={{ display: 'flex', alignItems: 'center', color: 'inherit', textDecoration: 'none' }}>
                <span className="glyphicon glyphicon-shopping-cart" style={{ marginRight: 4 }}></span>
                ${totalDue()}
                <span className="badge" style={{ marginLeft: 4 }}>{itemCount()}</span>
              </Link>
            </Toolbar>
          </AppBar>

          <Drawer anchor="left" open={drawerOpen} onClose={toggleDrawer(false)}>
            <Box
              sx={{ width: 250 }}
              role="presentation"
              onClick={toggleDrawer(false)}
              onKeyDown={toggleDrawer(false)}
              className="drawer-wrap"
            >
              <IconButton onClick={toggleDrawer(false)} className="close-drawer">
                <CloseIcon />
              </IconButton>
              {isLoggedIn ? (
                <LoggedInMenu handleLogout={handleLogout} />
              ) : (
                <LoggedOutMenu handleLoginClick={handleLoginClick} />
              )}
            </Box>
          </Drawer>
        </>
      ) : (
        // Desktop View: Horizontal Menu
        <AppBar position="fixed" color="primary" className="header-wrap">
          <Toolbar>
            <Link to="/" className="brand-name" style={{ display: 'flex', alignItems: 'center' }}>
              <img
                src={isLoggedIn ? imageUrlLogoW : imageUrlLogo}
                alt="RMT"
                style={{ height: 60, margin: '20px' }}
              />
            </Link>
            <Box sx={{ flexGrow: 1 }} />
            <DesktopMenu
              isLoggedIn={isLoggedIn}
              handleLogout={handleLogout}
              handleLoginClick={handleLoginClick}
            />
          </Toolbar>
        </AppBar>
      )}

      {/* Login Popup */}
      {isLoginOpen && (
        <LoginPopup
          isOpen={isLoginOpen}
          onRequestClose={closeLoginPopup}
          onSuccessRedirect={onSuccessRedirect}
        />
      )}
    </>
  );
};

export default Header;
