import React, { useState, useEffect, ClipboardEvent } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import {
  API_URL,
  ROUTING_NUMBER_API_URL,
  ACH_PRICE,
  DEBIT_CARD_PRICE,
  CREDIT_CARD_PRICE,
  PAYMENT_GATEWAY_DEVICEID,
  TSEP_HOST,
} from "../../config";

function PaymentForm({
  transitTokenObject,
  setTransitTokenObject,
  paymentMethod,
  setPaymentMethod,
  accountNumber,
  setAccountNumber,
  setRepeatAccountNumber,
  routingNumber,
  setRoutingNumber,
  accountType,
  setAccountType,
  cardType ,
  setCardType,
  bankInfo,
  setBankInfo,
  accountName,
  setAccountName,
  isShowTermsAndConditions,
  achAgree,
  setAchAgree,
  creditCardAgree,
  setCreditCardAgree,
  termsAgree,
  setTermsAgree,
  smsTermsAgree,
  setSmsTermsAgree,
  serviceFee,
  setServiceFee
}) {
  const [validationError, setValidationError] = useState({});
  const [accountNumberType, setAccountNumberType] = useState('tel');
  const [repeatAccountNumberType, setRepeatAccountNumberType] = useState('tel');
  const preventCopyPaste = (e: ClipboardEvent<HTMLInputElement>) => {
    e.preventDefault()
    alert("Copying and pasting is not allowed!")
  }
  // Function to fetch bank information based on routing number
  const fetchBankInformation = () => {
    // Make an API request to check the routing number
    setBankInfo(null); // Clear bank information
    fetch(ROUTING_NUMBER_API_URL + "/" + routingNumber)
      .then((response) => response.json())
      .then((data) => {
        if (data.message === "bank not found") {
        } else {
          // Routing number is valid, set the bank information
          setBankInfo(data);
        }
      })
      .catch((error) => {
        console.error("Error fetching bank information:", error);
        // Handle errors (if needed)
      });
  };

  useEffect(() => {
    window.tsepHandler = (eventType, event) => {
      if (eventType === "TokenEvent") {
        if (event.status === "FAIL") {
          setValidationError((errors) => ({
            ...errors,
            cardNumber: event.message,
          }));
        } else {
          setTransitTokenObject(event);
          setValidationError((errors) => ({
            ...errors,
            cardNumber: "",
          }));
        }
      } else if (eventType === "FieldValidationErrorEvent") {
        const fieldErrors = {
          "tsep-datepicker": "expiryDate",
          "tsep-cvv2": "cvv",
          "tsep-cardHolderName": "cardName",
          "tsep-zipCode": "zipCode",
          "tsep-cardNum": "cardNumber",
        };

        const fieldName = fieldErrors[event.fieldName];

        if (fieldName) {
          setValidationError((errors) => ({
            ...errors,
            [fieldName]: event.message,
          }));
        }
      }
    };

    fetch(API_URL + "/generate-encrypted-manifest")
      .then((response) => response.json())
      .then((data) => {
        const script = document.createElement("script");
        script.src = `${TSEP_HOST}/transit-tsep-web/jsView/${PAYMENT_GATEWAY_DEVICEID}?${data.encrypted_manifest}`;
        script.async = true;
        script.type = "text/javascript";

        script.addEventListener("load", () => {
          if (window.TransIT && window.TransIT.processPayment) {
            window.TransIT.processPayment();
          }
        });

        const existingScript = document.querySelector(
          `script[src="${script.src}"]`,
        );
        if (!existingScript) {
          document.body.appendChild(script);
        }

        return () => {
          document.body.removeChild(script);
        };
      })
      .catch((error) => {
        console.error("Error fetching manifest:", error);
      });
  }, [setTransitTokenObject]);

  const handlePaymentMethodChange = (method) => {
    setPaymentMethod(method);
    setServiceFee( method =='ach' ? Number(ACH_PRICE).toFixed(2) : Number(CREDIT_CARD_PRICE).toFixed(2) ) 
    setAchAgree(false);
    setCreditCardAgree(false);
    setTermsAgree(false);
    setSmsTermsAgree(false);
  };

  const handleCardTypeChange = (e) => {
    setServiceFee( e.target.value == 'DEBIT' ? Number(DEBIT_CARD_PRICE).toFixed(2) : Number(CREDIT_CARD_PRICE).toFixed(2) );
    setCardType(e.target.value);
  };

  const handleAccountTypeChange = (e) => {
    setAccountType(e.target.value);
  };

  return (
    <div className="payment-method address-wrap mt-0">
      <h3 className="text-left">Payment Detail</h3>
      <div className="row">
        <div className="col-md-12 payment-wrap">
          <ul className="text-left">
            <li className={`strip${paymentMethod === 'ach' ? ' active' : ''}`} onClick={() => handlePaymentMethodChange('ach')}>
              <FontAwesomeIcon icon={faCheck} className="completed-icon" />
            </li>
            <li className={`card${paymentMethod === 'creditCard' ? ' active' : ''}`} onClick={() => handlePaymentMethodChange('creditCard')}>
              <FontAwesomeIcon icon={faCheck} className="completed-icon" />
            </li>
          </ul>

          <div
            id="transit-fields"
            className="mt-4 clear-both"
            style={{
              display: paymentMethod === "creditCard" ? "block" : "none",
            }}
          >
            <div className="form-group">
              <label htmlFor="tsep-cardType">Card Type</label>
              <select
                id="tsep-cardType"
                name="tsep-cardType"
                className="form-control"
                autoComplete="cc-type"
                onChange={handleCardTypeChange}
                value={cardType}
              >
                <option value="">Select Card Type</option>
                <option value="CREDIT">Credit Card</option>
                <option value="DEBIT">Debit Card</option>
              </select>
            </div>
            <div className="form-group">
              <label htmlFor="tsep-cardNum">Card Number</label>
              <input
                id="tsep-cardNum"
                name="tsep-cardNum"
                className="form-control"
                placeholder="Card Number"
                autoComplete="cc-number"
                maxLength="23"
                type="tel"
              />
              {validationError.cardNumber && (
                <span className="error">{validationError.cardNumber}</span>
              )}
            </div>
            <div className="row">
              <div className="col-md-8">
                <div className="form-group">
                  <label htmlFor="tsep-datepicker">Expiration Date</label>
                  <input
                    id="tsep-datepicker"
                    name="tsep-datepicker"
                    className="form-control"
                    placeholder="MM/YYYY"
                    autoComplete="cc-exp"
                    maxLength="7"
                    type="tel"
                  />
                  {validationError.expiryDate && (
                    <span className="error">{validationError.expiryDate}</span>
                  )}
                </div>
              </div>

              <div className="col-md-4">
                <div className="form-group">
                  <label htmlFor="tsep-cvv2">CVV</label>
                  <input
                    id="tsep-cvv2"
                    name="tsep-cvv2"
                    className="form-control"
                    placeholder="CVV"
                    autoComplete="off"
                    maxLength="4"
                    type="text"
                  />
                  {validationError.cvv && (
                    <span className="error">{validationError.cvv}</span>
                  )}
                </div>
              </div>
            </div>

            <div className="form-group">
              <label htmlFor="tsep-cardHolderName">Name on Card</label>
              <input
                id="tsep-cardHolderName"
                name="tsep-cardHolderName"
                className="form-control"
                placeholder="Name on Card"
                autoComplete="cc-name"
                maxLength="30"
                type="text"
              />
              {validationError.cardName && (
                <span className="error">{validationError.cardName}</span>
              )}
            </div>
            <div className="form-group">
              <label htmlFor="tsep-zipCode">Zip Code</label>
              <input
                id="tsep-zipCode"
                name="tsep-zipCode"
                className="form-control"
                placeholder="Zip Code"
                autoComplete="billing postal-code"
                maxLength="10"
                type="text"
              />
              {validationError.zipCode && (
                <span className="error">{validationError.zipCode}</span>
              )}
            </div>
          </div>

          <div
            className="mt-4 clear-both"
            style={{ display: paymentMethod === "ach" ? "block" : "none" }}
          >
            <div className="form-group">
              <label htmlFor="accountName">Name on Account</label>
              <input
                id="accountName"
                name="accountName"
                className="form-control"
                placeholder="Account Name"
                type="text"
                onChange={(e) => setAccountName(e.target.value)}
              />
              <small id="name_help" class="form-text text-muted">Customers Name as it appears on the bank account and signor on the account.</small>
            </div>

            <div className="form-group">
              <label htmlFor="accountNumber">Account Number</label>
              <input
                id="accountNumber"
                name="accountNumber"
                className="form-control"
                placeholder="Account Number"
                type={accountNumberType}
                onFocus={() => setAccountNumberType('tel')}
                onBlur={() => setAccountNumberType('password')}
                onChange={(e) => setAccountNumber(e.target.value)}
                onCopy={(e) => preventCopyPaste(e)}
                onPaste={(e) => preventCopyPaste(e)}
                onCut={(e) => preventCopyPaste(e)}
              />
            </div>
            <div className="form-group">
              <label htmlFor="repeatAccountNumber">Repeat Account Number</label>
              <input
                id="repeatAccountNumber"
                name="repeatAccountNumber"
                className="form-control"
                placeholder="Repeat Account Number"
                type={repeatAccountNumberType}
                onFocus={() => setRepeatAccountNumberType('tel')}
                onBlur={() => setRepeatAccountNumberType('password')}
                onChange={(e) => setRepeatAccountNumber(e.target.value)}
                onCopy={(e) => preventCopyPaste(e)}
                onPaste={(e) => preventCopyPaste(e)}
                onCut={(e) => preventCopyPaste(e)}
              />
            </div>

            <div className="form-group">
              <label htmlFor="routingNumber">Routing Number</label>
              <input
                id="routingNumber"
                name="routingNumber"
                className="form-control"
                placeholder="Routing Number"
                type="tel"
                onChange={(e) => setRoutingNumber(e.target.value)}
                onBlur={fetchBankInformation}
              />
              {/* Display bank information (if available) */}
              {bankInfo && (
                <div className="bank-info">
                  <p>{bankInfo.bank}</p>
                </div>
              )}
            </div>

            <div className="form-group">
              <label htmlFor="accountType">Account Type</label>
              <select
                id="accountType"
                name="accountType"
                className="form-control"
                onChange={handleAccountTypeChange}
                value={accountType}
              >
                <option value="">Select Account Type</option>
                <option value="Checking">Checking</option>
                <option value="Savings">Savings</option>
              </select>
            </div>
          </div>

          <div className="form-group"
            style={{ display: paymentMethod === "ach" ? "block" : "none" }}>
            <div className="alert alert-warning">
              <label>
                <span>By agreeing to these terms, you hereby authorize Renew My Technologies to initiate an annual recurring Automated Clearing House (ACH) debit transaction from your designated bank account. This transaction will cover the total cost of your yearly vehicle plate renewal, inclusive of the applicable service fee charged by Renew My Technologies. You will receive a notification regarding the upcoming debit transaction, along with the specific amount to be debited, in advance of the scheduled date. Furthermore, you will be afforded an opportunity to cancel this transaction up to three days prior to the scheduled debit date. Your agreement to these terms confirms your understanding and acceptance of this authorization.
                </span>
              </label>
              <div className="checkbox">
                <label>
                  <input type="checkbox" name="ach_agree"  checked={achAgree} onChange={(e) => setAchAgree(e.target.checked)} />
                  <span className="terms">
                    I Acknowledge and Consent to the Terms of Authorization for Annual ACH Debit by Renew My Technologies.
                  </span>
                </label>
              </div>
            </div>
          </div>

          <div className="form-group"
            style={{ display: paymentMethod === "creditCard" ? "block" : "none" }}>
            <div className="alert alert-warning">
              <label>
                <span>
                  By agreeing to these terms, you hereby authorize Renew My Technologies to process an annual recurring charge to your designated credit card account. This charge will encompass the total amount due for your yearly vehicle plate renewal, including any applicable service fees assessed by Renew My Technologies. Prior to each scheduled transaction, you will receive a notification detailing the impending charge and the specific amount to be charged to your credit card. In addition, you will be granted the option to cancel this transaction up to three days before the scheduled charge date. Your agreement to these terms signifies your understanding and acceptance of this authorization for the recurring credit card charges as outlined above.
                </span>
              </label>
              <div className="checkbox">
                <label>
                  <input type="checkbox" name="creditCard_agree" checked={creditCardAgree} onChange={(e) => setCreditCardAgree(e.target.checked)} />
                  <span className="terms">
                    I Acknowledge and Consent to the Terms of Authorization for Annual Charge by Renew My Technologies.
                  </span>
                </label>
              </div>
            </div>
          </div>

          {isShowTermsAndConditions && <>
            <div className="form-group">
              <div className="checkbox">
                <label>
                  <input type="checkbox" name="terms_agree"  checked={termsAgree} onChange={(e) => setTermsAgree(e.target.checked)}/>
                  <span className="terms">
                    I agree to the {" "}
                    <a href="/terms/use" target="_blank">
                      Terms and Conditions
                    </a>
                    .
                  </span>
                </label>
              </div>
            </div>

            <div className="form-group">
              <div className="checkbox">
                <label>
                  <input type="checkbox" name="sms_terms_agree"  checked={smsTermsAgree} onChange={(e) => setSmsTermsAgree(e.target.checked)}/>
                  <span className="terms">
                    I agree to receive SMS and Email notifications according to
                    the{" "}
                    <a href="/terms/use" target="_blank">
                      Terms and Conditions
                    </a>
                    .
                  </span>
                </label>
              </div>
            </div>
            <div className="form-group">
              <div className="alert alert-info">
                <label>
                  <span className="price">
                    You will be charged ${serviceFee} automatically per year for
                    this service in addition to your yearly renewal costs.
                  </span>
                </label>
              </div>
            </div>
          </>}
        </div>
      </div>
    </div>
  );
}

export default PaymentForm;
