import React from 'react';
import Header from './component/Header';
import Footer from './component/Footer';

const PrivacyPolicy = () => {
    return (
        <div>
            <Header />
            <div className="static-page-wrap">
                <div className="wrap-img"></div>
                <div className="inner-wrapper">
                    <div className="container">
                        <div className="row pb-20">
                            <div className="col-md-2"></div>
                            <div className="col-md-8">
                                <div className="page-head">
                                    <h1>Privacy Policy</h1>
                                    <p>
                                        At Renew My Tech (RMT), we respect your privacy and are committed to protecting your personal information. This Privacy Policy explains how we collect, use, and safeguard your data when you use our services.
                                    </p>
                                </div>
                            </div>
                            <div className="col-md-2"></div>
                        </div>

                        <div className="white-wrapper">
                            <h4>1. Information We Collect</h4>
                            <p>
                                At Renew My Tech (RMT), we collect certain types of information from our users, including but not limited to personal information such as name, email address, phone number, and payment details. We collect this information to provide and improve our services.
                            </p>

                            <h4>2. How We Use Your Information</h4>
                            <p>
                                We use the collected information to process transactions with government entities on behalf of the consumer and provide customer support. We may also use the information to send notifications and updates related to our services.
                            </p>

                            <h4>3. Sharing Your Information</h4>
                            <p>
                                RMT takes your privacy seriously. We do not sell or rent your personal information to third parties. However, we may share your information with trusted partners and service providers to facilitate the services we offer.
                            </p>

                            <h4>4. Notification and Charges</h4>
                            <ul>
                                <li>
                                    Users who subscribe to our automated car tag service(s) will receive notifications by text and email regarding upcoming charges. Notifications will be sent 30 days prior to the charge, 15 days prior to the charge, 10 days prior to the charge, and 3 days prior to the charge.
                                </li>
                                <li>
                                    If a user receives a notification and does not respond or cancel the service within 3 days of the scheduled charge, it will be considered as their approval, and we will proceed to charge them as agreed upon.
                                </li>
                                <li>
                                    If the payment method on-file is insufficient or results in a chargeback, the subscriber will be responsible for all related fees. Fees will be at the cost of fees incurred by RMT and nothing more.
                                </li>
                            </ul>

                            <h4>5. Security</h4>
                            <p>
                                We employ industry-standard security measures to protect your personal information from unauthorized access, disclosure, or alteration. Your data is important to us, and we take all necessary precautions to keep it safe.
                            </p>

                            <h4>6. Your Choices</h4>
                            <p>
                                You have the choice to manage your subscription and personal information. You can cancel your subscription at any time by following the provided instructions. If you have any questions or concerns about your information, please contact us.
                            </p>

                            <h4>7. Changes to This Privacy Policy</h4>
                            <p>
                                RMT may update this Privacy Policy from time to time to reflect changes in our services and practices. We will notify users of any significant changes to this policy.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
};
export default PrivacyPolicy;
