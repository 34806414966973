import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import Select from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { API_URL } from '../../config';
import { toast } from 'react-toastify';


const ChangePreferredPaymentMethodPopup = ({ selectedPlateId, isOpen, onRequestClose, loadPlateRenewalData }) => {
  const [notificationMethods, setNotificationMethods] = useState([]);
  const [activeTab, setActiveTab] = useState('ach'); // Default tab is ach
  const [loading, setLoading] = useState(true);
  const [paymentMethods, setPaymentMethods] = useState([]);
  
  useEffect(() => {
    if (isOpen) {
      fetchNotificationMethods();
    }
  }, [isOpen]);


  const makePreferredMethod = (contactId) => {
    const authToken = localStorage.getItem('token');

    if (!authToken) {
      // Handle the case where the token is missing
      return;
    }

    fetch(`${API_URL}/plate-renewals/${selectedPlateId}/update-payment-method`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        contact_id: contactId,
      }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((data) => {
        toast.success('Preferred payment method updated successfully', { autoClose: 3000 });
        onRequestClose();
        loadPlateRenewalData();
      })
      .catch((error) => {
        toast.error('Failed to set preferred payment method', { autoClose: 3000 });
      });
  };


  const maskAccountNumber = (fullAccountNumber) => {
    if (fullAccountNumber && fullAccountNumber.length >= 4) {
      const last4Digits = fullAccountNumber.slice(-4);
      return `************${last4Digits}`;
    }
    return fullAccountNumber;
  };

  const filteredPaymentMethods = paymentMethods.filter(
      (method) =>
          (activeTab === 'ach' && method.payment_method.toLowerCase() === 'ach') ||
          (activeTab === 'creditCard' && method.payment_method.toLowerCase() === 'creditcard')
  );

  const fetchNotificationMethods = () => {
    const authToken = localStorage.getItem('token');

    if (!authToken) {
      return;
    }

    fetch(`${API_URL}/user/payment-methods`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((data) => {
        setPaymentMethods(data);
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching notification methods:', error);
      });
  };

  const makePreferredContact = (contactId) => {
    const authToken = localStorage.getItem('token');

    if (!authToken) {
      // Handle the case where the token is missing
      return;
    }

    fetch(`${API_URL}/plate-renewals/${selectedPlateId}/update-contact-id`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        contact_id: contactId,
      }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((data) => {
        toast.success('Preferred payment method updated successfully', { autoClose: 3000 });
        onRequestClose();
        loadPlateRenewalData();
      })
      .catch((error) => {
        toast.error('Failed to set preferred payment method', { autoClose: 3000 });
      });
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      className="login-popup"
      overlayClassName="overlay"
    >
      <div className="login-content add-plate-pop">
        <button className="close-button" onClick={onRequestClose}>
          <FontAwesomeIcon icon={faTimes} />
        </button>
        <h2>Choose Preferred Method</h2>

          <div className="container-fluid p-30">
               
                <div className="page-wrap">
                    <div className="tab-buttons">
                        <div
                            className={`tab-button ach-tab ${activeTab === 'ach' ? 'active' : ''}`}
                            onClick={() => setActiveTab('ach')}
                        >
                            ACH
                        </div>
                        <div
                            className={`tab-button card-tab ${activeTab === 'creditCard' ? 'active' : ''}`}
                            onClick={() => setActiveTab('creditCard')}
                        >
                            Credit Card
                        </div>
                    </div>
                    <div className="tabber-wrap p-20">
                        <div className="table-wrap">
                            
                            <div className="pb-20 clear-both"></div>
                            <div className="table-responsive">
                                <table className="table">
                                    <thead>
                                    <tr>
                                      
                                      {activeTab === 'creditCard' && (
                                        <>
                                          <th>Card Holder Name</th>
                                          <th>Masked Card Number</th>
                                        </>
                                      )}
                                      {activeTab === 'ach' && (
                                        <>
                                          <th>Account Number</th>
                                          <th>Routing Number</th>
                                        </>
                                      )}
                                      <th>Action</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {loading ? (
                                      <tr>
                                        <td colSpan="12">Loading...</td>
                                      </tr>
                                    ) : filteredPaymentMethods.length === 0 ? (
                                      <tr>
                                        <td colSpan="12">No payment methods available</td>
                                      </tr>
                                    ) : (
                                      filteredPaymentMethods.map((method, index) => (
                                        <tr key={index}>
                                          {activeTab === 'creditCard' ? (
                                            <>
                                              <td className="content">{method.card_holder_name || '-'}</td>
                                              <td className="content">{maskAccountNumber(method.masked_card_number) || '-'}</td>   
                                            </>
                                          ) : (
                                            <>
                                              <td className="content">{maskAccountNumber(method.account_number) || '-'}</td>
                                              <td className="content">{method.routing_number || '-'}</td>
                                            </>
                                          )}
                                          <td className="content">
                                            <button onClick={() => makePreferredMethod(method.id)}>
                                              Choose
                                            </button>
                                          </td>
                                        </tr>
                                      ))
                                    )}
                                  </tbody>

                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                
            </div>
      </div>
    </Modal>
  );
};

export default ChangePreferredPaymentMethodPopup;
