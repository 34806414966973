import React, { useState, useEffect, useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { API_URL } from '../config';
import './SignupWizard.css';
import { useNavigate } from 'react-router-dom';
import Header from './component/Header';
import { useParams } from 'react-router-dom';
import StepsIndicator from './component/StepsIndicator';
import Swal from 'sweetalert2';
import Footer from './component/Footer';
import StateMunicipalityDropdown from './component/StateMunicipalityDropdown';
import CustomFields from './component/CustomFields';

const SignupWizard = () => {

  const { onboardingId } = useParams();
  const [stateOptions, setStateOptions] = useState([]);
  const [selectedState, setSelectedState] = useState(null);
  const [selectedStateLabel, setSelectedStateLabel] = useState(null);
  const [formPlateNo, setFormPlateNo] = useState('');
  const [formLastSixDigitsOfVIN, setFormLastSixDigitsOfVIN] = useState('');
  const [formPlateType, setFormPlateType] = useState(null);
  const [formExpiration, setFormExpiration] = useState(null);
  const [plateTypeOptions, setPlateTypeOptions] = useState([]);
  const [plateList, setPlateList] = useState([]);
  const [errors, setErrors] = useState({});
  const [currentStep] = useState(2);
  const [stepsName, setStepsName] = useState({});
  const [customFieldsFormValues, setCustomFieldsFormValues] = useState({});
  const [pages, setPage] = useState(null);
  const navigate = useNavigate();
  const imageUrl = process.env.PUBLIC_URL + '/images/step2-img.png';

  // Use state to determine if the screen size is mobile or not
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [nextStepId, setNextStepId] = useState(null);

  // Custom date format function
  const customDateFormat = (dateString) => {
    const [year, month, day] = dateString.split('-');
    return `${month}/${day}/${year}`;
  };

  // Custom date format function
  const customYMDateFormat = (dateString) => {
    const [year, month] = dateString.split('-');
    const day = new Date(year, month, 0, 0).getDate();
 
    return `${month}/${day}/${year}`;
  };
  
  const dateBeforeDays = (days) => {
    return new Date(new Date().setDate(new Date().getDate() - days));
  }

  const dateBeforeDaysCustomDateFormat = (days) => {
    let date = dateBeforeDays(days);
    let year = date.getFullYear();
    let month = String(date.getMonth() + 1).padStart(2, "0");
    // let day = String(date.getDate());
    // if (day.length < 2) { day = "0" + day; }

    return `${year}-${month}`;
  }

  // Update the isMobile state when the window is resized
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    // Add event listener for window resize
    window.addEventListener('resize', handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const fetchCustomFields = useCallback((stateId) => {
    if (stateId !== undefined && stateId !== null) {
      fetch(API_URL + `/custom-step-sections/${stateId}`)
        .then((response) => response.json())
        .then((data) => {
        setPage(data.pages);
      })
      .catch((error) => {
          console.error('Error fetching custom step page data:', error);
      });
    }
  }, []);

  const fetchPlateTypes = useCallback((stateId) => {
    if (stateId !== undefined && stateId !== null) {
      fetch(API_URL + `/plate-types?state_id=${stateId}`)
        .then((response) => response.json())
        .then((data) => {
          if (Array.isArray(data)) {
            const options = data.map((plateType) => ({
              value: plateType.id,
              label: plateType.name,
            }));
            setPlateTypeOptions(options);
            if (options[0] !== undefined && options[0] !== null) {
              setFormPlateType(options[0]); // set first option as default 
            }
            fetchCustomFields(stateId);
          } else {
            // Handle the unexpected response structure here
          }
        })
        .catch((error) => {
          // Handle the error
        });
    } else {
      // Handle the case where stateId is undefined or null, if needed.
    }
  }, [fetchCustomFields]); // Include fetchCustomFields in the dependency array for fetchPlateTypes

  // Now, you can use fetchPlateTypes inside the useEffect without warnings
  useEffect(() => {
    // Fetch municipalities from the API
    fetch(API_URL + '/municipalities')
      .then((response) => response.json())
      .then((data) => {
        setStateOptions(data);
      })
      .catch((error) => {
        console.error('Error fetching municipalities:', error);
      });
  }, []);

  useEffect(() => {
    // Fetch onboarding data based on the onboardingId
    fetch(API_URL + `/onboarding/${onboardingId}`)
      .then((response) => response.json())
      .then((data) => {
        if (data.error) {
          navigate('/');
        } else {
          const onboardingStateId = data.onboarding.onboarding_detail.municipality_id;
          setSelectedState(onboardingStateId);
          setFormPlateNo(data.onboarding.onboarding_detail.plate_number);
          setStepsName(data.steps.stepsName);
          setNextStepId(data.steps.id);
          fetchPlateTypes(onboardingStateId);
        }
      })
      .catch((error) => {
        console.error('Error fetching onboarding data:', error);
      });
  }, [onboardingId, stateOptions, navigate, fetchPlateTypes]); // Include fetchPlateTypes in the dependency array


  // Function to check if the form is ready for submission
  const isFormReady = () => {
    return plateList.length > 0 ||  (selectedStateLabel == 'Idaho' && formPlateNo && selectedState && formPlateType && formExpiration && formLastSixDigitsOfVIN ) || (selectedStateLabel != 'Idaho' && formPlateNo && selectedState && formPlateType && formExpiration  );
  };

  const validatePlateNumber = (plateNumber) => {
    const plateNumberRegex = /^[A-Za-z0-9]*$/;
    return plateNumberRegex.test(plateNumber);
  };

  const handleStateChange = (selectedOption) => {
    setSelectedState(selectedOption);
    setFormPlateType(null);
    setPlateTypeOptions([]);
    setFormExpiration(null);
    setPage(null);

    if (selectedOption) {
      if( typeof( selectedOption ) == 'object' && selectedOption.value)  {
       let _stateLable= stateOptions.find((state) =>
       state.children.some((municipality) => municipality.value === selectedOption.value)
       );
       setSelectedStateLabel(_stateLable.label);
    } else {
          let _stateLable = stateOptions.find((state) =>
        state.children.some((municipality) => municipality.value === selectedOption)
        );
        setSelectedStateLabel(_stateLable.label);
      }
      fetchPlateTypes(selectedOption.value);
    }
  };

  const handleAddPlate = (e) => {
    e.preventDefault();

    // Validate plate number, plate type, and expiration
    let plateErrors = {};

    if (!selectedState) {
      plateErrors.state = 'Please select a county.';
    }

    if (!formPlateNo) {
      plateErrors.plateNo = 'Please enter the plate number.';
    } else if (!validatePlateNumber(formPlateNo)) {
      plateErrors.plateNo = 'Please enter a valid plate number.';
    }
    if (!formLastSixDigitsOfVIN && selectedStateLabel == 'Idaho') {
      plateErrors.lastSixDigitsOfVIN = 'Please enter the last 6 digits Of VIN.';
    }

    if (!formPlateType) {
      plateErrors.plateType = 'Please select the plate type.';
    }

    if (!formExpiration) {
      plateErrors.expiration = 'Please select the expiration date.';
    }
  
    // Check for duplicate plate number in plateList, ignoring case
    const isDuplicatePlateNo = plateList.some(plate => 
      plate.plateNo.toLowerCase() === formPlateNo.toLowerCase()
    );

    if (isDuplicatePlateNo) {
      plateErrors.plateNo = 'This plate number already exists.';
    }


    const customFieldData = {
    };


    if (pages !== undefined && pages !== null) {
      pages.forEach((page) => {
        page.fields.forEach((field) => {
          customFieldData[field.custom_field.id] = customFieldsFormValues[field.custom_field.id];

          // Check for required fields
          if (field.custom_field.is_required && !customFieldsFormValues[field.custom_field.id]) {
            plateErrors[field.custom_field.id] = `${field.custom_field.field_name} is required.`;
          }
        });
      });   
    }
    


    if (Object.keys(plateErrors).length > 0) {
      setErrors({ ...errors, ...plateErrors });

      // Display error message using SweetAlert2
      Swal.fire({
        icon: 'error',
        title: 'Please fix the following issues:',
        html: Object.values(plateErrors).join('<br>'), // Use <br> for line breaks
        showCloseButton: true,
      });
      return;
    }

    // Add the plate to the list
    const newPlate = {
      plateNo: formPlateNo,
      lastSixDigitsOfVIN: formLastSixDigitsOfVIN,
      selectedState,
      plateTypeId: formPlateType.value,
      plateType: formPlateType.label,
      expiration: isMobile ? customYMDateFormat(formExpiration) : formExpiration.toLocaleDateString('en-US'),
      customFields: customFieldData, // Include custom fields data in the newPlate object
    };

    setPlateList([...plateList, newPlate]);

    // Reset the plate form fields
    setFormPlateNo('');
    setFormLastSixDigitsOfVIN('');
    setFormPlateType(null);
    setFormExpiration(null);
    setErrors({});
  };

  const handleFormSubmit = async () => {
  // Initialize newPlate as an empty object
  let newPlate = {};

  if (formPlateNo && selectedState && formPlateType && formExpiration) {

    const customFieldData = {
    };

    if (pages !== undefined && pages !== null) {
      pages.forEach((page) => {
        // Iterate through custom fields and add their values to the object
        page.fields.forEach((field) => {
          customFieldData[field.custom_field.id] = customFieldsFormValues[field.custom_field.id];
        });
      });  
    }
  
    // Create the newPlate object with selectedState included
    newPlate = {
      plateNo: formPlateNo,
      lastSixDigitsOfVIN: formLastSixDigitsOfVIN,
      selectedState,
      plateTypeId: formPlateType.value,
      plateType: formPlateType.label,
      expiration: isMobile ? customYMDateFormat(formExpiration) : formExpiration.toLocaleDateString('en-US'),
      customFields: customFieldData
    };
  }

  // Create an array of all plates to send in the request
  const allPlates = [...plateList, newPlate].filter((plate) => Object.keys(plate).length > 0);

  // Perform form submission to the API endpoint
  try {
    const response = await fetch(API_URL + '/onboarding/store-plates', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        plates: allPlates.map((plate) => ({
          plate_number: plate.plateNo,
          last_six_digits_of_vin: plate.lastSixDigitsOfVIN,
          plate_type_id: plate.plateTypeId,
          expiration_date: plate.expiration,
          state_id: typeof plate.selectedState === 'object' ? plate.selectedState.value : plate.selectedState,
          customFields: plate.customFields,
        })),
        onboarding_id: onboardingId,
      }),

    });

    const data = await response.json();

    // Handle the response from the server
    if (data.error) {
      setErrors({ serverError: data.error });

      // Display error message using SweetAlert2
      Swal.fire({
        icon: 'error',
        title: 'Submission failed. Error:',
        html: data.error,
        showCloseButton: true,
      });

      setErrors({ serverError: data.error });
    } else {

      if (nextStepId) {
        navigate(`/plate-information/${onboardingId}/${nextStepId}`);
      } else {
        navigate(`/billing-information/${onboardingId}`);
      }
    }
  } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Error submitting the form. Please try again later.',
      });
  }};


  const handleDeletePlate = (index) => {
    // Remove the plate from the list
    const updatedPlateList = [...plateList];
    updatedPlateList.splice(index, 1);
    setPlateList(updatedPlateList);

  };

  const renderPlateList = () => {
    if (plateList.length === 0) {
      return null;
    }

    return (
      <div className="plate-list">
        <h3>Added Plates</h3>
        <ul>
          {plateList.map((plate, index) => (
            <li key={index}>
              <table className="table">
                <tr>
                  <td width="30%" className="plateNo">{plate.plateNo}</td>
                  <td width="30%" className="plateType">{plate.plateType}</td>
                  <td width="30%" className="plateExpiration">{plate.expiration}</td>
                  <td width="10%" className="plateDel">
                    <button className="btn btn-link btn-delete" onClick={() => handleDeletePlate(index)}>
                      <FontAwesomeIcon icon={faTrash} className="icon" />
                    </button>
                  </td>
                </tr>
              </table>
            </li>
          ))}
        </ul>
      </div>
    );
  };

  return (
    <div className="">
        <Header />
        <div className="step2-wrap">
            <div className="step2-wrap-img"></div>
            <div className="page-wrap step2">
                <div className="container">
                    <StepsIndicator currentStep={currentStep} stepsName={stepsName} />
                    <div className="row">
                        <div className="col-md-6 col-sm-6 col-xs-12">
                            <div className="banner">
                                <h2>Sign up wizard</h2>
                                <div className="text-center">
                                    <img className="signup-wizard-img" src={imageUrl} alt="Step 2 Banner" />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-1 col-sm-6 col-xs-12"></div>
                        <div className="col-md-5 col-sm-6 col-xs-12">
                            <div className="form-wrapper">
                                <form>
                                    <div className="form-group">
                                        <label htmlFor="stateSelect">County</label>
                                        <StateMunicipalityDropdown
                                            data={stateOptions}
                                            selectedMunicipality={selectedState}
                                            setSelectedMunicipality={handleStateChange}
                                        />
                                        {errors.state && (
                                            <div className="invalid-feedback">{errors.state}</div>
                                        )}
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="plateNoInput">License Plate Number</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="plateNoInput"
                                            placeholder="Enter your plate number"
                                            value={formPlateNo}
                                            onChange={(e) => setFormPlateNo((e.target.value) ? e.target.value.replace(/[^0-9a-zA-Z]+/ig, "") : '')}
                                        />
                                        {errors.plateNo && (
                                            <div className="invalid-feedback">{errors.plateNo}</div>
                                        )}
                                    </div>
                                    {selectedStateLabel == 'Idaho' && 
                                    <div className="form-group">
                                        <label htmlFor="lastSixDigitsOfVIN">Last 6 digits of VIN</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="lastSixDigitsOfVIN"
                                            placeholder="Enter Last 6 digits of VIN"
                                            value={formLastSixDigitsOfVIN}
                                            maxLength={6}
                                            onChange={(e) => setFormLastSixDigitsOfVIN(e.target.value)}
                                        />
                                        {errors.lastSixDigitsOfVIN && (
                                            <div className="invalid-feedback">{errors.lastSixDigitsOfVIN}</div>
                                        )}
                                    </div>}
                                    {/* <div className="form-group plate-type-wrap">  
                                        <label htmlFor="plateTypeSelect">Plate Type</label>
                                        <Select
                                            id="plateTypeSelect"
                                            classNamePrefix="form-select"
                                            options={plateTypeOptions}
                                            value={formPlateType}
                                            onChange={(selectedOption) => setFormPlateType(selectedOption)}
                                        />
                                        {errors.plateType && (
                                            <div className="invalid-feedback">{errors.plateType}</div>
                                        )}
                                    </div> */}
                                    {/* Conditionally render date input based on screen size */}
                                    {isMobile ? (
                                        <div className="form-group">
                                            <label htmlFor="expirationInput">Expiration</label>
                                            <input
                                                id="expirationInput"
                                                className="form-control"
                                                type="month"
                                                value={formExpiration}
                                                onChange={(e) => setFormExpiration(e.target.value)}
                                                placeholder="Select expiration date"
                                                min={dateBeforeDaysCustomDateFormat(45)}
                                            />
                                            {errors.expiration && (
                                                <div className="invalid-feedback">{errors.expiration}</div>
                                            )}
                                        </div>
                                    ) : (
                                        <div className="form-group">
                                            <label htmlFor="expirationInput">Expiration</label>
                                            <DatePicker
                                                id="expirationInput"
                                                className="form-control"
                                                selected={formExpiration}
                                                onKeyDown={(e) => { e.preventDefault() }}
                                                onChange={(date) => setFormExpiration(new Date(date.getFullYear(), date.getMonth() + 1, 0, 0))}
                                                showMonthYearPicker
                                                dateFormat="MMM yyyy"
                                                placeholderText="Select expiration date"
                                                minDate={dateBeforeDays(45)}
                                            />
                                            {errors.expiration && (
                                                <div className="invalid-feedback">{errors.expiration}</div>
                                            )}
                                        </div>
                                    )}
                                    <div className="cust-field-wrap">
                                        <CustomFields setCustomFieldsFormValues={setCustomFieldsFormValues} customFieldsFormValues={customFieldsFormValues} pages={pages}  errors={errors} setErrors={setErrors}/>
                                    </div>
                                    <div className="submit-wrap">
                                        <button type="button" className="btn btn-primary btn-bordered" onClick={handleAddPlate}>
                                            <FontAwesomeIcon icon={faPlus} />
                                            Add more
                                        </button>
                                        <button type="button" className="btn btn-primary" onClick={handleFormSubmit} disabled={!isFormReady()}>
                                            Next <FontAwesomeIcon icon={faArrowRight} />
                                        </button>
                                    </div>
                                </form>
                                {renderPlateList()}
                            </div>
                        </div>
                        <div className="col-md-1 col-sm-12 col-xs-12"></div>
                    </div>
                </div>
            </div>
        </div>
        <Footer />
    </div>
  );
};

export default SignupWizard;