import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { API_URL } from '../config';
import './SignupWizard.css';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import StepsIndicator from './component/StepsIndicator';
import Header from './component/Header';
import Swal from 'sweetalert2';
import Footer from './component/Footer';


const CustomPages = () => {
  const { onboardingId } = useParams();
  const { pageId } = useParams();
  const [page, setPage] = useState(null);
  const [formValues, setFormValues] = useState({});
  const [errors, setErrors] = useState({});
  const [currentStep, setCurrentStep] = useState(3);
  const [stepsName, setStepsName] = useState({});
  const [nextStepId, setNextStepId] = useState(null);
  const navigate = useNavigate();
  const imageUrl = process.env.PUBLIC_URL + '/images/step3-img.png';

  useEffect(() => {
    // Fetch custom step page data based on pageId
    fetch(API_URL + `/custom-step-pages/${pageId}/${onboardingId}`)
      .then((response) => response.json())
      .then((data) => {
        setPage(data.pages);
        setStepsName(data.steps.stepsName);
        setNextStepId(data.steps.id);
        setCurrentStep(data.steps.currentStep);

        initializeFormValues(data);
      })
      .catch((error) => {
        console.error('Error fetching custom step page data:', error);
      });
  }, [pageId, onboardingId, navigate]);

  const initializeFormValues = (pageData) => {
    const initialValues = {};
    // Initialize form values based on custom fields
    pageData.fields.forEach((field) => {
      initialValues[field.custom_field.id] = '';
    });
    setFormValues(initialValues);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleSelectChange = (selectedOption, name) => {
    setFormValues({ ...formValues, [name]: selectedOption });
  };

  const handleDatePickerChange = (date, name) => {
    setFormValues({ ...formValues, [name]: date });
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    const customFieldData = {
      page_id: pageId, // Assuming you have pageId available
      onboarding_id: onboardingId, // Assuming you have onboardingId available
      customFields: {}
    };

    // Create an array to storcustomFieldDatae validation error messages
    const validationErrors = [];

    // Iterate through custom fields and add their values to the object
    page.fields.forEach((field) => {
      customFieldData['customFields'][field.custom_field.id] = formValues[field.custom_field.id];

      // Check for required fields
      if (field.custom_field.is_required && !formValues[field.custom_field.id]) {
        validationErrors[field.custom_field.id] = `${field.custom_field.field_name} is required.`;
      }
    });

    // If there are validation errors, display them using Swal
    if (validationErrors.length > 0) {
      setErrors(validationErrors);
      Swal.fire({
        icon: 'error',
        title: 'Validation Errors',
        html: Object.values(validationErrors).join('<br>'),
        confirmButtonText: 'OK',
      });

    } else {
      fetch(API_URL + `/custom-step-pages`, {
        method: 'POST', 
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(customFieldData),
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.json();
        })
        .then((data) => {
          if (nextStepId) {
            navigate(`/plate-information/${onboardingId}/${nextStepId}`);
          } else {
            navigate(`/billing-information/${onboardingId}`);
          }
        })
        .catch((error) => {
          console.error('Error sending data to API:', error);
        });
    }
  };


  if (!page) {
    return <div>Loading...</div>;
  }

    return (
        <div className="">
            <Header />
            <div className="step2-wrap">
                <div className="step2-wrap-img"></div>
                <div className="page-wrap step2">
                    <div className="container">
                        <StepsIndicator currentStep={currentStep} stepsName={stepsName} />
                        <div className="row">
                            <div className="col-md-5 col-sm-6 col-xs-12">
                                <div className="banner">
                                    <h2>{page.page_headline}</h2>
                                    <div className="text-center">
                                        <img className="signup-wizard-img" src={imageUrl} alt="Step 3 Banner" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-1 col-sm-6 col-xs-12"></div>
                            <div className="col-md-5 col-sm-6 col-xs-12">
                                <div className="form-wrapper">
                                    <form onSubmit={handleSubmit}>
                                        {page.fields.map((field) => (
                                            <div key={field.id} className="form-group">
                                                <label>{field.custom_field.field_name}</label>
                                                {field.custom_field.field_type === 'Text' && (
                                                    <input
                                                        type="text"
                                                        name={field.custom_field.id}
                                                        value={formValues[field.custom_field.id]}
                                                        onChange={handleInputChange}
                                                        className="form-control"
                                                        placeholder={field.custom_field.field_name}
                                                    />
                                                )}
                                                {field.custom_field.field_type === 'Number' && (
                                                    <input
                                                        type="number"
                                                        name={field.custom_field.id}
                                                        value={formValues[field.custom_field.id]}
                                                        onChange={handleInputChange}
                                                        className="form-control"
                                                        placeholder={field.custom_field.field_name}
                                                    />
                                                )}
                                                {field.custom_field.field_type === 'Dropdown List' && (
                                                    <Select
                                                        name={field.custom_field.id}
                                                        value={formValues[field.custom_field.id]}
                                                        classNamePrefix="form-select"
                                                        isMulti={field.custom_field.choice_type === 'multiple'} // Add isMulti based on choice_type
                                                        onChange={(selectedOption) =>
                                                        handleSelectChange(selectedOption, field.custom_field.id)
                                                    }
                                                        options={JSON.parse(field.custom_field.dropdown_values).map((option) => ({
                                                        value: option,
                                                        label: option,
                                                    }))}
                                                    />
                                                )}
                                                {field.custom_field.field_type === 'Date' && (
                                                    <DatePicker
                                                        selected={formValues[field.custom_field.id]}
                                                        onChange={(date) => handleDatePickerChange(date, field.custom_field.id)}
                                                        className="form-control"
                                                        placeholder={field.custom_field.field_name}
                                                    />
                                                )}
                                                {errors[field.custom_field.id] && (
                                                    <div className="text-danger">{errors[field.custom_field.id]}</div>
                                                )}
                                            </div>
                                        ))}
                                        <button type="submit" className="btn btn-primary">
                                            Next <FontAwesomeIcon icon={faArrowRight} />
                                        </button>
                                    </form>
                                </div>
                            </div>
                            <div className="col-md-1 col-sm-12 col-xs-12"></div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
  );
};

export default CustomPages;
