import React from 'react';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const CustomFields = ({ customFieldsFormValues, setCustomFieldsFormValues, pages, errors }) => {
  
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setCustomFieldsFormValues({ ...customFieldsFormValues, [name]: value });
  };

  const handleSelectChange = (selectedOption, name) => {
    setCustomFieldsFormValues({ ...customFieldsFormValues, [name]: selectedOption });
  };

  const handleDatePickerChange = (date, name) => {
    setCustomFieldsFormValues({ ...customFieldsFormValues, [name]: date });
  };

  if (!pages) {
    return null; // Return null or some loading indicator when pages are not available yet
  }

  return (
    <div>
      {pages.map((page) => (
        <div key={page.id}>
          <h4>{page.page_headline}</h4>
          {page.fields.map((field) => (
            <div key={field.id} className="form-group">
              <label>{field.custom_field.field_name}</label>
              {field.custom_field.field_type === 'Text' && (
                <input
                  type="text"
                  name={field.custom_field.id}
                  value={customFieldsFormValues[field.custom_field.id]}
                  onChange={handleInputChange}
                  className="form-control"
                  placeholder={field.custom_field.field_name}
                />
              )}
              {field.custom_field.field_type === 'Number' && (
                <input
                  type="number"
                  name={field.custom_field.id}
                  value={customFieldsFormValues[field.custom_field.id]}
                  onChange={handleInputChange}
                  className="form-control"
                  placeholder={field.custom_field.field_name}
                />
              )}
              {field.custom_field.field_type === 'Dropdown List' && (
                <Select
                  name={field.custom_field.id}
                  value={customFieldsFormValues[field.custom_field.id]}
                  classNamePrefix="form-select"
                  isMulti={field.custom_field.choice_type === 'multiple'}
                  onChange={(selectedOption) =>
                    handleSelectChange(selectedOption, field.custom_field.id)
                  }
                  options={JSON.parse(field.custom_field.dropdown_values).map((option) => ({
                    value: option,
                    label: option,
                  }))}
                />
              )}

              {field.custom_field.field_type === 'Date' && (
                <DatePicker
                  selected={customFieldsFormValues[field.custom_field.id]}
                  onChange={(date) => handleDatePickerChange(date, field.custom_field.id)}
                  className="form-control"
                  placeholder={field.custom_field.field_name}
                />
              )}
              {errors[field.custom_field.id] && (
                <div className="text-danger">{errors[field.custom_field.id]}</div>
              )}
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

export default CustomFields;
