import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave } from '@fortawesome/free-solid-svg-icons';
import Modal from 'react-modal';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import Select from 'react-select';
import { API_URL } from '../../config';
import PlacesAutocomplete, { geocodeByAddress } from 'react-places-autocomplete';
import Swal from 'sweetalert2';
import InputMask from 'react-input-mask';
const ContactModal = ({ isOpen, onClose, contact, onSave }) => {
  const [selectedContactId, setSelectedContactId] = useState(null);
  const [stateOptions, setStateOptions] = useState([]);
  const preferredMethodOptions = ["Mobile", "Email"];
  const [selectedState, setSelectedState] = useState(null);
  const [preferredMethod, setPreferredMethod] = useState(null);
  const [preferredMethodData, setPreferredMethodData] = useState(null);
  const [selectedStateId, setSelectedStateId] = useState(null);
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [address, setAddress] = useState('');
  const [city, setCity] = useState('');
  const [pinCode, setPinCode] = useState('');
  const [errors, setErrors] = useState({});
  const [rawPhoneNumber, setRawPhoneNumber] = useState('');
  const handleAddressSelect = async (address) => {
    setAddress(address);
    try {
      // Fetch the details of the selected address from Google Maps
      const results = await geocodeByAddress(address);
      if (results && results.length > 0) {
        const selectedAddress = results[0];
        // Update the city, state, and zip code fields based on the selected address
        setCity(selectedAddress.address_components.find(comp => comp.types.includes('locality'))?.long_name || '');
        setPinCode(selectedAddress.address_components.find(comp => comp.types.includes('postal_code'))?.long_name || '');
        // Find the corresponding state option and set it as the selected state
        const stateName = selectedAddress.address_components.find(comp => comp.types.includes('administrative_area_level_1'))?.long_name || '';
        const selectedOption = stateOptions.find(option => option.label === stateName);
        setSelectedState(selectedOption);
        setSelectedStateId(selectedOption.value);
      }
    } catch (error) {
      console.log('Error fetching address details:', error);
    }
  };
  const handleStateChange = (selectedOption) => {
    setSelectedState(selectedOption);
    setSelectedStateId(selectedOption.value);
  };
  const handlePreferredMethodChange = (selectedOption) => {
    setPreferredMethod(selectedOption);
    setPreferredMethodData(selectedOption.value);
  };
  useEffect(() => {
    // Fetch states from the API
    fetch(API_URL + '/states')
      .then((response) => response.json())
      .then((data) => {
        // Map the API response to the required format for react-select
        const options = data.map((state) => ({
          value: state.id,
          label: state.name,
        }));
        setStateOptions(options);
      })
      .catch((error) => {
        console.log('Error fetching states:', error);
      });
  }, []);
  useEffect(() => {
    setErrors({});
    if (contact) {
      // If contact is provided (not null), populate the form with its values
      setEmail(contact.email || '');
      setPhoneNumber(contact.phone || '');
      setAddress(contact.address || '');
      setCity(contact.city || '');
      setPinCode(contact.pin_code || '');
      // Find the corresponding state option and set it as the selected state
      const selectedOption = stateOptions.find(option => option.value === contact.state_id);
      setSelectedState(selectedOption);
      setSelectedStateId(contact.state_id);
      setSelectedContactId(contact.id);
      setPreferredMethodData(contact.preffered_method);
      setPreferredMethod({ value: contact.preffered_method, label: contact.preffered_method });
    } else {
      // If contact is null, reset the form
      setEmail('');
      setPhoneNumber('');
      setAddress('');
      setCity('');
      setPinCode('');
      setSelectedState(null);
      setSelectedContactId(null);
      setSelectedStateId(null);
      setPreferredMethodData(null);
      setPreferredMethod({ value: null, label: null });
    }
  }, [contact, stateOptions]);
  const isFormValid = () => {
    let isValid = true;
    const newErrors = {
      email: '',
      phoneNumber: '',
      preferredMethod: '',
      address: '',
      city: '',
      state: '',
      pinCode: '',
    };
    if (!email) {
      newErrors.email = 'Email is required.';
      isValid = false;
    } else if (!isValidEmail(email)) {
      newErrors.email = 'Invalid email format.';
      isValid = false;
    }
    if (!phoneNumber) {
      newErrors.phoneNumber = 'Phone number is required.';
      isValid = false;
    } else if (!isValidPhoneNumber(phoneNumber)) {
      newErrors.phoneNumber = 'Invalid phone number format.';
      isValid = false;
    }
    if (!preferredMethodData) {
      newErrors.preferredMethod = 'Preferred Method is required.';
      isValid = false;
    }
    if (!address) {
      newErrors.address = 'Address is required.';
      isValid = false;
    }
    if (!city) {
      newErrors.city = 'City is required.';
      isValid = false;
    }
    if (!selectedState) {
      newErrors.state = 'State is required.';
      isValid = false;
    }
    if (!pinCode) {
      newErrors.pinCode = 'Zip Code is required.';
      isValid = false;
    }
    setErrors(newErrors);
    // Combine all error messages into one
    const combinedErrorMessage = Object.values(newErrors).filter((message) => message).join('\n');
    if (!isValid) {
      // Replace \n with <br> for line breaks in the error message
      const errorMessageWithLineBreaks = combinedErrorMessage.replace(/\n/g, '<br>');
      // Show an error alert using SweetAlert with line breaks
      Swal.fire({
        icon: 'error',
        title: 'Please fix the following issues:',
        html: `${errorMessageWithLineBreaks}`,
        showCloseButton: true,
        showConfirmButton: true, // Hide the "OK" button
      });
    }
    return isValid;
  };
  const handleBlur = (field, value) => {
    const newErrors = { ...errors };
    switch (field) {
      case 'email':
        if (!value) {
          newErrors.email = 'Email is required.';
        } else if (!isValidEmail(value)) {
          newErrors.email = 'Invalid email format.';
        } else {
          newErrors.email = '';
        }
        break;
      case 'phoneNumber':
        if (!value) {
          newErrors.phoneNumber = 'Phone number is required.';
        } else if (!isValidPhoneNumber(value)) {
          newErrors.phoneNumber = 'Invalid phone number format.';
        } else {
          newErrors.phoneNumber = '';
        }
        break;
      case 'address':
        newErrors.address = value ? '' : 'Address is required.';
        break;
      case 'city':
        newErrors.city = value ? '' : 'City is required.';
        break;
      case 'state':
        newErrors.state = value ? '' : 'State is required.';
        break;
      case 'pinCode':
        newErrors.pinCode = value ? '' : 'Zip Code is required.';
        break;
      default:
        break;
    }
    setErrors(newErrors);
  };
  const handleFormSubmit = () => {
    if (!isFormValid()) {
      return;
    }
    const token = localStorage.getItem('token');
    fetch(API_URL + '/customer/notification/methods', {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email,
        phoneNumber,
        address,
        city,
        pinCode,
        selectedStateId,
        rawPhoneNumber,
        selectedContactId,
        preferredMethodData
      }),
    })
      .then((response) => {
        if (response.status === 422) {
          return response.json().then((data) => {
            const errorMessages = Object.values(data.errors).join('\n');
            throw new Error(errorMessages); // Throw an error to trigger the .catch() block
          });
        } else if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((data) => {
        // Display a success message using SweetAlert2
        Swal.fire({
          icon: 'success',
          title: 'Success!',
          text: "Contact successfully updated.",
          showConfirmButton: true,
        });
        onSave();
      })
      .catch((error) => {
        // Handle network and other errors during the submission
        Swal.fire({
          icon: 'error',
          title: 'Error submitting the form',
          text: error,
          showCloseButton: true,
          showConfirmButton: true,
        });
      });
  };
  const handlePhoneNumberChange = (e) => {
    const formattedPhoneNumber = e.target.value;
    setPhoneNumber(formattedPhoneNumber); // Set the formatted phone number in your state
    const rawPhoneNumber = formattedPhoneNumber.replace(/\D/g, ''); // Remove non-numeric characters
    setRawPhoneNumber(rawPhoneNumber); // Set the raw phone number in your state
  };
  // Helper function to validate email format
  const isValidEmail = (email) => {
    // Basic email validation regex
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };
  const isValidPhoneNumber = (phoneNumber) => {
    // Phone number validation regex with mask support
    const phoneRegex = /^\(?\d{3}\)?[-.\s]?\d{3}[-.\s]?\d{4}$/;
    return phoneRegex.test(phoneNumber);
  };
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      className="login-popup"
      overlayClassName="overlay"
    >
      <div className="login-content add-plate-pop">
        <button className="close-button" onClick={onClose}>
          <FontAwesomeIcon icon={faTimes} />
        </button>
        <h2 className="text-left">{contact ? 'Edit Contact' : 'Add Contact'}</h2>
        <div className="form-group">
          <label htmlFor="emailInput">Enter Email</label>
          <input
            type="email"
            className="form-control"
            id="emailInput"
            placeholder="Enter your email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            onBlur={() => handleBlur('email', email)}
          />
          {/* Display error message */}
          {errors.email && <span className="error">{errors.email}</span>}
        </div>
        {/* Phone Number */}
        <div className="form-group">
          <label htmlFor="phoneNumberInput">Mobile Number</label>
          <InputMask
            mask="(999) 999-9999"
            id="phoneNumberInput"
            name="phoneNumber"
            value={phoneNumber}
            onChange={handlePhoneNumberChange}
            className="form-control"
            placeholder="Enter your phone number"
            onBlur={() => handleBlur('phoneNumber', phoneNumber)}
            type="tel" // Use "tel" type for phone numbers
            inputMode="numeric" // Set inputMode to "numeric" for numeric keypad on mobile
          />
          {/* Display error message */}
          {errors.phoneNumber && <span className="error">{errors.phoneNumber}</span>}
        </div>
        <div className="form-group">
          <label htmlFor="preferredMethodInput">Preferred Method</label>
          <Select
            id="preferredMethodInput"
            classNamePrefix="form-select"
            options={preferredMethodOptions.map(option => ({ value: option, label: option }))}
            value={preferredMethod}
            onChange={handlePreferredMethodChange}
            onBlur={() => handleBlur('preferredMethod', preferredMethod)}
          />
          {/* Display error message */}
          {errors.preferredMethod && <span className="error">{errors.preferredMethod}</span>}
        </div>
        {/* Addresses */}
        <div className="form-group address-wrap">
          <h3>Addresses</h3>
          <label htmlFor="addressInput">Address</label>
          <PlacesAutocomplete value={address} onChange={setAddress} onSelect={handleAddressSelect}>
            {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
              <div className="count-wrap-div">
                <input className="form-control" {...getInputProps({ placeholder: "Enter your address" })} />
               {suggestions.length > 0 && (
                    <div className="count-inner">
                      {loading && <div>Loading...</div>}
                      {suggestions.map((suggestion, index) => {
                          const style = {
                              backgroundColor: suggestion.active ? "#0c4adb" : "",
                          };
                          return (
                              <div key={index} {...getSuggestionItemProps(suggestion, { style })}>
                                  {suggestion.description}
                              </div>
                          );
                      })}
                  </div>
                  )}
              </div>
            )}
          </PlacesAutocomplete>
          {/* Display error message */}
          {errors.address && <span className="error">{errors.address}</span>}
        </div>
        <div className="form-group">
          <label htmlFor="cityInput">City</label>
          <input
            type="text"
            className="form-control"
            id="cityInput"
            placeholder="Enter your city"
            value={city}
            onChange={(e) => setCity(e.target.value)}
            onBlur={() => handleBlur('city', city)}
          />
          {/* Display error message */}
          {errors.city && <span className="error">{errors.city}</span>}
        </div>
        <div className="row addr-m-padding">
          <div className="col-md-6 col-xs-12 form-group">
            <label htmlFor="stateSelect">State</label>
            <Select
              id="stateSelect"
              classNamePrefix="form-select"
              options={stateOptions}
              value={selectedState}
              onChange={handleStateChange}
              onBlur={() => handleBlur('state', selectedState)}
            />
            {/* Display error message */}
            {errors.state && <span className="error">{errors.state}</span>}
          </div>
          <div className="col-md-6 col-xs-12 form-group">
            <label htmlFor="pinCodeInput">Zip Code</label>
            <input
              type="text"
              className="form-control"
              id="pinCodeInput"
              placeholder="Enter your Zip Code"
              value={pinCode}
              onChange={(e) => setPinCode(e.target.value)}
              onBlur={() => handleBlur('pinCode', pinCode)}
            />
            {/* Display error message */}
            {errors.pinCode && <span className="error">{errors.pinCode}</span>}
          </div>
        </div>
        <button className="btn btn-primary login-button" onClick={handleFormSubmit}>
          <span className="icon">
            <FontAwesomeIcon icon={faSave} />
          </span>
          <span> {contact ? 'Save Changes' : 'Add Contact'}</span>
        </button>
      </div>
    </Modal>
  );
};
export default ContactModal;