import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import Select from "react-select";
import { API_URL, ACH_PRICE } from "../config";

import "./SignupWizard.css";
import { useNavigate } from "react-router-dom";
import Header from "./component/Header";
import StepsIndicator from "./component/StepsIndicator";
import { useParams } from "react-router-dom";
import PlacesAutocomplete, {
  geocodeByAddress,
} from "react-places-autocomplete"; // Import PlacesAutocomplete and related functions
import PaymentForm from "./component/PaymentGateway";
import Loading from "./component/Loading";
import Swal from "sweetalert2";
import { useAuth } from "./component/AuthContext"; // Import the useAuth hook
import InputMask from "react-input-mask";
import Footer from "./component/Footer";

const BillingInformation = () => {
  const { onboardingId } = useParams();
  const [stateOptions, setStateOptions] = useState();
  const [selectedState, setSelectedState] = useState(null);
  const [selectedStateId, setSelectedStateId] = useState(null);
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [pinCode, setPinCode] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [paymentMethod, setPaymentMethod] = useState("ach");
  const navigate = useNavigate();
  const [errors, setErrors] = useState({});
  const [currentStep, setCurrentStep] = useState(null);
  const imageUrl = process.env.PUBLIC_URL + "/images/step3-img.png";
  const [rawPhoneNumber, setRawPhoneNumber] = useState("");
  const [transitTokenObject, setTransitTokenObject] = useState(null);
  const [accountNumber, setAccountNumber] = useState("");
  const [repeatAccountNumber, setRepeatAccountNumber] = useState("");
  const [routingNumber, setRoutingNumber] = useState("");
  const [accountType, setAccountType] = useState(""); // Set an initial value
  const [cardType, setCardType] = useState(""); // Set an initial value
  const { login } = useAuth(); // Use the useAuth hook
  const [loading, setLoading] = useState(false);
  const [achAgree, setAchAgree] = useState(false); // ach agree checkbox
  const [creditCardAgree, setCreditCardAgree] = useState(false); // creditCard agree checkbox
  const [termsAgree, setTermsAgree] = useState(false); // terms agree checkbox
  const [smsTermsAgree, setSmsTermsAgree] = useState(false); // sms terms agree checkbox
  const [bankInfo, setBankInfo] = useState(null); // State to store bank information
  const [stepsName, setStepsName] = useState({});
  const [accountName, setAccountName] = useState("");
  const [serviceFee, setServiceFee] = useState(Number(ACH_PRICE).toFixed(2));  

  const handleAddressSelect = async (address) => {
    setAddress(address);
    try {
      // Fetch the details of the selected address from Google Maps
      const results = await geocodeByAddress(address);
      if (results && results.length > 0) {
        const selectedAddress = results[0];

        // Update the city, state, and zip code fields based on the selected address
        setCity(
          selectedAddress.address_components.find((comp) =>
            comp.types.includes("locality"),
          )?.long_name || "",
        );
        setPinCode(
          selectedAddress.address_components.find((comp) =>
            comp.types.includes("postal_code"),
          )?.long_name || "",
        );

        // Find the corresponding state option and set it as the selected state
        const stateName =
          selectedAddress.address_components.find((comp) =>
            comp.types.includes("administrative_area_level_1"),
          )?.long_name || "";
        const selectedOption = stateOptions.find(
          (option) => option.label === stateName,
        );
        setSelectedState(selectedOption);
        setSelectedStateId(selectedOption.value);
      }
    } catch (error) {
      console.log("Error fetching address details:", error);
    }
  };

  const handleStateChange = (selectedOption) => {
    setSelectedState(selectedOption);
    setSelectedStateId(selectedOption.value);
  };

  useEffect(() => {
    // Fetch states from the API
    fetch(API_URL + "/states")
      .then((response) => response.json())
      .then((data) => {
        // Map the API response to the required format for react-select
        const options = data.map((state) => ({
          value: state.id,
          label: state.name,
        }));
        setStateOptions(options);
      })
      .catch((error) => {
        console.log("Error fetching states:", error);
      });
  }, []);

  useEffect(() => {
    // Fetch onboarding data based on the onboardingId
    fetch(API_URL + `/onboarding/${onboardingId}`)
      .then((response) => response.json())
      .then((data) => {
        if (data.error) {
          navigate("/");
        } else {
          setEmail(data.onboarding.email);
          setPhoneNumber(formatPhoneNumber(data.onboarding.phone));
          setRawPhoneNumber(data.onboarding.phone);
          setStepsName(data.steps.stepsName);
          setCurrentStep(data.steps.customPagesCount + 3);
        }
      })
      .catch((error) => {
        navigate("/");
        console.log("Error fetching onboarding data:", error);
      });
  }, [onboardingId, navigate]);

  const isFormValid = () => {
    let isValid = true;
    const newErrors = {
      email: "",
      phoneNumber: "",
      address: "",
      city: "",
      state: "",
      pinCode: "",
      password: "",
      confirmPassword: "",
      paymentMethod: "",
    };

    if (!email) {
      newErrors.email = "Email is required.";
      isValid = false;
    } else if (!isValidEmail(email)) {
      newErrors.email = "Invalid email format.";
      isValid = false;
    }

    if (!phoneNumber) {
      newErrors.phoneNumber = "Phone number is required.";
      isValid = false;
    } else if (!isValidPhoneNumber(phoneNumber)) {
      newErrors.phoneNumber = "Invalid phone number format.";
      isValid = false;
    }

    if (!address) {
      newErrors.address = "Address is required.";
      isValid = false;
    }

    if (!city) {
      newErrors.city = "City is required.";
      isValid = false;
    }

    if (!selectedState) {
      newErrors.state = "State is required.";
      isValid = false;
    }

    if (!pinCode) {
      newErrors.pinCode = "Zip Code is required.";
      isValid = false;
    }

    if (!password) {
      newErrors.password = "Password is required.";
      isValid = false;
    } else if (password.length < 6) {
      newErrors.password = "Password must be at least 6 characters long.";
      isValid = false;
    }

    if (!confirmPassword) {
      newErrors.confirmPassword = "Confirm password is required.";
      isValid = false;
    } else if (password !== confirmPassword) {
      newErrors.confirmPassword = "Passwords do not match.";
      isValid = false;
    }

    if (!paymentMethod) {
      newErrors.paymentMethod = "Payment method is required.";
      isValid = false;
    }

    if (paymentMethod === "ach") {
      if (!accountNumber) {
        newErrors.accountNumber = "Account number is required.";
        isValid = false;
      }

      if (!repeatAccountNumber) {
        newErrors.repeatAccountNumber = 'Repeat Account number is required.';
        isValid = false;
      }

      if (accountNumber !== repeatAccountNumber) {
        newErrors.repeatAccountNumber = 'Account number & Repeat Account number must be same.';
        isValid = false;
      }

      if (!accountType) {
        newErrors.accountType = "Account type is required.";
        isValid = false;
      }

      if (!routingNumber) {
        newErrors.routingNumber = "Routing number is required.";
        isValid = false;
      } else if (!/^\d{9}$/.test(routingNumber)) {
        newErrors.routingNumber =
          "Invalid routing number format. It should be 9 digits.";
        isValid = false;
      } else if (!bankInfo) {
        newErrors.routingNumber = "Invalid routing number.";
        isValid = false;
      }
    }

      if (!termsAgree || !smsTermsAgree ||  (paymentMethod === "ach" && !achAgree) ||  (paymentMethod === "creditCard" && !creditCardAgree)) {
        newErrors.termsAgree = 'Please check All terms checkboxes.';
        isValid = false;
      }  
  


    setErrors(newErrors);

    // Combine all error messages into one
    const combinedErrorMessage = Object.values(newErrors)
      .filter((message) => message)
      .join("\n");

    if (!isValid) {
      // Replace \n with <br> for line breaks in the error message
      const errorMessageWithLineBreaks = combinedErrorMessage.replace(
        /\n/g,
        "<br>",
      );

      // Show an error alert using SweetAlert with line breaks
      Swal.fire({
        icon: "error",
        title: "Please fix the following issues:",
        html: `${errorMessageWithLineBreaks}`,
        showCloseButton: true,
        showConfirmButton: true, // Hide the "OK" button
      });
    }

    return isValid;
  };

  const handleBlur = (field, value) => {
    const newErrors = { ...errors };
    switch (field) {
      case "email":
        if (!value) {
          newErrors.email = "Email is required.";
        } else if (!isValidEmail(value)) {
          newErrors.email = "Invalid email format.";
        } else {
          newErrors.email = "";
        }
        break;
      case "phoneNumber":
        if (!value) {
          newErrors.phoneNumber = "Phone number is required.";
        } else if (!isValidPhoneNumber(value)) {
          newErrors.phoneNumber = "Invalid phone number format.";
        } else {
          newErrors.phoneNumber = "";
        }
        break;
      case "address":
        newErrors.address = value ? "" : "Address is required.";
        break;
      case "city":
        newErrors.city = value ? "" : "City is required.";
        break;
      case "state":
        newErrors.state = value ? "" : "State is required.";
        break;
      case "pinCode":
        newErrors.pinCode = value ? "" : "Zip Code is required.";
        break;
      case "password":
        if (!value) {
          newErrors.password = "Password is required.";
        } else if (value.length < 6) {
          newErrors.password = "Password must be at least 6 characters long.";
        } else {
          newErrors.password = "";
        }
        break;
      case "confirmPassword":
        if (!value) {
          newErrors.confirmPassword = "Confirm password is required.";
        } else if (value !== password) {
          newErrors.confirmPassword = "Passwords do not match.";
        } else {
          newErrors.confirmPassword = "";
        }
        break;
      default:
        break;
    }
    setErrors(newErrors);
  };

  const handleFormSubmit = () => {
    if (!isFormValid()) {
      return;
    }

    setLoading(true);

    fetch(API_URL + "/billing-information/save", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email,
        phoneNumber,
        address,
        city,
        pinCode,
        password,
        paymentMethod,
        onboardingId,
        selectedStateId,
        rawPhoneNumber,
        transitTokenObject,
        accountNumber,
        repeatAccountNumber,
        accountName,
        serviceFee,
        routingNumber,
        accountType,
        cardType,
        achAgree,
        creditCardAgree,
        termsAgree,
        accountType,
      }),
    })
      .then((response) => {
        setLoading(false);
        if (response.status === 422) {
          return response.json().then((data) => {
            const errorMessages = Object.values(data.errors).join("\n");
            throw new Error(errorMessages); // Throw an error to trigger the .catch() block
          });
        } else if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        login(data);

        // Display a success message using SweetAlert2
        Swal.fire({
          icon: "success",
          title: "Success!",
          text: "Congratulations! Your account has been successfully created.",
          showConfirmButton: true,
        });

        navigate("/welcome"); // Navigate to the welcome page
      })
      .catch((error) => {
        setLoading(false);
        if (
          error.response &&
          error.response.data &&
          error.response.data.errors
        ) {
          // Handle errors from backend
          Swal.fire({
            icon: "error",
            title: "Error submitting the form",
            html: Object.values(error.response.data.errors).join("<br>"), // Join error messages with line breaks
            showCloseButton: true,
            showConfirmButton: true,
          });
        } else {
          // Handle other types of errors (network error, etc.)
          Swal.fire({
            icon: "error",
            title: "Error submitting the form",
            text: error.message || "An unexpected error occurred",
            showCloseButton: true,
            showConfirmButton: true,
          });
        }
      });
  };

  const formatPhoneNumber = (value) => {
    // Remove any non-digit characters from the input value
    const formattedValue = value.replace(/\D/g, "");

    // Format the phone number based on your desired format (e.g., US format: (xxx) xxx-xxxx)
    // This example assumes a US phone number format
    let formattedPhoneNumber = "";
    if (formattedValue.length > 0) {
      formattedPhoneNumber = "(" + formattedValue.slice(0, 3) + ") ";
      if (formattedValue.length > 3) {
        formattedPhoneNumber += formattedValue.slice(3, 6) + "-";
        if (formattedValue.length > 6) {
          formattedPhoneNumber += formattedValue.slice(6, 10);
        }
      }
    }

    return formattedPhoneNumber;
  };

  const handlePhoneNumberChange = (e) => {
    const formattedPhoneNumber = e.target.value;
    setPhoneNumber(formattedPhoneNumber); // Set the formatted phone number in your state
    const rawPhoneNumber = formattedPhoneNumber.replace(/\D/g, ""); // Remove non-numeric characters
    setRawPhoneNumber(rawPhoneNumber); // Set the raw phone number in your state
  };

  // Helper function to validate email format
  const isValidEmail = (email) => {
    // Basic email validation regex
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const isValidPhoneNumber = (phoneNumber) => {
    // Phone number validation regex with mask support
    const phoneRegex = /^\(?\d{3}\)?[-.\s]?\d{3}[-.\s]?\d{4}$/;
    return phoneRegex.test(phoneNumber);
  };

  return (
    <div>
      <Header />
      <div className="step2-wrap">
        <div className="step2-wrap-img"></div>
        {loading ? <Loading text="Verifying Card..." /> : ""}
        <div className="page-wrap step2">
          <div className="container">
            <StepsIndicator currentStep={currentStep} stepsName={stepsName} />
            <div className="row">
              <div className="col-md-6 col-sm-6 col-xs-12">
                <div className="banner">
                  <h2>Billing Information</h2>
                  <div className="text-center">
                    <img
                      className="signup-wizard-img"
                      src={imageUrl}
                      alt="Step 3 Banner"
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-1 col-sm-6 col-xs-12"></div>
              <div className="col-md-5 col-sm-6 col-xs-12">
                <div className="form-wrapper">
                  <form>
                    {/* Email */}
                    <div className="form-group">
                      <label htmlFor="emailInput">Enter Email</label>
                      <input
                        type="email"
                        className="form-control"
                        id="emailInput"
                        placeholder="Enter your email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        onBlur={() => handleBlur("email", email)}
                      />
                      {/* Display error message */}
                      {errors.email && (
                        <span className="error">{errors.email}</span>
                      )}
                    </div>
                    {/* Phone Number */}
                    <div className="form-group">
                      <label htmlFor="phoneNumberInput">Phone Number</label>
                      <InputMask
                        mask="(999) 999-9999"
                        id="phoneNumberInput"
                        name="phoneNumber"
                        value={phoneNumber}
                        onChange={handlePhoneNumberChange}
                        className="form-control"
                        placeholder="Enter your phone number"
                        onBlur={() => handleBlur("phoneNumber", phoneNumber)}
                        type="tel" // Use "tel" type for phone numbers
                        inputMode="numeric" // Set inputMode to "numeric" for numeric keypad on mobile
                      />
                      {/* Display error message */}
                      {errors.phoneNumber && (
                        <span className="error">{errors.phoneNumber}</span>
                      )}
                    </div>
                    {/* Addresses */}
                    <div className="form-group address-wrap">
                      <h3>Addresses</h3>
                      <label htmlFor="addressInput">Address</label>
                      <PlacesAutocomplete
                        value={address}
                        onChange={setAddress}
                        onSelect={handleAddressSelect}
                      >
                        {({
                          getInputProps,
                          suggestions,
                          getSuggestionItemProps,
                          loading,
                        }) => (
                          <div className="count-wrap-div">
                            <input
                              className="form-control"
                              {...getInputProps({
                                placeholder: "Enter your address",
                              })}
                            />
                            {suggestions.length > 0 && (
                              <div className="count-inner">
                                {loading && <div>Loading...</div>}
                                {suggestions.map((suggestion, index) => {
                                  const style = {
                                    backgroundColor: suggestion.active
                                      ? "#0c4adb"
                                      : "",
                                  };

                                  return (
                                    <div
                                      key={index}
                                      {...getSuggestionItemProps(suggestion, {
                                        style,
                                      })}
                                    >
                                      {suggestion.description}
                                    </div>
                                  );
                                })}
                              </div>
                            )}
                          </div>
                        )}
                      </PlacesAutocomplete>
                      {/* Display error message */}
                      {errors.address && (
                        <span className="error">{errors.address}</span>
                      )}
                    </div>
                    <div className="form-group">
                      <label htmlFor="cityInput">City</label>
                      <input
                        type="text"
                        className="form-control"
                        id="cityInput"
                        placeholder="Enter your city"
                        value={city}
                        onChange={(e) => setCity(e.target.value)}
                        onBlur={() => handleBlur("city", city)}
                      />
                      {/* Display error message */}
                      {errors.city && (
                        <span className="error">{errors.city}</span>
                      )}
                    </div>
                    <div className="row addr-m-padding">
                      <div className="col-md-6 col-xs-12 form-group select-drop-wrap">
                        <label htmlFor="stateSelect">State</label>
                        <Select
                          id="stateSelect"
                          classNamePrefix="form-select"
                          options={stateOptions}
                          value={selectedState}
                          onChange={handleStateChange}
                          onBlur={() => handleBlur("state", selectedState)}
                        />
                        {/* Display error message */}
                        {errors.state && (
                          <span className="error">{errors.state}</span>
                        )}
                      </div>
                      <div className="col-md-6 col-xs-12 form-group">
                        <label htmlFor="pinCodeInput">Zip Code</label>
                        <input
                          type="text"
                          className="form-control"
                          id="pinCodeInput"
                          placeholder="Enter your Zip Code"
                          value={pinCode}
                          onChange={(e) => setPinCode(e.target.value)}
                          onBlur={() => handleBlur("pinCode", pinCode)}
                        />
                        {/* Display error message */}
                        {errors.pinCode && (
                          <span className="error">{errors.pinCode}</span>
                        )}
                      </div>
                    </div>
                    <div className="form-group">
                      <label htmlFor="passwordInput">Password</label>
                      <input
                        type="password"
                        className="form-control"
                        id="passwordInput"
                        placeholder="Enter your password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        onBlur={() => handleBlur("password", password)}
                      />
                      {/* Display error message */}
                      {errors.password && (
                        <span className="error">{errors.password}</span>
                      )}
                    </div>
                    <div className="form-group">
                      <label htmlFor="confirmPasswordInput">
                        Confirm Password
                      </label>
                      <input
                        type="password"
                        className="form-control"
                        id="confirmPasswordInput"
                        placeholder="Confirm your password"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        onBlur={() =>
                          handleBlur("confirmPassword", confirmPassword)
                        }
                      />
                      {/* Display error message */}
                      {errors.confirmPassword && (
                        <span className="error">{errors.confirmPassword}</span>
                      )}
                    </div>
                    <PaymentForm
                      accountType={accountType}
                      setAccountType={setAccountType}
                      cardType={cardType} 
                      setCardType={setCardType} 
                      routingNumber={routingNumber}
                      setRoutingNumber={setRoutingNumber}
                      accountNumber={accountNumber}
                      setAccountNumber={setAccountNumber}
                      setRepeatAccountNumber={setRepeatAccountNumber} 
                      paymentMethod={paymentMethod}
                      setPaymentMethod={setPaymentMethod}
                      transitTokenObject={transitTokenObject}
                      setTransitTokenObject={setTransitTokenObject}
                      bankInfo={bankInfo}
                      setBankInfo={setBankInfo}
                      accountName={accountName}
                      setAccountName={setAccountName}
                      isShowTermsAndConditions={true}
                      achAgree={achAgree}
                      setAchAgree={setAchAgree}
                      creditCardAgree={creditCardAgree}
                      setCreditCardAgree={setCreditCardAgree}
                      termsAgree={termsAgree}
                      setTermsAgree={setTermsAgree}
                      smsTermsAgree={smsTermsAgree}
                      setSmsTermsAgree={setSmsTermsAgree}
                      serviceFee={serviceFee}
                      setServiceFee={setServiceFee}
                    />
                    <div className="text-left submit-wrap">
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={handleFormSubmit}
                        disabled={
                          paymentMethod === "creditCard"
                            ? !transitTokenObject
                            : false
                        }
                      >
                        Signup <FontAwesomeIcon icon={faArrowRight} />
                      </button>
                    </div>
                  </form>
                </div>
              </div>
              <div className="col-md-1 col-sm-12 col-xs-12"></div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default BillingInformation;
