import React, { useContext, useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCartPlus, faEye, faTrashAlt, faXmark } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import { SearchResultsContext } from './SearchResultsContext';
import { useCart } from './CartContext';
import { useUI } from './UIContext';
import { API_URL } from '../../config';

const SearchResultsDrawer = () => {
  const { searchResults } = useContext(SearchResultsContext);
  const { toggleBackdrop, showContent } = useUI();
  const navigate = useNavigate();
  const { cart, addToCart, removeFromCart } = useCart();
  const [isMobile, setIsMobile] = useState(false);

  const formatCurrency = value => `$${parseFloat(value).toFixed(2)}`;

  const handleAddAllToCart = async (accountNumber) => {
    try {
      const response = await fetch(`${API_URL}/property/${accountNumber}/bills`);
      const bills = await response.json();
      if (response.ok) {
        bills.forEach(bill => {
          if (!cart.some(item => item.id === bill.id) && !bill.is_delinquent && !bill.has_order) {
            addToCart(bill);
          }
        });
      } else {
        console.error('Error fetching bills:', bills.message);
        alert('Failed to fetch bills for this account.');
      }
    } catch (err) {
      console.error('Error:', err);
      alert('An error occurred while adding bills to the cart.');
    }
  };

  const isItemInCart = (itemId) => cart.some(item => item.id === itemId);

  // Detect if user is on mobile
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.matchMedia('(max-width: 768px)').matches);
    };

    window.addEventListener('resize', handleResize);
    handleResize(); // Check screen size on initial load

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    showContent && (
      <div>
        {/* Desktop and Mobile View */}
        <div className="content-div content-popup">
          <div className="close-content-div">
            <button className="close-link" onClick={() => toggleBackdrop(false)} style={{ cursor: 'pointer' }}>
              <FontAwesomeIcon icon={faXmark} />
            </button>
          </div>
          <div className="white-wrapper p-30 mt-30" style={{ height: 'auto', overflow: 'scroll' }}>
          <div class="heading-center"><h3><span>Accounts</span></h3></div>
            
            {/* Render different layout for mobile */}
            {isMobile ? (
              <div className="row">

              <div className="col-xs-12">
                {searchResults.map((result) => (

                  <div key={result.id} className="cart-list-card">
                    <div className="cart-info">
                      <div class="cart-info-items"><span>Account</span> {result.account_number}</div>
                      <div class="cart-info-items"><span>Owner Name</span> {result.owner_name}</div>
                      <div class="cart-info-items"><span>Address</span> {result.property_address}</div>
                    </div>
                    <div className="cart-actions">
                      <div className="balance-info">
                        {parseFloat(result.total_balance) > 0 ? formatCurrency(result.total_balance) : 'All Paid'}
                      </div>

                      <div className="action-links">
                        {result.has_order ? (
                          <div className="order-placed" style={{ color: '#fff' }}>Order Placed</div>
                        ) : isItemInCart(result.id) ? (
                          <button onClick={() => removeFromCart(result.id)}>
                            <FontAwesomeIcon icon={faTrashAlt} />
                          </button>
                        ) : (
                          <button
                            onClick={() => handleAddAllToCart(result.account_number)}
                            disabled={result.is_delinquent}
                            style={result.is_delinquent ? { opacity: 0.5, cursor: 'not-allowed' } : {}}
                          >
                            <FontAwesomeIcon icon={faCartPlus} />
                          </button>
                        )}
                        <button
                          onClick={() => {
                            navigate(`/property-detail/${result.id}`);
                            toggleBackdrop(false);
                          }}
                          disabled={result.is_delinquent}
                          style={result.is_delinquent ? { opacity: 0.5, cursor: 'not-allowed' } : {}}
                        >
                          <FontAwesomeIcon icon={faEye} />
                        </button>
                      </div>
                    </div>
                    
                  </div>
                ))}
              </div>
              </div>
            ) : (
              <table className="table table-striped-columns">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Owner</th>
                    <th>Address</th>
                    <th>Municipality</th>
                    <th>State</th>
                    <th className="text-right">Due</th>
                    <th width="10%" className="text-right">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {searchResults.map((result) => (
                    <tr key={result.id}>
                      <td>{result.account_number}</td>
                      <td>{result.owner_name}</td>
                      <td>{result.property_address}</td>
                      <td>{result.municipality_name}</td>
                      <td>{result.state_name}</td>
                      <td className="text-right">
                        {parseFloat(result.total_balance) > 0 ? (
                          <span className="pending-bill">
                            {formatCurrency(result.total_balance)}
                            {result.is_delinquent && (
                              <span style={{ color: 'red', marginLeft: '10px' }}>Delinquent</span>
                            )}
                          </span>
                        ) : (
                          <span className="already-paid">All Paid</span>
                        )}
                      </td>
                      <td className="text-right">
                        {result.has_order ? (
                          <span className="order-placed" style={{ color: 'green' }}>Order Placed</span>
                        ) : isItemInCart(result.id) ? (
                          <button onClick={() => removeFromCart(result.id)}>
                            <FontAwesomeIcon icon={faTrashAlt} />
                          </button>
                        ) : (
                          <button
                            onClick={() => handleAddAllToCart(result.account_number)}
                            disabled={result.is_delinquent}
                            style={result.is_delinquent ? { opacity: 0.5, cursor: 'not-allowed' } : {}}
                          >
                            <FontAwesomeIcon icon={faCartPlus} />
                          </button>
                        )}
                        <button
                          onClick={() => {
                            navigate(`/property-detail/${result.id}`);
                            toggleBackdrop(false);
                          }}
                          disabled={result.is_delinquent}
                          style={result.is_delinquent ? { opacity: 0.5, cursor: 'not-allowed' } : {}}
                        >
                          <FontAwesomeIcon icon={faEye} />
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </div>
        </div>

        <div className="dull-box"></div>
      </div>  
    )
  );
};

export default SearchResultsDrawer;
