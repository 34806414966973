import React from 'react';
import './Loading.css'; // Import your CSS file for styling

const Loading = ({ text }) => {
  return (
    <div className="loading-container">
      <div className="custom-loader"></div>
      <p className="loading-text">{text}</p>
    </div>
  );
};

export default Loading;
