import React, { createContext, useContext, useState } from 'react';

const UIContext = createContext();

export const useUI = () => useContext(UIContext);

export const UIProvider = ({ children }) => {
  const [showBackdrop, setShowBackdrop] = useState(false);
  const [showContent, setShowContent] = useState(false);

  const toggleBackdrop = (show) => {
    setShowBackdrop(show);
    setShowContent(show);  // Assuming you want to toggle content and backdrop together
  };

  return (
    <UIContext.Provider value={{ showBackdrop, showContent, toggleBackdrop }}>
      {children}
    </UIContext.Provider>
  );
};
