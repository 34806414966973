import React, { useState, useEffect } from 'react';

import { useNavigate } from 'react-router-dom';

const Footer = () => {

    const imageUrlLogo = process.env.PUBLIC_URL + '/images/rmt.jpg';

    return (
        <div className="footer">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-6 col-sm-12">
                        <ul className="footer-links">
                            <li><a href="/privacy/policy">Privacy Policy</a></li>
                            <li><a href="/terms/use">Terms of Use</a></li>
                            <li><a href="/contact/us">Need Help?</a></li>
                        </ul>
                    </div>
                    <div className="col-md-6 col-sm-12">
                        <div className="copyright">Copyright &copy; 2023 RMT. All Right Reserved.</div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Footer;
