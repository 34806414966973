import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faUserCircle,
    faArrowRight,
    faUser,
    faLock,
    faKey,
    faTimes,
} from '@fortawesome/free-solid-svg-icons';
import Swal from 'sweetalert2';
import { API_URL } from '../config';
import { useAuth } from './component/AuthContext';
import { useNavigate } from 'react-router-dom';
import Loading from './component/Loading';
import HeaderProperty from "./component/HeaderProperty";

const LoginPage = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [resetEmail, setResetEmail] = useState('');
    const [mode, setMode] = useState('login'); // 'login' or 'resetPassword'
    const { login } = useAuth();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [isScrolled, setIsScrolled] = useState(false);
    const [isMobile, setIsMobile] = useState(false);

    const handleScroll = () => {
        if (window.scrollY > 50) {
            setIsScrolled(true);
        } else {
            setIsScrolled(false);
        }
    };

    // Function to check if the view is mobile or not
    const checkMobile = () => {
        setIsMobile(window.innerWidth < 768); // Threshold for mobile
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        checkMobile(); // Initial check on mount
        window.addEventListener('resize', checkMobile); // Re-check on window resize

        return () => {
            window.removeEventListener('scroll', handleScroll);
            window.removeEventListener('resize', checkMobile);
        };
    }, []);

    const handleLogin = async (e) => {
        e.preventDefault();

        if (!email || !password) {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                html: 'Please fill in both email and password fields.',
            });
            return;
        }

        if (!isValidEmail(email)) {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Please enter a valid email address.',
            });
            return;
        }

        try {
            const response = await fetch(API_URL + '/login', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email, password }),
            });

            const data = await response.json();

            if (response.ok) {
                login(data);
                navigate('/account'); // Redirect after successful login
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: data.message || 'Login failed',
                });
            }
        } catch (error) {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'An error occurred. Please try again later.',
            });
        }
    };

    const handleResetPassword = async (e) => {
        e.preventDefault();

        if (!isValidEmail(resetEmail)) {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Please enter a valid email address.',
            });
            return;
        }

        setLoading(true);

        try {
            const response = await fetch(API_URL + '/password/email', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email: resetEmail }),
            });

            const data = await response.json();

            if (response.ok) {
                Swal.fire({
                    icon: 'success',
                    title: 'Reset Password Email Sent',
                    text: data.message || 'Check your email for reset instructions.',
                });
                setMode('login'); // Switch back to login mode after sending reset email
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: data.message || 'Failed to send reset email',
                });
            }
        } catch (error) {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'An error occurred. Please try again later.',
            });
        } finally {
            setLoading(false);
        }
    };

    const isValidEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    // Web View Return
    const webView = () => (
        <div>
            <div className={`property-detail-head${isScrolled ? ' scrolled' : ''}`}>
                <HeaderProperty />
            </div>
            <div className="property-detail-head-inner">
                <div className="container">
                    {loading && <Loading text="Sending password reset link..." />}
                    <h1>{mode === 'login' ? 'Sign In' : 'Reset Password'}</h1>
                    <h2>You can sign in here for property tax payments.</h2>
                </div>
                <div className="dark"></div>
            </div>
            <div className="login-landing">
                <div className="container">
                    <div className="row">
                        <div className="col-md-1 col-sm-1 col-xs-12"></div>
                        <div className="col-md-5 col-sm-5 col-xs-12 login-landing-wrap">
                            {mode === 'login' ? (
                                <form onSubmit={handleLogin}>
                                    <div className="login-input">
                                        <FontAwesomeIcon icon={faUser} />
                                        <input
                                            id="loginEmail"
                                            type="text"
                                            placeholder="Email"
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                        />
                                    </div>
                                    <div className="login-input">
                                        <FontAwesomeIcon icon={faLock} />
                                        <input
                                            type="password"
                                            placeholder="Password"
                                            value={password}
                                            onChange={(e) => setPassword(e.target.value)}
                                        />
                                    </div>
                                    <button type="submit" className="btn btn-primary login-button">
                                        Login <FontAwesomeIcon icon={faArrowRight} />
                                    </button>
                                </form>
                            ) : (
                                <form onSubmit={handleResetPassword}>
                                    <div className="login-input">
                                        <FontAwesomeIcon icon={faUser} />
                                        <input
                                            id="resetEmail"
                                            type="text"
                                            placeholder="Email"
                                            value={resetEmail}
                                            onChange={(e) => setResetEmail(e.target.value)}
                                        />
                                    </div>
                                    <button type="submit" className="btn btn-primary login-button">
                                        Reset Password <FontAwesomeIcon icon={faArrowRight} />
                                    </button>
                                    <button
                                        onClick={() => setMode('login')}
                                        className="btn-link"
                                    >
                                        Back to Login?
                                    </button>
                                </form>
                            )}
                        </div>
                        <div className="col-md-5 col-sm-5 col-xs-12">
                            <ul className="register-links">
                                <li>
                                    <button
                                        onClick={() => setMode('resetPassword')}
                                        className="btn-link"
                                    >
                                        Forgot your password?
                                        <span>No problem</span>
                                    </button>
                                </li>
                                <li>
                                    <button
                                        onClick={() => setMode('resetPassword')}
                                        className="btn-link"
                                    >
                                        Looking to create an account?
                                        <span>Please first find your bill.</span>
                                    </button>
                                </li>
                            </ul>
                        </div>
                        <div className="col-md-1 col-sm-1 col-xs-12"></div>
                    </div>
                </div>
            </div>
        </div>
    );

    // Mobile View Return
    const mobileView = () => (
         <div>
            <div className={`property-detail-head${isScrolled ? ' scrolled' : ''}`}>
                <HeaderProperty />
            </div>
            
            <div className="mobile-landing">
                <div className="mobile-landing-inner">
                    <div className="login-landing">
                        <div className="container">
                            <div className="row">
                                <div className="col-xs-12 login-landing-wrap">
                                    <div className="title-header-mobile">
                                        <h1>Sign in</h1>
                                        <h2>You can sign in here for property tax payments.</h2>
                                    </div>
                                    {mode === 'login' ? (
                                        <form onSubmit={handleLogin}>
                                            <div className="login-input">
                                                <FontAwesomeIcon icon={faUser} />
                                                <input
                                                    id="loginEmail"
                                                    type="text"
                                                    placeholder="Email"
                                                    value={email}
                                                    onChange={(e) => setEmail(e.target.value)}
                                                />
                                            </div>
                                            <div className="login-input">
                                                <FontAwesomeIcon icon={faLock} />
                                                <input
                                                    type="password"
                                                    placeholder="Password"
                                                    value={password}
                                                    onChange={(e) => setPassword(e.target.value)}
                                                />
                                            </div>
                                            <button type="submit" className="btn btn-primary login-button">
                                                Login <FontAwesomeIcon icon={faArrowRight} />
                                            </button>
                            
                                            <button onClick={() => setMode('resetPassword')} className="btn-link" >
                                                Forgot your password?
                                            </button>
                                        </form>
                                    ) : (
                                        <form onSubmit={handleResetPassword}>
                                            <div className="login-input">
                                                <FontAwesomeIcon icon={faUser} />
                                                <input
                                                    id="resetEmail"
                                                    type="text"
                                                    placeholder="Email"
                                                    value={resetEmail}
                                                    onChange={(e) => setResetEmail(e.target.value)}
                                                />
                                            </div>
                                            <button type="submit" className="btn btn-primary login-button">
                                                Reset Password <FontAwesomeIcon icon={faArrowRight} />
                                            </button>

                                            <a href="#"
                                                onClick={() => setMode('login')}
                                                className="btn-link"
                                            >
                                                Back to Login?
                                            </a>

                                        </form>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div> 
            </div>       
        </div>
    );

    return isMobile ? mobileView() : webView();
};

export default LoginPage;
