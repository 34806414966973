import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons'; // Import the close icon
import Header from './component/Header';
import { API_URL } from '../config';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; // Import the CSS
import './RenewalHistory.css';
import Footer from './component/Footer';
import { Link, useParams } from 'react-router-dom';

const RenewalHistory = () => {
  const { plateNumber } = useParams();
  const [plateRenewals, setPlateRenewals] = useState([]);
  const [loading, setLoading] = useState(true);
  const [flashMessage, setFlashMessage] = useState('');

  useEffect(() => {
    // Check for a flash message in local storage
    const storedFlashMessage = localStorage.getItem('flashMessage');
    if (storedFlashMessage) {
      // If a flash message is found, set it and then remove it from local storage
      setFlashMessage(storedFlashMessage);
      localStorage.removeItem('flashMessage');
    }

    // Fetch plate renewals for the authenticated user (your existing code)
  }, []);

  useEffect(() => {
    loadPlateRenewalsHistoryData();
  }, []);


  const loadPlateRenewalsHistoryData = () => {
    // Get the authentication token from localStorage
    const authToken = localStorage.getItem('token');

    // Check if the authToken exists before making the API request
    if (!authToken) {
      // Handle the case where the token is missing
      return;
    }

    // Fetch data from the API endpoint
    fetch(API_URL + `/renewal-history/${plateNumber}`, {
      headers: {
        Authorization: `Bearer ${authToken}`, // Include the token in the header
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setPlateRenewals(data);
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
        setLoading(false);
      });
  };

  const hideFlashMessage = () => {
    setFlashMessage('');
  };

  return (
    <div className="account-wrap account-page">
      <Header />
      <div className="container-fluid p-30">
        <h2>Renewal History</h2>
        <ol class="breadcrumb-wrap mb-30">
          <li><Link>RMT</Link></li>
          <li className="active">Renewal History</li>
          <li className="active">{plateNumber}</li>
        </ol>

        <div className="card-wrap full-width">
          <div className="card-inner full-width">
            <div className="">
              <div className="card-header">
                <h4 className="pull-left">{plateNumber} Renewal History</h4>
              </div>
              <div className="card-body">
                <div class="table-responsive">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>Municipality</th>
                        <th>Card Holder Name</th>
                        <th>Transaction Date</th>
                        <th>Transaction Id</th>
                        <th>Payment Detail</th>
                        <th>Status</th>
                        <th>Amount</th>
                        <th>Payment Method</th>
                      </tr>
                    </thead>
                    <tbody>
                      {loading ? (
                        <tr>
                          <td colSpan="5">Loading...</td>
                        </tr>
                      ) : plateRenewals.length === 0 ? (
                        <tr>
                          <td colSpan="5">No data available</td>
                        </tr>
                      ) : (
                        plateRenewals.map((data, index) => (
                          <tr key={index}>
                            <td>{data.municipality_name}</td>
                            <td>{data.card_holder_name}</td>
                            <td>{data.transaction_date}</td>
                            <td>{data.pay_transaction_id}</td>
                            <td>{data.payment_detail}</td>
                            <td>{data.status}</td>
                            <td>{data.amount}</td>
                            <td>{data.payment_method}</td>
                          </tr>
                        ))
                      )}
                    </tbody>
                  </table>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>


      <div className="">
        <div className="">
          {flashMessage && (
            <div className="alert alert-success alert-dismissible mx-auto" role="alert">
              <div>
                {flashMessage}
                <button
                  type="button"
                  className="close"
                  data-dismiss="alert"
                  aria-label="Close"
                  onClick={hideFlashMessage}
                >
                  <span aria-hidden="true">
                    <FontAwesomeIcon icon={faTimes} />
                  </span>
                </button>
              </div>
            </div>
          )}

        </div>
        <ToastContainer />
      </div>
      <Footer />
    </div>
  );
};

export default RenewalHistory;
