import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import Select from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faArrowRight, faHandPointUp } from '@fortawesome/free-solid-svg-icons';
import { API_URL } from '../../config';
import { toast } from 'react-toastify';

const ChangePreferredContactPopup = ({ selectedRenewalId, isOpen, onRequestClose, loadRenewalData, type }) => {
  const [notificationMethods, setNotificationMethods] = useState([]);

  useEffect(() => {
    if (isOpen) {
      fetchNotificationMethods();
    }
  }, [isOpen]);

  const fetchNotificationMethods = () => {
    const authToken = localStorage.getItem('token');

    if (!authToken) {
      return;
    }

    fetch(`${API_URL}/customer/notification/methods`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((data) => {
        setNotificationMethods(data);
      })
      .catch((error) => {
        console.error('Error fetching notification methods:', error);
      });
  };

  const makePreferredContact = (contactId) => {
    const authToken = localStorage.getItem('token');

    if (!authToken) {
      return;
    }

    fetch(`${API_URL}/plate-renewals/${selectedRenewalId}/update-contact-id`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        contact_id: contactId,
        type: type // Pass the type parameter to the API
      }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((data) => {
        toast.success('Preferred contact updated successfully', { autoClose: 3000 });
        onRequestClose();
        loadRenewalData();
      })
      .catch((error) => {
        toast.error('Failed to set preferred contact', { autoClose: 3000 });
      });
  };

  return (
    <Modal isOpen={isOpen} onRequestClose={onRequestClose} className="login-popup" overlayClassName="overlay">
      <div className="login-content add-plate-pop">
        <button className="close-button" onClick={onRequestClose}>
          <FontAwesomeIcon icon={faTimes} />
        </button>
        <h2>Choose Preferred Contact</h2>

        <div className="table-responsiveness">
          <table className="table table-striped-columns">
            <thead>
              <tr>
                <th>Email</th>
                <th>Phone</th>
                <th className="text-center">Action</th>
              </tr>
            </thead>
            <tbody>
              {notificationMethods.map((contact) => (
                <tr key={contact.id}>
                  <td className="text-left">{contact.email}</td>
                  <td className="text-left">{contact.phone}</td>
                  <td>
                    <button className="btn pop-btn action-btn" onClick={() => makePreferredContact(contact.id)}>
                      Choose <FontAwesomeIcon icon={faHandPointUp} />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </Modal>
  );
};

export default ChangePreferredContactPopup;