import React from 'react';
import './MobilePopup.css';  // Styling for the mobile popup
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';

const MobilePopup = ({ title, children }) => {
    return (
        <div className="mobile-popup">
            <div className="mobile-popup-content">
                <div className="close-btn-pop">
                    <button className="close-button-mobile-view" onClick={() => window.history.back()}>
                        <FontAwesomeIcon icon={faArrowLeft} />
                        {title || 'Go Back'}
                    </button>
                </div>
                {children}
            </div>
        </div>
    );
};

export default MobilePopup;
