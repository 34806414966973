import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faLock, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { API_URL } from '../config';
import { useNavigate, useParams } from 'react-router-dom';
import Header from './component/Header';
import Footer from './component/Footer';
import Swal from 'sweetalert2';


const ResetPassword = () => {
    const { token } = useParams();
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [email, setEmail] = useState('');
    const [errors, setErrors] = useState({});
    const navigate = useNavigate();

    const handleSubmit = (e) => {
        e.preventDefault();

        const validationErrors = {};
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        if (!email) {
            validationErrors.email = 'Please enter an email.';
        } else if (!emailRegex.test(email)) {
            validationErrors.email = 'Please enter a valid email.';
        }

        if (!newPassword) {
            validationErrors.newPassword = 'Please enter a new password.';
        }
        if (!confirmPassword) {
            validationErrors.confirmPassword = 'Please confirm your new password.';
        }
        if (newPassword !== confirmPassword) {
            validationErrors.confirmPassword = 'Passwords do not match.';
        }

        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);

            const combinedErrorMessage = Object.values(validationErrors).join('<br>');

            Swal.fire({
                icon: 'error',
                title: 'Please fix the following issues:',
                html: `${combinedErrorMessage}`,
                showCloseButton: true,
            });

        return;
        } else {
            fetch(API_URL + '/reset-password', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    email,
                    token,
                    password: newPassword,
                    password_confirmation: confirmPassword,
                }),
            })
            .then((response) => {
                if (response.status === 200) {
                    Swal.fire({
                        icon: 'success',
                        title: 'Password reset successful!',
                        timer: 2000,
                    });
                    navigate('/');
                    } else if (response.status === 422) {
                        response.json().then((data) => {
                        const errorMessages = data.errors
                        ? Object.values(data.errors).join('<br>')
                        : data.message || 'An error occurred during password reset.';
                        Swal.fire({
                            icon: 'error',
                            title: 'Password reset failed. Error(s):',
                            html: errorMessages,
                            showCloseButton: true,
                        });
                    });
                } else {
                    console.error('Unexpected status code:', response.status);
                }
            })
            .catch((error) => {
                console.error('Error resetting password:', error);
            });
        }
    };

    return (
        <div className="reset-pass" style={{ color: 'black' }}>
            <Header />
            <div className="container p-40">
                <div className="row pb-40 pt-40">
                    <div className="col-md-6 col-md-offset-3 card-inner pt-10 pb-30">
                        <h2>Reset Password</h2>
                        <form onSubmit={handleSubmit} className="normal-form">
                            <div className={`clear-both form-group${errors.email ? ' has-error' : ''}`}>
                                
                                <div className="input-group">
                                    <span className="input-group-addon">
                                        <FontAwesomeIcon icon={faEnvelope} />
                                    </span>
                                    <input
                                        type="text"
                                        id="email"
                                        className="form-control"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        placeholder="Email"
                                    />
                                </div>
                                {errors.email && <span className="help-block">{errors.email}</span>}
                            </div>
                            <div className={`clear-both form-group${errors.newPassword ? ' has-error' : ''}`}>
                                
                                <div className="input-group">
                                    <span className="input-group-addon">
                                        <FontAwesomeIcon icon={faLock} />
                                    </span>
                                    <input
                                        type="password"
                                        id="newPassword"
                                        className="form-control"
                                        value={newPassword}
                                        onChange={(e) => setNewPassword(e.target.value)}
                                        placeholder="New Password"
                                    />
                                </div>
                                {errors.newPassword && <span className="help-block">{errors.newPassword}</span>}
                            </div>
                            <div className={`clear-both form-group${errors.confirmPassword ? ' has-error' : ''}`}>
                                
                                <div className="input-group">
                                    <span className="input-group-addon">
                                        <FontAwesomeIcon icon={faLock} />
                                    </span>
                                    <input
                                        type="password"
                                        id="confirmPassword"
                                        className="form-control"
                                        value={confirmPassword}
                                        onChange={(e) => setConfirmPassword(e.target.value)}
                                        placeholder="Confirm Password"
                                    />
                                </div>
                                {errors.confirmPassword && <span className="help-block">{errors.confirmPassword}</span>}
                            </div>
                            <div className="clear-both pt-10">
                                <button type="submit" className="btn btn-primary">
                                    Reset Password <FontAwesomeIcon icon={faArrowRight} />
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        <Footer />
    </div>
  );
};

export default ResetPassword;
