import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Home from './pages/Home';
import SignupWizard from './pages/SignupWizard';
import BillingInformation from './pages/BillingInformation';
import PaymentMethods from './pages/PaymentMethods';
import Account from './pages/Account';
import Welcome from './pages/Welcome';
import Profile from './pages/Profile';
import { AuthProvider, useAuth } from './pages/component/AuthContext';
import ResetPassword from './pages/ResetPassword';
import LoginAsCustomer from './pages/LoginAsCustomer';
import CustomPages from './pages/CustomPages';
import PrivacyPolicy from './pages/PrivacyPolicy';
import TermsUse from './pages/TermsUse';
import ContactUs from './pages/ContactUs';
import HowItWorks from './pages/HowItWorks';
import Pricing from './pages/Pricing';
import TagManager from 'react-gtm-module';
import { GTM_ID, REACT_APP_DOMAIN } from './config';
import NotFound from './pages/NotFound';
import RenewalHistory from './pages/RenewalHistory';
import PropertyDetail from './pages/PropertyDetail';
import HomeProperty from './pages/HomeProperty';
import LoginPage from './pages/LoginPage';

import BottomNav from './pages/component/BottomNav';

import HowItWorksProperty from './pages/property-taxes/HowItWorks';
import PricingProperty from './pages/property-taxes/Pricing';

import Cart from './pages/Cart';
import { CartProvider } from './pages/component/CartContext';
import { UIProvider } from './pages/component/UIContext'; 
import { SearchResultsProvider } from './pages/component/SearchResultsContext';
import MobilePopup from './pages/component/MobilePopup';  // Create this new component
import "./pages/HomeProperty.css";

const tagManagerArgs = {
  gtmId: GTM_ID,
};
TagManager.initialize(tagManagerArgs);

const PrivateRoute = ({ element }) => {
  const { isAuthenticated } = useAuth();
  return isAuthenticated ? element : <Navigate to="/login" replace />;
};

const PublicRoute = ({ element }) => {
  const { isAuthenticated } = useAuth();
  return isAuthenticated && element.type.name !== 'LoginAsCustomer' ? <Navigate to="/account" replace /> : element;
};

const domain = REACT_APP_DOMAIN ?? window.location.hostname.split('.')[0];  // Simplified for demonstration

const App = () => {

  const [isMobile, setIsMobile] = useState(false);

  window.dataLayer.push({
    event: 'pageview',
  });

  // Detect if user is on mobile
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.matchMedia('(max-width: 768px)').matches);
    };

    window.addEventListener('resize', handleResize);
    handleResize(); // Check screen size on initial load

    return () => window.removeEventListener('resize', handleResize);
  }, []);


  return (
        <div className="app-container">

          <UIProvider>
            <CartProvider>
              <SearchResultsProvider>
                <Router>
                  <div className="content">
                    <AuthProvider>
                      <Routes>
                        <Route path="/" element={domain === 'property' ? <HomeProperty /> : <PublicRoute element={<Home />} />} />
                        <Route path="/property-home" element={<HomeProperty />} />
                        <Route path="/vehicle-home" element={<Home />} />
                        <Route path="/login" element={<LoginPage />} /> {/* New Login Page Route */}

                        <Route path="/password/reset/:token" element={<PublicRoute element={<ResetPassword />} />} />
                        <Route path="/signup-wizard/:onboardingId" element={<PublicRoute element={<SignupWizard />} />} />

                        <Route path="/property-detail/:id" element={isMobile ? <MobilePopup title="Property Detail"><PropertyDetail /></MobilePopup> : <PropertyDetail />} />

                        <Route path="/cart" element= {<Cart />} />

                        <Route path="/plate-information/:onboardingId/:pageId" element={<PublicRoute element={<CustomPages />} />} />
                        <Route path="/billing-information/:onboardingId" element={<PublicRoute element={<BillingInformation />} />} />
                        <Route path="/terms/use" element={<TermsUse />} />
                        <Route path="/privacy/policy" element={<PrivacyPolicy />} />
                        <Route path="/how/works" element={<HowItWorks />} />
                        <Route path="/pricing" element={<Pricing />} />
                        <Route path="/property/how/works" element={isMobile ? <MobilePopup title="How it Works"><HowItWorksProperty /></MobilePopup> : <HowItWorksProperty />} />

                        <Route path="/property/pricing" element={isMobile ? <MobilePopup title="Pricing"><PricingProperty /></MobilePopup> : <PricingProperty />} />


                        <Route path="/contact/us" element={<ContactUs />} />
                        <Route path="/login-as-customer/:userId" element={<PublicRoute element={<LoginAsCustomer />} />} />
                        <Route path="/account" element={<PrivateRoute element={<Account />} />} />
                        <Route path="/welcome" element={<PrivateRoute element={<Welcome />} />} />
                        <Route path="/payment-methods" element={<PrivateRoute element={<PaymentMethods />} />} />
                        <Route path="/profile" element={<PrivateRoute element={<Profile />} />} />
                        <Route path="/renewal-history/:plateNumber" element={<PrivateRoute element={<RenewalHistory />} />} />
                        <Route path="*" element={<NotFound />} />
                      </Routes>
                    </AuthProvider>
                  </div>
                  <div className="footer-mobile">
                    <BottomNav />
                  </div>
                </Router>
              </SearchResultsProvider>
            </CartProvider>
          </UIProvider>
    </div>
  );
};

export default App;
