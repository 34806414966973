import { API_URL } from "../config";
import { useAuth } from './component/AuthContext';
import { useNavigate } from 'react-router-dom';
import { useParams } from "react-router-dom";
import React, { useEffect } from "react";

const LoginAsCustomer = () => {
    const { login } = useAuth();
    const navigate = useNavigate();
    const { userId } = useParams();

    useEffect(() => {
        //Admin Login as customer API 
        fetch(API_URL + "/login-as-customer", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                user_id: userId,
                secret_code: 'ACQVn09mq2J7pLib2Bt1e88MPFqkyMZIvg',
            }),
        }).then((response) => {
            if (!response.ok) {
                console.log('redirect /')
                navigate('/');
            }
            return response.json();
        }).then((data) => {
            if (data && data.message == 'Login successful') {
                login(data);
                navigate('/account');
            }
        }).catch((error) => console.error("Error:", error));
    }, []);

    return (
        <div>Loading...</div>
    )
}

export default LoginAsCustomer;
