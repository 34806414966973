import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';

const StateMunicipalityDropdown = ({ data, selectedMunicipality, setSelectedMunicipality }) => {
  const [selectedState, setSelectedState] = useState(null);
  const [showMunicipalityDropdown, setShowMunicipalityDropdown] = useState(false);

  useEffect(() => {
    if (selectedMunicipality) {
      const stateOfSelectedMunicipality = data.find((state) =>
        state.children.some((municipality) => municipality.value === selectedMunicipality)
      );

      if (stateOfSelectedMunicipality) {
        setSelectedState({ value: stateOfSelectedMunicipality.value, label: stateOfSelectedMunicipality.label });
        setShowMunicipalityDropdown(true);
        setSelectedMunicipality(selectedMunicipality);
      }
    }
  }, [data, selectedMunicipality]);

  const handleStateChange = (selectedOption) => {
    setSelectedState(selectedOption);
    setShowMunicipalityDropdown(true);
    setSelectedMunicipality(null); // Reset selectedMunicipality when state changes
  };

  const handleBackButtonClick = () => {
    setSelectedState(null);
    setShowMunicipalityDropdown(false);
    setSelectedMunicipality(null);
  };

  const stateOptions = data.map((state) => ({
    value: state.value,
    label: state.label,
  }));

  const selectedStateData = selectedState
    ? data.find((state) => state.value === selectedState.value)
    : null;

  const municipalityOptions = selectedStateData
    ? selectedStateData.children.map((municipality) => ({
        value: municipality.value,
        label: municipality.label,
      }))
    : [];
    return (
      <div>
        <div className={`state-section ${showMunicipalityDropdown ? 'hidden' : ''}`}>
          <Select
            options={stateOptions}
            onChange={handleStateChange}
            placeholder="Select a state"
            classNamePrefix={`form-select custom-select`} // Add your custom class prefix
            value={selectedState}
          />
        </div>
        <div className={`municipality-section ${showMunicipalityDropdown ? '' : 'hidden'}`}>
          <Select
            options={municipalityOptions}
            placeholder="Select a county"
            classNamePrefix={`form-select custom-select`} // Add your custom class prefix
            onChange={setSelectedMunicipality}
            value={selectedMunicipality !== null ? municipalityOptions.find((option) => option.value === selectedMunicipality) : null}
          />
          <div className="selected-state" onClick={handleBackButtonClick}>
            <FontAwesomeIcon icon={faArrowLeft} className="icon" />
            {selectedState && (
              <span className="selected-state-label">State: {selectedState.label}</span>
            )}
          </div>
        </div>
      </div>
    );
    };


export default StateMunicipalityDropdown;
