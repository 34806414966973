import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';

const StepsIndicator = ({ currentStep, stepsName }) => {
  const isStepCompleted = (stepNumber) => {
    return stepNumber <= currentStep;
  };

  const renderStepContent = (stepNumber) => {
    const isCompleted = isStepCompleted(stepNumber);
    return (
      <div className={`step-content${isCompleted ? ' completed' : ''}`}>
        <div className="step-icon">
          {isCompleted ? (
            <div className="step-circle completed">
              <FontAwesomeIcon icon={faCheck} className="completed-icon" />
            </div>
          ) : (
            <div className="step-circle">
              <FontAwesomeIcon icon={faTimes} className="remaining-icon" />
            </div>
          )}
        </div>
      </div>
    );
  };

  const renderSteps = () => {
    const steps = [];
    for (let stepNumber = 1; stepNumber <= stepsName.length; stepNumber++) {
      steps.push(
        <div className={`step-item${isStepCompleted(stepNumber) ? ' accomplished' : ''}`} key={stepNumber}>
          {renderStepContent(stepNumber)}
          <span className="step-text">{stepsName[stepNumber - 1]}</span>
        </div>
      );

      // Add a step line after each step except the last one
      if (stepNumber < stepsName.length) {
        steps.push(<div className="step-line" key={`line-${stepNumber}`} />);
      }
    }
    return steps;
  };

  return <div className="steps-indicator">{renderSteps()}</div>;
};

export default StepsIndicator;
