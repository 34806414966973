import React, { useState, useEffect, useCallback, useMemo } from "react";
import { useCart } from './CartContext'; 
import Swal from 'sweetalert2';
import { useAuth } from "./AuthContext"; 
import { useNavigate } from 'react-router-dom'; 

// Import the separate components for web and mobile
import PaymentFormWeb from './PaymentFormWeb';
import PaymentFormMobile from './PaymentFormMobile';

import {
    API_URL,
} from "../../config";

function PaymentGateway() {
    const [isMobile, setIsMobile] = useState(true);

    // Conditional rendering of Web or Mobile payment form
    return isMobile ? (
        <PaymentFormMobile/>
    ) : (
        <PaymentFormWeb />
    );
}

export default PaymentGateway;
