import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTimes } from '@fortawesome/free-solid-svg-icons';
import Header from './component/Header';
import Switch from 'react-switch';
import { API_URL } from '../config';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AddPlatePopup from './component/AddPlatePopup';
import ChangePreferredContactPopup from './component/ChangePreferredContactPopup';
import ChangePreferredPaymentMethodPopup from './component/ChangePreferredPaymentMethodPopup';
import PropertySchedulePopup from './component/PropertySchedulePopup'; // Import the PropertySchedulePopup component
import Footer from './component/Footer';
import './Account.css';
import { Link } from 'react-router-dom';

const Account = () => {
  const navigate = useNavigate();
  const [plateRenewals, setPlateRenewals] = useState([]);
  const [propertyRenewals, setPropertyRenewals] = useState([]);
  const [loading, setLoading] = useState(true);
  const [flashMessage, setFlashMessage] = useState('');
  const [isAddPlateModalOpen, setIsAddPlateModalOpen] = useState(false);
  const [isPreferredContactModalOpen, setIsPreferredContactModalOpen] = useState(false);
  const [isPreferredPaymentMethodModalOpen, setIsPreferredPaymentMethodModalOpen] = useState(false);
  const [isScheduleModalOpen, setIsScheduleModalOpen] = useState(false); // State for schedule modal
  const [scheduleData, setScheduleData] = useState([]); // State to store schedule data
  const [selectedRenewalId, setSelectedRenewalId] = useState(null);
  const [renewalType, setRenewalType] = useState('');

  // Open Preferred Contact Modal for Vehicle or Property
  const openPreferredContactModal = (renewalId, type) => {
    setSelectedRenewalId(renewalId);
    setRenewalType(type);
    setIsPreferredContactModalOpen(true);
  };

  const openPreferredPaymentMethodModal = (renewalId) => {
    setSelectedRenewalId(renewalId);
    setIsPreferredPaymentMethodModalOpen(true);
  };

  // Load data on component mount
  useEffect(() => {
    const storedFlashMessage = localStorage.getItem('flashMessage');
    if (storedFlashMessage) {
      setFlashMessage(storedFlashMessage);
      localStorage.removeItem('flashMessage');
    }
    loadPlateRenewalData();
    loadPropertyRenewalData();
  }, []);

  // Load Plate Renewal Data (Vehicles)
  const loadPlateRenewalData = () => {
    const authToken = localStorage.getItem('token');
    if (!authToken) return;
    fetch(`${API_URL}/plate-renewals`, {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    })
    .then((response) => response.json())
    .then((data) => {
      setPlateRenewals(data);
      setLoading(false);
    })
    .catch((error) => {
      console.error('Error fetching plate renewal data:', error);
      setLoading(false);
    });
  };

  // Load Property Renewal Data
  const loadPropertyRenewalData = () => {
    const authToken = localStorage.getItem('token');
    if (!authToken) return;
    fetch(`${API_URL}/property-renewals`, {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    })
    .then((response) => response.json())
    .then((data) => {
      setPropertyRenewals(data);
      setLoading(false);
    })
    .catch((error) => {
      console.error('Error fetching property renewal data:', error);
      setLoading(false);
    });
  };

  const hideFlashMessage = () => {
    setFlashMessage('');
  };

  // Toggle active status for vehicles or properties
  const toggleStatus = (renewalId, type, index) => {
    let updatedRenewals = [];
    if (type === 'plate') {
      updatedRenewals = [...plateRenewals];
      const plate = updatedRenewals[index];
      plate.is_active = !plate.is_active;
    } else if (type === 'property') {
      updatedRenewals = [...propertyRenewals];
      const property = updatedRenewals[index];
      property.is_active = !property.is_active;
    }

    const authToken = localStorage.getItem('token');
    const newStatusMessage = type === 'plate'
      ? updatedRenewals[index].is_active
        ? 'Auto renewal of plate activated.'
        : 'Auto renewal of plate deactivated.'
      : updatedRenewals[index].is_active
        ? 'Auto renewal of property activated.'
        : 'Auto renewal of property deactivated.';

    fetch(`${API_URL}/plate-renewals/${renewalId}/change-status`, {
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ is_active: updatedRenewals[index].is_active, type }),
    })
    .then((response) => {
      if (!response.ok) {
        throw new Error('API request failed');
      }
      toast.success(`${newStatusMessage}`, {
        autoClose: 3000,
      });
    })
    .catch((error) => {
      console.error('Error updating status:', error);
      toast.error('Failed to update status', { autoClose: 3000 });
    });

    if (type === 'plate') {
      setPlateRenewals(updatedRenewals);
    } else if (type === 'property') {
      setPropertyRenewals(updatedRenewals);
    }
  };

  const openAddPlateModal = () => {
    setIsAddPlateModalOpen(true);
  };

  const closeAddPlateModal = () => {
    setIsAddPlateModalOpen(false);
  };

  const ClosePreferredContactModal = () => {
    setIsPreferredContactModalOpen(false);
  };

  const ClosePreferredPaymentMethodModal = () => {
    setIsPreferredPaymentMethodModalOpen(false);
  };

  const openPropertyHomePage = () => {
    navigate('/');
  };

  // Load schedule data for properties and open modal
  const loadScheduleData = (renewalId) => {
    const authToken = localStorage.getItem('token');

    fetch(`${API_URL}/renewals/${renewalId}/schedule`, {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setScheduleData(data);
        setSelectedRenewalId(renewalId); // Set the renewalId for future operations (e.g., skip/resume)
        setIsScheduleModalOpen(true);
      })
      .catch((error) => {
        console.error('Error fetching schedule:', error);
        toast.error('Failed to fetch schedule', { autoClose: 3000 });
      });
  };

  const closeScheduleModal = () => {
    setIsScheduleModalOpen(false);
  };

  const activePlateRenewals = plateRenewals.filter((renewal) => renewal.is_active).length;
  const activePropertyRenewals = propertyRenewals.length;

  return (
    <div className="account-wrap account-page">
      <Header />
      <div className="container-fluid p-30">
        <h2>Dashboard</h2>
        <ol className="breadcrumb-wrap mb-30">
          <li><Link>RMT</Link></li>
          <li className="active">Dashboard</li>
        </ol>
        <div className="row pb-30">
          <div className="col-md-3 col-xs-12">
            <div className="analyse-box">
              <div className="heading">Total Plates</div>
              <div className="value">{activePlateRenewals}</div>
              <span className="glyphicon glyphicon-stats"></span>
            </div>
          </div>
          <div className="col-md-3 col-xs-12">
            <div className="analyse-box">
              <div className="heading">Total Properties</div>
              <div className="value">{activePropertyRenewals}</div>
              <span className="glyphicon glyphicon-stats"></span>
            </div>
          </div>
          <div className="col-md-3 col-xs-12">
            <div className="analyse-box">
              <div className="heading">Total Licenses</div>
              <div className="value">0</div>
              <span className="glyphicon glyphicon-stats"></span>
            </div>
          </div>
          <div className="col-md-3 col-xs-12">
            <div className="analyse-box">
              <div className="heading">Total Utilities</div>
              <div className="value">0</div>
              <span className="glyphicon glyphicon-stats"></span>
            </div>
          </div>
        </div>

        <div className="card-wrap full-width">
          <div className="card-inner full-width">
            <div className="">
              <div className="card-header">
                <h4 className="pull-left">Property Renewal Information</h4>
                <button className="btn btn-primary add-more pull-right" onClick={openPropertyHomePage}>
                  <FontAwesomeIcon icon={faPlus} /> Add More
                </button>
               </div>
              <div className="card-body">
                <div className="table-responsive">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>Account Number</th>
                        <th>Bill Number</th>
                        <th>Due Date</th>
                        <th>Amount Due</th>
                        <th>Payment Day of Every Month</th>
                        <th>Split Option</th>
                        <th>EMI Option</th>
                        <th>Preferred Contact</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {loading ? (
                        <tr>
                          <td colSpan="9">Loading...</td>
                        </tr>
                      ) : propertyRenewals.length === 0 ? (
                        <tr>
                          <td colSpan="9">No data available</td>
                        </tr>
                      ) : (
                        propertyRenewals.map((data, index) => (
                          <tr key={index}>
                            <td>{data.account_number}</td>
                            <td>{data.bill_number}</td>
                            <td>{data.due_date}</td>
                            <td>{data.amount_due}</td>
                            <td>{data.payment_day_of_every_month}</td>
                            <td>{data.split_option}</td>
                            <td>
                              {data.split_option !== 'full' && (
                                <Link role="button" onClick={() => loadScheduleData(data.id)}>
                                  See Schedule
                                </Link>
                              )}
                            </td>
                            <td>
                              {data.notificationMethod}
                              <Link role="button" onClick={() => openPreferredContactModal(data.id, 'property')}>
                                (Change)
                              </Link>
                            </td>
                            <td>
                              <Switch
                                onChange={() => toggleStatus(data.id, 'property', index)}
                                checked={Boolean(data.is_active)}
                                onColor="#12b659"
                                offColor="#9aa6b5"
                                width={50}
                                height={25}
                              />
                            </td>
                          </tr>
                        ))
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="card-wrap full-width">
          <div className="card-inner full-width">
            <div className="">
              <div className="card-header">
                <h4 className="pull-left">License Plate Information</h4>
                <button className="btn btn-primary add-more pull-right" onClick={openAddPlateModal}>
                  <FontAwesomeIcon icon={faPlus} /> Add More
                </button>
              </div>
              <div className="card-body">
                <div className="table-responsive">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>Plate Number</th>
                        <th>County</th>
                        <th>Days Until Renewal</th>
                        <th>Preferred Contact</th>
                        <th>Preferred Payment Method</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {loading ? (
                        <tr>
                          <td colSpan="6">Loading...</td>
                        </tr>
                      ) : plateRenewals.length === 0 ? (
                        <tr>
                          <td colSpan="6">No data available</td>
                        </tr>
                      ) : (
                        plateRenewals.map((data, index) => (
                          <tr key={index}>
                            <td className="content">
                              <Link to={`/renewal-history/${data.plate_number}`}>
                                {data.plate_number}
                              </Link>
                            </td>
                            <td className="content">
                              {data.municipality_name}, {data.state_name}
                            </td>
                            <td>{data.expiration_date}</td>
                            <td>
                              {data.notificationMethod}
                              <Link role="button" onClick={() => openPreferredContactModal(data.id, 'plate')}>
                                (Change)
                              </Link>
                            </td>
                            <td>
                              {data.paymentMethod}
                              <Link role="button" href="#" onClick={() => openPreferredPaymentMethodModal(data.id)}>
                                (Change)
                              </Link>
                            </td>
                            <td>
                              <Switch
                                onChange={() => toggleStatus(data.id, 'plate', index)}
                                checked={Boolean(data.is_active)}
                                onColor="#12b659"
                                offColor="#9aa6b5"
                                width={50}
                                height={25}
                              />
                            </td>
                          </tr>
                        ))
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {flashMessage && (
        <div className="alert alert-success alert-dismissible mx-auto" role="alert">
          <div>
            {flashMessage}
            <button
              type="button"
              className="close"
              data-dismiss="alert"
              aria-label="Close"
              onClick={hideFlashMessage}
            >
              <span aria-hidden="true">
                <FontAwesomeIcon icon={faTimes} />
              </span>
            </button>
          </div>
        </div>
      )}

      <AddPlatePopup loadPlateRenewalData={loadPlateRenewalData} isOpen={isAddPlateModalOpen} onRequestClose={closeAddPlateModal}></AddPlatePopup>
      <ChangePreferredContactPopup selectedRenewalId={selectedRenewalId} loadRenewalData={renewalType === 'plate' ? loadPlateRenewalData : loadPropertyRenewalData} isOpen={isPreferredContactModalOpen} onRequestClose={ClosePreferredContactModal} type={renewalType}></ChangePreferredContactPopup>
      <ChangePreferredPaymentMethodPopup selectedRenewalId={selectedRenewalId} loadRenewalData={loadPlateRenewalData} isOpen={isPreferredPaymentMethodModalOpen} onRequestClose={ClosePreferredPaymentMethodModal}></ChangePreferredPaymentMethodPopup>
      
      <PropertySchedulePopup
        isOpen={isScheduleModalOpen}
        onRequestClose={closeScheduleModal}
        scheduleData={scheduleData}
        loadScheduleData={loadScheduleData}
        renewalId={selectedRenewalId} // Pass the renewalId for skip and resume actions
      />

      <ToastContainer />
      <Footer />
    </div>
  );
};

export default Account;
