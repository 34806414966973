import React from "react";
import PropTypes from "prop-types";
import './CustomStepper.css'; // Add custom styles here

const CustomStepper = ({ steps, activeStep }) => {
  return (
    <div className="custom-stepper">
      {steps.map((step, index) => {
        const isActive = index === activeStep;
        const isComplete = index < activeStep;

        return (
          <div key={index} className={`step ${isComplete ? 'complete' : ''} ${isActive ? 'active' : ''}`}>

            <div className="circle">
              <span className="">Step</span>
              {isComplete ? <span className="tick">&#10003;</span> : index + 1}
            </div>
            <div className={`label ${isActive ? 'active-label' : ''}`}>{step.title}</div>
            {index < steps.length - 1 && <div className="line" />}
          </div>
        );
      })}
    </div>
  );
};

CustomStepper.propTypes = {
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
    })
  ).isRequired,
  activeStep: PropTypes.number.isRequired,
};

export default CustomStepper;
