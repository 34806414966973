// src/pages/component/SearchResultsContext.js

import React, { createContext, useState } from 'react';

export const SearchResultsContext = createContext();

export const SearchResultsProvider = ({ children }) => {
  const [searchResults, setSearchResults] = useState([]);
  const [showContent, setShowContent] = useState(false);

  const toggleBackdrop = (show) => {
    setShowContent(show);
  };

  return (
    <SearchResultsContext.Provider value={{ searchResults, setSearchResults, showContent, toggleBackdrop }}>
      {children}
    </SearchResultsContext.Provider>
  );
};
