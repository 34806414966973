import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import './Account.css';
import Footer from './component/Footer';
import { API_URL, ACH_PRICE } from "../config";
import Header from './component/Header';
import Modal from 'react-modal'; // Import the modal library
import PaymentForm from './component/PaymentGateway';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import Swal from 'sweetalert2';
import Loading from './component/Loading';
import Switch from 'react-switch'; // Import the react-switch component
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; // Import the CSS
import { Link } from 'react-router-dom';

const PaymentMethods = () => {

    const [paymentMethods, setPaymentMethods] = useState([]);
    const [loading, setLoading] = useState(true);
    const [activeTab, setActiveTab] = useState('ach'); // Default tab is ach
    const [isAddModalOpen, setIsAddModalOpen] = useState(false); // State to control the modal
    const [transitTokenObject, setTransitTokenObject] = useState(null);
    const [accountNumber, setAccountNumber] = useState('');
    const [repeatAccountNumber, setRepeatAccountNumber] = useState('');
    const [routingNumber, setRoutingNumber] = useState('');
    const [accountType, setAccountType] = useState(''); // Set an initial value
    const [cardType, setCardType] = useState(''); // Set an initial value
    const [bankInfo, setBankInfo] = useState(null); // State to store bank information
    const [accountName, setAccountName] = useState('');
    const [achAgree, setAchAgree] = useState(false); // ach agree checkbox
    const [creditCardAgree, setCreditCardAgree] = useState(false); // creditCard agree checkbox
    const [termsAgree, setTermsAgree] = useState(false); // terms agree checkbox
    const [smsTermsAgree, setSmsTermsAgree] = useState(false); // sms terms agree checkbox  
    const [serviceFee, setServiceFee] = useState(ACH_PRICE);
    
    const maskAccountNumber = (fullAccountNumber) => {
      if (fullAccountNumber && fullAccountNumber.length >= 4) {
        const last4Digits = fullAccountNumber.slice(-4);
        return `************${last4Digits}`;
      }
      return fullAccountNumber;
    };

    const loadPaymentMethods = () => {
        const authToken = localStorage.getItem('token');

        if (!authToken) {
          return;
        }

        fetch(`${API_URL}/user/payment-methods`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((data) => {
        setPaymentMethods(data);
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching payment methods:', error);
        setLoading(false);
      });
    };

    useEffect(() => {
        // Load payment methods data when the component mounts
        loadPaymentMethods();
    }, []);    

    const filteredPaymentMethods = paymentMethods.filter(
        (method) =>
            (activeTab === 'ach' && method.payment_method.toLowerCase() === 'ach') ||
            (activeTab === 'creditCard' && method.payment_method.toLowerCase() === 'creditcard')
    );

    // Function to handle the "Add More" button click and open the modal
    const handleAddMoreClick = () => {
        setIsAddModalOpen(true);
    };

    // Function to handle adding a new payment method (Simulated API call)
    const handleAddPaymentMethod = () => {

        if (!isFormValid()) {
          return;
        }
        setLoading(true);
        
        // Fetch payment methods for the authenticated user
        const authToken = localStorage.getItem('token');

        if (!authToken) {
            // Handle the case where the token is missing
            return;
        }

        fetch(API_URL + '/user/payment-methods/save', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${authToken}`,
          },
          body: JSON.stringify({
            activeTab,
            transitTokenObject,
            accountNumber, 
            repeatAccountNumber, 
            routingNumber,
            accountType,
            cardType,
            accountName
          }),
        })
          .then((response) => {
            setLoading(false);
            if (response.status === 422) {
              return response.json().then((data) => {
                const errorMessages = Object.values(data.errors).join('\n');
                throw new Error(errorMessages); // Throw an error to trigger the .catch() block
              });
            } else if (!response.ok) {
              throw new Error('Network response was not ok');
            }
            return response.json();
          })
          .then((data) => {
            loadPaymentMethods();
            setIsAddModalOpen(false);
            setTransitTokenObject(null);
            setAccountNumber('');
            setRepeatAccountNumber('');
            setRoutingNumber('');
            setAccountType('');
            setCardType('');
            setBankInfo(null);

            // Display a success message using SweetAlert2
            Swal.fire({
              icon: 'success',
              title: 'Success!',
              text: "Congratulations! Payment method has been successfully created.",
              showConfirmButton: true,
            });
          })
          .catch((error) => {
            // Handle network and other errors during the submission
            Swal.fire({
              icon: 'error',
              title: 'Error submitting the form',
              text: error,
              showCloseButton: true,
              showConfirmButton: true,
            });
        });
    };

    const isFormValid = () => {

        const newErrors = {
          paymentMethod: '',
        };

        let isValid = true;

        if (!activeTab) {
          newErrors.paymentMethod = 'Payment method is required.';
          isValid = false;
        }


        if (activeTab==='ach') {
          if (!accountNumber) {
            newErrors.accountNumber = 'Account number is required.';
            isValid = false;
          }
          if (!repeatAccountNumber) {
            newErrors.repeatAccountNumber = 'Repeat Account number is required.';
            isValid = false;
          }
          if (accountNumber !== repeatAccountNumber) {
            newErrors.repeatAccountNumber = 'Account number & Repeat Account number must be same.';
            isValid = false;
          }

          if (!accountType) {
            newErrors.accountType = 'Account type is required.';
            isValid = false;
          }

          if (!routingNumber) {
            newErrors.routingNumber = 'Routing number is required.';
            isValid = false;
          } else if (!/^\d{9}$/.test(routingNumber)) {
            newErrors.routingNumber = 'Invalid routing number format. It should be 9 digits.';
            isValid = false;
          } else if (!bankInfo) {
            //newErrors.routingNumber = 'Invalid routing number.';
            //isValid = false;
          }
        } else {
            if (!transitTokenObject) {
                newErrors.accountType = 'Please ensure that you provide accurate and valid card information';
                isValid = false;
            }
        }

        if ((activeTab === "ach" && !achAgree) || (activeTab === "creditCard" && !creditCardAgree)) {
          newErrors.termsAgree = 'Please check All terms checkboxes.';
          isValid = false;
        }    

        // Combine all error messages into one
        const combinedErrorMessage = Object.values(newErrors).filter((message) => message).join('\n');

        if (!isValid) {
          // Replace \n with <br> for line breaks in the error message
          const errorMessageWithLineBreaks = combinedErrorMessage.replace(/\n/g, '<br>');

          // Show an error alert using SweetAlert with line breaks
          Swal.fire({
            icon: 'error',
            title: 'Please fix the following issues:',
            html: `${errorMessageWithLineBreaks}`,
            showCloseButton: true,
            showConfirmButton: true, // Hide the "OK" button
          });
        }

      return isValid;
  };

  const toggleIsDefault = (id, isDefault) => {
  
    const authToken = localStorage.getItem('token');

    if (!authToken) {
        // Handle the case where the token is missing
        return;
    }

    // Find the payment method in your local state by ID
    const updatedMethods = paymentMethods.map((method) => {
        if (method.id === id) {
            method.is_default = isDefault;
        }
        return method;
    });

    setPaymentMethods(updatedMethods);

    const newStatusMessage = isDefault
        ? 'Payment method set as default.'
        : 'Payment method removed as default.';

    fetch(`${API_URL}/user/payment-methods/update-is-default/${id}`, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${authToken}`,
        },
        body: JSON.stringify({ is_default: isDefault }),
    })
    .then((response) => {
        if (!response.ok) {
            toast.error('Network response was not ok', { autoClose: 3000 });
        }
        loadPaymentMethods(); // Refresh the payment methods after updating
        // Show a success message with the updated status information
        toast.success(`${newStatusMessage}`, {
            autoClose: 3000, // Auto close after 3 seconds
        });
    })
    .then((data) => {
    })
    .catch((error) => {
        toast.error('Error updating is_default', { autoClose: 3000 });
    });
  };

    const toggleEnabled = (id, isEnabled) => {

        const authToken = localStorage.getItem('token');

        if (!authToken) {
            // Handle the case where the token is missing
            return;
        }

        // Find the payment method in your local state by ID
        const updatedMethods = paymentMethods.map((method) => {
          if (method.id === id) {
            method.enabled = isEnabled;
          }
          return method;
        });
        
        setPaymentMethods(updatedMethods);

        const newStatusMessage = isEnabled
          ? 'Payment method activated.'
          : 'Payment method deactivated.';

        fetch(`${API_URL}/user/payment-methods/update-enabled/${id}`, {
            method: 'PUT', // You can use POST or PUT based on your API
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${authToken}`,
            },
            body: JSON.stringify({ enabled: isEnabled }),
        })
        .then((response) => {
            if (!response.ok) {
                toast.error('Network response was not ok', { autoClose: 3000 });
            }

            // Show a success message with the updated status information
            toast.success(`${newStatusMessage}`, {
              autoClose: 3000, // Auto close after 3 seconds
            });
        })
        .then((data) => {
        })
        .catch((error) => {
            toast.error('Error updating enabled', { autoClose: 3000 });
        });
    };

    return (
        <div className="">
            <Header />
            <div className="container-fluid p-30">
                {loading ? <Loading text="Please wait..." /> : ''}
                <div className="page-wrap">
                    <h2>Payment Methods</h2>
                    <ol className="breadcrumb-wrap mb-30">
                        <li><Link to="#">RMT</Link></li>
                        <li className="active">Payment Method</li>
                    </ol>
                    <div className="tab-buttons">
                        <div
                            className={`tab-button ach-tab ${activeTab === 'ach' ? 'active' : ''}`}
                            onClick={() => setActiveTab('ach')}
                        >
                            ACH
                        </div>
                        <div
                            className={`tab-button card-tab ${activeTab === 'creditCard' ? 'active' : ''}`}
                            onClick={() => setActiveTab('creditCard')}
                        >
                            Credit Card
                        </div>
                    </div>
                    <div className="tabber-wrap p-20">
                        <div className="table-wrap">
                            <div className="clear-both">
                                <button className="btn btn-primary btn-bordered" onClick={handleAddMoreClick}>
                                    <FontAwesomeIcon icon={faPlus} /> Add More
                                </button>
                            </div>
                            <div className="pb-20 clear-both"></div>
                            <div className="table-responsive">
                                <table className="table">
                                    <thead>
                                    <tr>
                                      <th>Created At</th>
                                      {activeTab === 'creditCard' && (
                                        <>
                                          <th>Payment ID</th>
                                          <th>Card Holder Name</th>
                                          <th>Masked Card Number</th>
                                          <th>Card Type</th>
                                          <th>Expiration Date</th>
                                          <th>Make it default</th>
                                          <th>Enabled</th>
                                          
                                        </>
                                      )}
                                      {activeTab === 'ach' && (
                                        <>
                                          <th>Account Number</th>
                                          <th>Routing Number</th>
                                          <th>Account Type</th>
                                          <th>Make it default</th>
                                          <th>Enabled</th>
                                          
                                        </>
                                      )}
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {loading ? (
                                      <tr>
                                        <td colSpan="12">Loading...</td>
                                      </tr>
                                    ) : filteredPaymentMethods.length === 0 ? (
                                      <tr>
                                        <td colSpan="12">No payment methods available</td>
                                      </tr>
                                    ) : (
                                      filteredPaymentMethods.map((method, index) => (
                                        <tr key={index}>
                                          <td className="content">
                                            {method.created_at ? new Date(method.created_at).toLocaleDateString() : '-'}
                                          </td>
                                          {activeTab === 'creditCard' ? (
                                            <>
                                              <td className="content">{method.pay_payment_id || '-'}</td>
                                              <td className="content">{method.card_holder_name || '-'}</td>
                                              <td className="content">{maskAccountNumber(method.masked_card_number) || '-'}</td>
                                              <td className="content">{method.card_type || '-'}</td>
                                              <td className="content">{method.expiration_date || '-'}</td>
                                              <td className="content">
                                                  <Switch
                                                      onChange={() => toggleIsDefault(method.id, !method.is_default)}
                                                      checked={Boolean(method.is_default)}
                                                      onColor="#12b659"
                                                      offColor="#9aa6b5"
                                                      width={50}
                                                      height={25}
                                                  />
                                              </td>
                                              <td className="content">
                                                  <Switch
                                                      onChange={() => toggleEnabled(method.id, !method.enabled)}
                                                      checked={Boolean(method.enabled)}
                                                      onColor="#12b659"
                                                      offColor="#9aa6b5"
                                                      width={50}
                                                      height={25}
                                                  />
                                              </td>
                                            </>
                                          ) : (
                                            <>
                                              <td className="content">{maskAccountNumber(method.account_number) || '-'}</td>
                                              <td className="content">{method.routing_number || '-'}</td>
                                              <td className="content">{method.account_type || '-'}</td>
                                              <td className="content">
                                                  <Switch
                                                      onChange={() => toggleIsDefault(method.id, !method.is_default)}
                                                      checked={Boolean(method.is_default)}
                                                      onColor="#12b659"
                                                      offColor="#9aa6b5"
                                                      width={50}
                                                      height={25}
                                                  />
                                              </td>
                                              <td className="content">
                                                  <Switch
                                                      onChange={() => toggleEnabled(method.id, !method.enabled)}
                                                      checked={Boolean(method.enabled)}
                                                      onColor="#12b659"
                                                      offColor="#9aa6b5"
                                                      width={50}
                                                      height={25}
                                                  />
                                              </td>
                                            </>
                                          )}
                                        </tr>
                                      ))
                                    )}
                                  </tbody>

                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <ToastContainer />
            </div>
            <Footer />
            

            <Modal isOpen={isAddModalOpen} className="login-popup" overlayClassName="overlay">
                <div className="login-content add-plate-pop">
                    <button className="close-button"  onClick={() => setIsAddModalOpen(false)}>
                      <FontAwesomeIcon icon={faTimes} />
                    </button>

                    <h2>Add Payment Method</h2>
                    <PaymentForm accountType={accountType} setAccountType={setAccountType} cardType={cardType} setCardType={setCardType} routingNumber={routingNumber} setRoutingNumber={setRoutingNumber} accountNumber={accountNumber} setAccountNumber={setAccountNumber}  setRepeatAccountNumber={setRepeatAccountNumber} paymentMethod={activeTab} setPaymentMethod={setActiveTab} transitTokenObject={transitTokenObject} setTransitTokenObject={setTransitTokenObject} bankInfo={bankInfo}  setBankInfo={setBankInfo} accountName={accountName} setAccountName = {setAccountName} isShowTermsAndConditions={false} achAgree={achAgree} setAchAgree={setAchAgree} creditCardAgree={creditCardAgree} setCreditCardAgree={setCreditCardAgree} termsAgree={termsAgree} setTermsAgree={setTermsAgree} smsTermsAgree={smsTermsAgree} setSmsTermsAgree={setSmsTermsAgree} serviceFee={serviceFee}
                      setServiceFee={setServiceFee}/>
                    <button className="btn btn-primary" onClick={handleAddPaymentMethod}>Save Detail</button>
                </div>
            </Modal>
        </div>
    );
};

export default PaymentMethods;
