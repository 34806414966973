export const API_URL = (typeof window.env !== 'undefined' && window.env.REACT_APP_API_URL) 
  ? window.env.REACT_APP_API_URL 
  : process.env.REACT_APP_API_URL || "https://nx.renewmy.dev/api";

export const REACT_APP_DOMAIN_NAME = "property";

export const REACT_APP_DOMAIN = (typeof window.env !== 'undefined' && window.env.REACT_APP_DOMAIN)
  ? window.env.REACT_APP_DOMAIN
  : process.env.REACT_APP_DOMAIN || "property";

export const ROUTING_NUMBER_API_URL = (typeof window.env !== 'undefined' && window.env.REACT_NUMBER_API)
  ? window.env.REACT_NUMBER_API
  : process.env.REACT_APP_ROUTING_NUMBER_API || "https://routing-number-api.fly.dev/banks";

export const TSEP_HOST = (typeof window.env !== 'undefined' && window.env.REACT_TSEP_HOST)
  ? window.env.REACT_TSEP_HOST
  : process.env.REACT_APP_TSEP_HOST || "https://stagegw.transnox.com";

export const GTM_ID = (typeof window.env !== 'undefined' && window.env.REACT_GTM_ID)
  ? window.env.REACT_GTM_ID
  : process.env.REACT_APP_GTM_ID || "GTM-000000";

export const PAYMENT_GATEWAY_DEVICEID = (typeof window.env !== 'undefined' && window.env.REACT_APP_PAYMENT_GATEWAY_DEVICEID)
  ? window.env.REACT_APP_PAYMENT_GATEWAY_DEVICEID
  : process.env.REACT_APP_PAYMENT_GATEWAY_DEVICEID || "88800000313002";

export const ACH_PRICE = (typeof window.env !== 'undefined' && window.env.REACT_APP_ACH_PRICE)
  ? window.env.REACT_APP_ACH_PRICE
  : process.env.REACT_APP_ACH_PRICE || 5;

export const DEBIT_CARD_PRICE = (typeof window.env !== 'undefined' && window.env.REACT_APP_DEBIT_CARD_PRICE)
  ? window.env.REACT_APP_DEBIT_CARD_PRICE
  : process.env.REACT_APP_DEBIT_CARD_PRICE || 5.50;

export const CREDIT_CARD_PRICE = (typeof window.env !== 'undefined' && window.env.REACT_APP_CREDIT_CARD_PRICE)
  ? window.env.REACT_APP_CREDIT_CARD_PRICE
  : process.env.REACT_APP_CREDIT_CARD_PRICE || 6.50;

export const ACH_SERVICE_FEE = (typeof window.env !== 'undefined' && window.env.REACT_APP_ACH_SERVICE_FEE)
  ? window.env.REACT_APP_ACH_SERVICE_FEE
  : process.env.REACT_APP_ACH_SERVICE_FEE || 0;

export const DEBIT_CARD_SERVICE_FEE = (typeof window.env !== 'undefined' && window.env.REACT_APP_DEBIT_CARD_SERVICE_FEE)
  ? window.env.REACT_APP_DEBIT_CARD_SERVICE_FEE
  : process.env.REACT_APP_DEBIT_CARD_SERVICE_FEE || 0;

export const CREDIT_CARD_SERVICE_FEE = (typeof window.env !== 'undefined' && window.env.REACT_APP_CREDIT_CARD_SERVICE_FEE)
  ? window.env.REACT_APP_CREDIT_CARD_SERVICE_FEE
  : process.env.REACT_APP_CREDIT_CARD_SERVICE_FEE || 1.75;

export const NEXT_YEAR_INCREASE_PERCENTAGE = (typeof window.env !== 'undefined' && window.env.NEXT_YEAR_INCREASE_PERCENTAGE)
  ? window.env.NEXT_YEAR_INCREASE_PERCENTAGE
  : process.env.REACT_APP_NEXT_YEAR_INCREASE_PERCENTAGE || 3;
